interface Props {
  text: string;
  className?: string;
}

export default function Instruction({ text = "", className }: Props) {
  // Match and split text by numbers followed by a period (e.g., "1.", "2.", etc.)
  const steps = text
    .match(/\d+\.\s*([^0-9]+)/g)
    ?.map((step) => step.replace(/^\d+\.\s*/, "").trim()) || [text];

  return (
    <div className={"m-4 " + className}>
      {steps.length > 1 ? (
        <ol className="list-decimal pl-6">
          {steps.map((step, index) => (
            <li key={index} className="mb-2 font-semibold text-left">
              {step}
            </li>
          ))}
        </ol>
      ) : (
        <p className="font-semibold">{text}</p>
      )}
    </div>
  );
}
