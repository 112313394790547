import { ResourceInterface } from "interface/ResourceInterface";
import { projectFirestore, timestamp } from "../firebase/config";
import useOrganisationContext from "./organisation/useOrganisationContext";
import { useCallback, useEffect, useState } from "react";

export const useResourcesOrganisation = () => {
  const { selectedOrganisation } = useOrganisationContext();
  const [resources, setResources] = useState<ResourceInterface[]>([]);

  const AddResources = useCallback(
    async (data: object) => {
      try {
        const record = {
          createdAt: timestamp.fromDate(new Date()),
          organisationId: selectedOrganisation?.id,
          ...data,
        };
        const ref = await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("resources")
          .add(record);
        await ref.update({ id: ref.id });
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const DeleteResource = useCallback(
    async (resourceId: string) => {
      try {
        await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("resources")
          .doc(resourceId)
          .delete();
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const EditResource = useCallback(
    async (resourceId: string, updatedData: object) => {
      try {
        await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("resources")
          .doc(resourceId)
          .update(updatedData); // Update with the new data
      } catch (error) {
        console.error("Error editing resource:", error);
      }
    },
    [selectedOrganisation]
  );

  useEffect(() => {
    if (!selectedOrganisation) return;

    const resourcesQuery = projectFirestore
      .collection("organisations")
      .doc(selectedOrganisation.id)
      .collection("resources")
      .orderBy("createdAt", "desc");

    const unsubscribe = resourcesQuery.onSnapshot(
      (snapshot) => {
        const updatedResources: ResourceInterface[] = [];
        snapshot.forEach((doc) => {
          updatedResources.push(doc.data() as ResourceInterface);
        });
        setResources(updatedResources);
      },
      (error) => {
        console.error("Error fetching resources:", error);
      }
    );

    return () => unsubscribe();
  }, [selectedOrganisation]);

  const GetOrganisationResourcesById = () => (orgId: string) => {
    const updatedResources: ResourceInterface[] = [];
    let topicUnsubscribe: (() => void) | undefined;

    try {
      const resourcesQuery = projectFirestore
        .collection("organisations")
        .doc(orgId)
        .collection("resources");

      topicUnsubscribe = resourcesQuery.onSnapshot((resourcesSnapshot) => {
        updatedResources.length = 0; // Clear the array
        resourcesSnapshot.forEach((resourceSnapshot) => {
          updatedResources.push(resourceSnapshot.data() as ResourceInterface);
        });

        setResources(updatedResources);
      });
    } catch (error) {
      console.error(error);
    }

    return () => {
      if (topicUnsubscribe) {
        topicUnsubscribe();
      }
    };
  };

  return {
    AddResources,
    DeleteResource,
    GetOrganisationResourcesById,
    EditResource,
    resources,
  };
};
