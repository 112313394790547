import { FeedbackFormTabsProps } from "components/NG/FeedBackFormTabs";
import FeedBackOrgFormTabs from "components/NG/FeedBackOrgFormTabs";

import { ReactNode, useEffect } from "react";

type Props = {
  children: ReactNode;
  organisation?: string;
  viewOnly?: boolean;
  numTab: number;
} & FeedbackFormTabsProps;

export default function FeedbackOrgForm({
  children,
  organisation,
  viewOnly,
  numTab,

  ...feedbackFormTabsProps
}: Props) {
  useEffect(() => {
    return () => {
      // Cleanup ResizeObserver on unmount
      const observers = (window as any).__resizeObservers || [];
      observers.forEach((observer: any) => {
        observer.disconnect();
      });
    };
  }, []);

  return (
    <div className="bg-white relative w-full  min-h-[660px] rounded-tl-3xl rounded-tr-3xl rounded-md shadow-lg">
      <FeedBackOrgFormTabs
        numTabs={numTab}
        viewOnly={viewOnly}
        organisation={organisation}
        {...feedbackFormTabsProps}
      />
      <div className="w-full flex flex-col justify-center items-center text-center">
        {children}
      </div>
    </div>
  );
}
