import SimpleLoader from "components/Loaders/SimpleLoader";
import { Modal } from "flowbite-react";
import { useResourcesOrganisation } from "hooks/useResourcesOrganisation";
import usePromise from "hooks/utility/usePromise";
import { ResourceInterface } from "interface/ResourceInterface";
import { FormEvent, useState } from "react";
import { toast } from "react-hot-toast";
import {
  FaEdit,
  FaTrash,
  FaLink,
  FaExternalLinkAlt,
  FaExclamationCircle,
  FaPlus,
} from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";

interface ResourceProps {
  resource: ResourceInterface;
}

const ResourceCard = ({ resource }: ResourceProps) => {
  const { isLoading: isDeleting, resolve } = usePromise();
  const { isLoading, resolve: updating } = usePromise();
  const { DeleteResource } = useResourcesOrganisation();
  const [isVisible, setIsVisible] = useState(true);
  const { EditResource } = useResourcesOrganisation();
  const [error, setError] = useState("");
  const deleteHandler = async (resourceId: string) => {
    resolve(() => DeleteResource(resourceId)).then(() => {
      setShow(!show);
      toast.success("data was deleted successfully");
      setIsVisible(!isVisible);
    });
  };
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [newResource, setNewResource] = useState(resource.title || "");
  const [url, setURL] = useState(resource.url || "");
  const modalHandler = async () => {
    setShow(!show);
  };
  const modalEditHandler = async () => {
    setEditShow(!editShow);
  };

  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();
    const data = { title: newResource, url };
    updating(() => EditResource(resource.id, data)).then(() => {
      modalEditHandler();
      toast.success("Resources was saved successfully.");
    });
  };

  const urlHandler = (url: string) => {
    // Validate URL
    const isUrlValid = /^https:\/\//.test(url);

    if (url.length > 0 && !isUrlValid) {
      setError("Invalid URL. Please make sure it starts with 'https://'.");
      setURL(url);
      return;
    } else {
      setError("");
      setURL(url);
    }
  };

  return (
    <>
      {/* Delete Confirmation Modal */}
      <Modal show={show} size="md">
        <Modal.Body className="p-6 relative">
          <AiFillCloseCircle
            size={28}
            onClick={modalHandler}
            className="cursor-pointer text-[#EB8181] absolute right-4 top-4 hover:opacity-80 transition-opacity"
          />
          <div className="text-center">
            <div className="mx-auto mb-6 w-16 h-16 bg-red-50 rounded-full flex items-center justify-center">
              <FaExclamationCircle className="text-[#EB8181] text-3xl" />
            </div>
            <h3 className="mb-6 text-lg font-medium text-gray-700">
              Delete Resource
              <span className="block mt-2 text-base font-normal text-gray-500">
                Are you sure you want to delete "
                <strong>{resource.title}</strong>"?
              </span>
            </h3>
            {!isDeleting ? (
              <div className="flex justify-center gap-4">
                <button
                  className="px-6 py-2 rounded-md bg-red-50 border-2 border-[#EB8181] text-[#EB8181] hover:bg-red-100 transition-colors"
                  onClick={() => deleteHandler(resource.id)}
                >
                  Delete
                </button>
                <button
                  className="px-6 py-2 rounded-md border-2 border-gray-200 text-gray-600 hover:border-gray-300 transition-colors"
                  onClick={modalHandler}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div className="flex justify-center">
                <SimpleLoader />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>

      {/* Edit Modal */}
      <Modal show={editShow} size="md">
        <Modal.Body className="p-6 relative">
          <AiFillCloseCircle
            size={28}
            onClick={modalEditHandler}
            className="cursor-pointer text-[#EB8181] absolute right-4 top-4 hover:opacity-80 transition-opacity"
          />
          <div className="mb-6">
            <h3 className="text-lg font-semibold text-gray-800 mb-2">
              Edit Resource
            </h3>
            <p className="text-gray-600 text-sm">
              Update the resource details below
            </p>
          </div>

          <form onSubmit={submitHandler} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Resource Name
              </label>
              <input
                className="w-full px-4 py-2 rounded-md border border-gray-200 focus:border-[#EB8181] focus:ring-1 focus:ring-[#EB8181] outline-none transition-colors"
                value={newResource}
                placeholder="Enter resource name"
                onChange={(e) => setNewResource(e.target.value)}
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Resource URL/Link
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 right-3 flex items-center">
                  <FaLink className="text-gray-400" />
                </div>
                <input
                  className="w-full px-4 py-2 pr-10 rounded-md border border-gray-200 focus:border-[#EB8181] focus:ring-1 focus:ring-[#EB8181] outline-none transition-colors"
                  value={url}
                  placeholder="https://"
                  onChange={(e) => urlHandler(e.target.value)}
                  required
                />
              </div>
              {error && (
                <p className="mt-2 text-sm text-red-600 flex items-center gap-2">
                  <FaExclamationCircle />
                  {error}
                </p>
              )}
            </div>

            <button
              className="w-full px-4 py-2 rounded-md bg-white border-2 border-[#EB8181] text-[#EB8181] hover:bg-red-50 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
              disabled={isLoading || error.length > 0}
              type="submit"
            >
              <FaPlus size={16} />
              {isLoading ? "Updating..." : "Update Resource"}
            </button>
          </form>
        </Modal.Body>
      </Modal>

      {/* Resource Card */}
      <div className={`${!isVisible && "hidden"} relative`}>
        <div className="bg-white rounded-lg border border-gray-200 p-4 hover:border-[#EB8181] transition-colors group">
          <div className="absolute top-4 right-4 flex items-center gap-3">
            <button
              onClick={modalEditHandler}
              className="p-2 rounded-full   transition-colors !shadow-none"
            >
              <FaEdit className="text-default transition-colors" size={16} />
            </button>
            <button
              onClick={modalHandler}
              className="p-2 rounded-full   transition-colors !shadow-none"
            >
              <FaTrash className="text-default transition-colors" size={16} />
            </button>
          </div>

          <div className="pr-24">
            <h3 className="font-medium text-gray-800 mb-2">{resource.title}</h3>
            <a
              href={resource.url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 text-sm text-gray-500 hover:text-[#EB8181] transition-colors"
            >
              <FaLink size={12} />
              {resource.url}
              <FaExternalLinkAlt size={12} className="ml-1" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResourceCard;
