import { useTour } from "@reactour/tour";
import SimpleLoader from "components/Loaders/SimpleLoader";
import EmptySpace from "components/utility/EmptySpace";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useResourcesOrganisation } from "hooks/useResourcesOrganisation";
import usePromise from "hooks/utility/usePromise";
import { ResourceInterface } from "interface/ResourceInterface";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { FormEvent, useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import ResourcesList from "./ResourcesList";
import { FaEdit, FaLink, FaPlus, FaToggleOn } from "react-icons/fa";
import { ToggleSwitch } from "flowbite-react";
import switchClasses from "../ActivitySettings/ActivitySharePanelOption.module.css";

interface ResourceSettingsProps {
  resources: ResourceInterface[];
}

const ResourcesSettings = ({ resources }: ResourceSettingsProps) => {
  const { setIsOpen: setIsTourOpen } = useTour();
  const { selectedOrganisation, updateSelectedOrganisation } =
    useOrganisationContext();
  const [newResource, setNewResource] = useState("");
  const [url, setURL] = useState("");
  const { AddResources } = useResourcesOrganisation();
  const { isLoading, resolve } = usePromise();
  const { isLoading: isUpdating, resolve: updating } = usePromise();
  const [error, setError] = useState("");
  const [checkboxState, setCheckboxState] = useState({
    useDefaultResources: true,
  });

  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();
    const data = { title: newResource, url };
    resolve(() => AddResources(data)).then(() => {
      setNewResource("");
      setURL("");
      toast.success("New resources was successfully added.");
    });
  };

  const urlHandler = (url: string) => {
    // Validate URL
    const isUrlValid = /^https:\/\//.test(url);

    if (url.length > 0 && !isUrlValid) {
      setError("Invalid URL. Please make sure it starts with 'https://'.");
      setURL(url);
      return;
    } else {
      setError("");
      setURL(url);
    }
  };

  const handleCheckboxChange = async (checkboxName: string) => {
    const updatedCheckboxState = {
      ...checkboxState,
      [checkboxName]: !checkboxState[checkboxName],
    };
    updating(() =>
      updateSelectedOrganisation({
        [checkboxName]: updatedCheckboxState[checkboxName],
      })
    ).then(() => {
      setCheckboxState(updatedCheckboxState);
      toast.success("Settings was updated successfully.");
    });
  };

  useEffect(() => {
    // eslint-disable-next-line
  }, [resources]);

  return (
    <>
      <EmptySpace />
      <button
        className={`${classes["button"]}`}
        onClick={() => setIsTourOpen(true)}
      >
        Show Tutorial
      </button>
      <EmptySpace />
      <div className={`${classes["container"]} mb-[100px] flex flex-col`}>
        <div className="mb-2">
          <p className="text-lg my-2">
            {" "}
            Resources for{" "}
            <strong>{selectedOrganisation && selectedOrganisation.name}</strong>
          </p>
        </div>
        {/* Instructions Panel */}
        <div className="p-6 mb-8">
          <div className="flex items-center gap-2 mb-4">
            <div className="w-2 h-2 rounded-full bg-[#EB8181]" />
            <h2 className="text-lg font-semibold text-gray-800">
              Instructions
            </h2>
          </div>

          <div className="space-y-6 text-gray-600">
            <p className="text-sm">Follow these steps to manage resources:</p>

            <div className="grid grid-cols-2 gap-6  w-1/2 ">
              {/* Step 1 */}
              <div className="flex items-start gap-3">
                <div className="p-2 bg-red-50 rounded-full">
                  <FaEdit className="text-[#EB8181]" />
                </div>
                <div>
                  <p className="font-medium text-gray-700 mb-1">
                    Enter Resource Name
                  </p>
                  <p className="text-sm text-gray-500">
                    Add a descriptive name for your resource in the "Resource
                    Name" field
                  </p>
                </div>
              </div>

              {/* Step 2 */}
              <div className="flex items-start gap-3">
                <div className="p-2 bg-red-50 rounded-full">
                  <FaLink className="text-[#EB8181]" />
                </div>
                <div>
                  <p className="font-medium text-gray-700 mb-1">Provide URL</p>
                  <p className="text-sm text-gray-500">
                    Enter the resource URL starting with "https://" in the link
                    field
                  </p>
                </div>
              </div>

              {/* Step 3 */}
              <div className="flex items-start gap-3">
                <div className="p-2 bg-red-50 rounded-full">
                  <FaPlus className="text-[#EB8181]" />
                </div>
                <div>
                  <p className="font-medium text-gray-700 mb-1">Add Resource</p>
                  <p className="text-sm text-gray-500">
                    Click "Add Resource" button to save it to your resources
                    page
                  </p>
                </div>
              </div>

              {/* Step 4 */}
              <div className="flex items-start gap-3">
                <div className="p-2 bg-red-50 rounded-full">
                  <FaToggleOn className="text-[#EB8181]" />
                </div>
                <div>
                  <p className="font-medium text-gray-700 mb-1">
                    Default Resources
                  </p>
                  <p className="text-sm text-gray-500">
                    Use the checkbox to show/hide default resource links
                  </p>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-3 pt-4 border-t border-gray-100">
              <div className="p-2 bg-gray-50 rounded-full">
                <FaEdit className="text-gray-400" />
              </div>
              <p className="text-sm text-gray-500 italic">
                Note: You can edit or remove resources at any time using the
                icons on each resource card
              </p>
            </div>
          </div>
        </div>

        <div className="w-1/2 my-8 p-6 border border-gray-200 rounded-lg">
          <div className="flex items-center gap-2 mb-4">
            <div className="w-2 h-2 rounded-full bg-[#EB8181]" />
            <h2 className="text-lg font-semibold text-gray-800">
              Default Resources
            </h2>
          </div>

          {selectedOrganisation && (
            <div className={`${switchClasses["option-container"]} space-y-4`}>
              <label className="flex items-start gap-2 cursor-pointer">
                <div className="flex-shrink-0 pt-1">
                  <ToggleSwitch
                    disabled={isUpdating}
                    checked={selectedOrganisation?.useDefaultResources ?? true}
                    label={``}
                    onChange={() => handleCheckboxChange("useDefaultResources")}
                  />
                </div>
                <div className="space-y-2">
                  <span className="block text-gray-700 font-medium">
                    Use default resources links
                  </span>
                  <p className="text-sm text-gray-500">
                    When enabled, users will see a curated list of default
                    mental health resources including links to IMH CHAT,
                    Mindline.sg, HealthHub SG, and other helpful services.
                    {selectedOrganisation?.useDefaultResources
                      ? " These resources are currently visible to users."
                      : " These resources are currently hidden from users."}
                  </p>
                </div>
              </label>

              <div className="mt-4 bg-gray-50 p-4 rounded-md">
                <div className="flex items-center gap-2 text-sm text-gray-600">
                  <FaToggleOn className="text-gray-400" />
                  <span>
                    {selectedOrganisation?.useDefaultResources
                      ? "Default resources are enabled"
                      : "Default resources are disabled"}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="bg-white rounded-lg border border-gray-200 p-6 mb-8 max-w-sm">
          <div className="flex items-center gap-2 mb-6">
            <div className="w-2 h-2 rounded-full bg-[#EB8181]" />
            <h2 className="text-lg font-semibold text-gray-800">
              Add New Resource
            </h2>
          </div>

          <form onSubmit={submitHandler} className="space-y-6 w-full">
            <div resources-settings-tour="resource-name-input">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Resource Name
              </label>
              <input
                className="w-full px-4 py-2 rounded-md border border-gray-200 focus:border-[#EB8181] focus:ring-1 focus:ring-[#EB8181] outline-none transition-colors placeholder:text-sm text-xs"
                value={newResource}
                placeholder="Enter resource name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setNewResource(e.target.value)
                }
                required
              />
            </div>

            <div resources-settings-tour="resource-link-input">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Resource URL/Link
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 right-2 pl-3 flex items-center pointer-events-none">
                  <FaLink className="text-gray-400" />
                </div>
                <input
                  className="w-full pl-10 pr-4 py-2 rounded-md border border-gray-200 focus:border-[#EB8181] focus:ring-1 focus:ring-[#EB8181] outline-none transition-colors placeholder:text-sm text-sm"
                  value={url}
                  placeholder="https://"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    urlHandler(e.target.value)
                  }
                  required
                />
              </div>
              {error && (
                <p className="mt-2 text-sm text-red-600 flex items-center gap-2">
                  <FaEdit />
                  {error}
                </p>
              )}
            </div>

            <button
              resources-settings-tour="add-resource-button"
              className={`
                w-full px-4 py-2 rounded-md
                flex items-center justify-center gap-2
                bg-white border-2 border-[#EB8181] text-[#EB8181]
                hover:bg-red-50 transition-all duration-200
                disabled:opacity-50 disabled:cursor-not-allowed
              `}
              disabled={isLoading || error.length > 0}
              type="submit"
            >
              <FaPlus size={16} />
              {isLoading ? "Adding Resource..." : "Add Resource"}
            </button>
          </form>
        </div>

        {!isLoading ? (
          <ResourcesList resources={resources} />
        ) : (
          <SimpleLoader />
        )}
      </div>
    </>
  );
};

export default ResourcesSettings;
