import classes from "./SmileyRating.module.css";
import { ReactComponent as ExclamationMark } from "assets/ExclamationMark.svg";
import { useThemeContext } from "hooks/useThemeContext";

type SmileyRatingProps = {
  question?: string;
  value: number; // Changed from activeValue to value
  name: string;
  onSelect: (e: any) => void; // Corrected type
  showError: boolean;
  otherClass?: string;
  maxRating?: number; // New prop
  viewOnly?: boolean;
};

export default function SmileyRatingWithMax({
  question,
  value, // Changed from activeValue to value
  name,
  onSelect,
  showError,
  otherClass,
  maxRating = 10,
  viewOnly, // Default value is 5
}: SmileyRatingProps) {
  const { isFacil } = useThemeContext();

  const smileyInputClasses = `${classes["smiley-input"]} ${otherClass} ${
    showError && value === 0 ? classes["smiley-input--error"] : ""
  }`;

  const renderCircles = () => {
    const circles: JSX.Element[] = []; // Specify the type of the array
    for (let i = 1; i <= maxRating; i++) {
      circles.push(
        <div
          key={i}
          className={`${classes[value === i ? "circle-active" : "circle"]} ${
            isFacil
              ? classes[value === i ? "circle-active--facil" : "circle--facil"]
              : ""
          }`}
          data-name={name}
          data-set={i}
          onClick={(e) => (viewOnly ? {} : onSelect(e))}
        ></div>
      );
    }
    return circles;
  };

  return (
    <div className={classes["smiley-container"]}>
      <p>{question}</p>
      <div className={`${classes["smiley-input-container"]}`}>
        <p className={classes["rating-number"]}>1</p>
        <div className={smileyInputClasses}>{renderCircles()}</div>
        <p className={classes["rating-number"]}>{maxRating}</p>
      </div>
      {showError && value === 0 && (
        <div className={classes["smiley-error-message"]}>
          <ExclamationMark />
          <span>Please choose your rating for this section.</span>
        </div>
      )}
    </div>
  );
}
