import React, { useState, useEffect } from "react";
import classes from './FeedbackForm.module.css';
import { addFeedback } from "models/feedback";
import { FEEDBACK_RATING } from 'models/feedback';
import { uploadImages } from "utility/uploadHandling";
import { useAuthContext } from "../../hooks/useAuthContext";
import getOrganisationNames from "../../models/organisation";


const FeedbackForm: React.FC = () => {
  const { profile } = useAuthContext(); // Access the user's profile
  const [activeSection, setActiveSection] = useState("feedback-suggestions");
  const [rating, setRating] = useState<string>("No Rating");
  const [feedback, setFeedback] = useState("");
  const [suggestions, setSuggestions] = useState("");
  const [problem, setProblem] = useState("");
  const [device, setDevice] = useState("");
  const [deviceModel, setDeviceModel] = useState("");
  const [os, setOs] = useState("");
  const [browser, setBrowser] = useState("");
  const [otherBrowser, setOtherBrowser] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isMissingFieldsModalVisible, setIsMissingFieldsModalVisible] = useState(false);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [organisations, setOrganisations] = useState<string[]>([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState("");

  useEffect(() => {
    const fetchOrganisations = async () => {
      try {
        const orgNames = await getOrganisationNames();
        setOrganisations(orgNames); // Fixed typo here
      } catch (error) {
        console.error("Error fetching organisations:", error);
      }
    };
    fetchOrganisations();
  }, []);

  const OrganisationDropdown: React.FC<{ organisations: string[], onSelect: (org: string) => void }> = ({ organisations, onSelect }) => (
    <div className={classes["question-container"]}>
      <label className={classes["question-label"]}>Select the organisation you are part of for feedback:</label>
      <select
        className={classes["input-select"]}
        value={selectedOrganisation}
        onChange={(e) => onSelect(e.target.value)}
        required
      >
        <option value="" disabled>Select an organisation</option>
        {organisations.map((org, index) => (
          <option key={index} value={org}>{org}</option>
        ))}
      </select>
    </div>
  );

  const showSection = (sectionId: string) => {
    setActiveSection(sectionId);
  };

  const selectRating = (starIndex: number) => {
    setRating(starIndex.toString());
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(event.target.files || []);
    const validFiles: File[] = [];
    let hasInvalidFiles = false;

    newFiles.forEach((file) => {
      const isValidImage = /\.(jpe?g|png|gif|bmp)$/i.test(file.name) && file.type.startsWith("image/");
      if (isValidImage) {
        validFiles.push(file);
      } else {
        hasInvalidFiles = true;
      }
    });

    if (hasInvalidFiles) {
      setIsErrorModalVisible(true); // Show error modal if there's an invalid file
    }

    if (validFiles.length > 0) {
      setFiles((prevFiles) => prevFiles.concat(validFiles));
    }
  };


  const deleteFile = (fileToDelete: File) => {
    setFiles((prevFiles) => prevFiles.filter(file => file !== fileToDelete));
  };

  // Validation function
  const isFormValid = () => {
    const isOrganisationValid = selectedOrganisation !== "";
    const isFeedbackSectionValid = feedback.trim() !== "" && suggestions.trim() !== "" && rating !== "No Rating";
    const isProblemSectionValid = problem.trim() !== "";
    const isDeviceSectionValid = device !== "" && os !== "" && browser !== "";

    // Ensure that either:
    // 1. Feedback section is filled, or
    // 2. Problem section is filled, or
    // 3. Device section is filled
    return isOrganisationValid && (isFeedbackSectionValid || isProblemSectionValid || isDeviceSectionValid);
  };

  const handleSubmit = async () => {
    if (!isFormValid()) {
      alert("Please fill all required fields.");
      return false;
    }
    const uploadedFiles = await uploadImages(files, "AppFeedback"); // Upload images and get URLs
    const formData = {
      organisation: selectedOrganisation,
      feedback,
      suggestions,
      rating: rating as FEEDBACK_RATING,
      problem,
      files: uploadedFiles,
      device,
      deviceModel,
      os,
      browser,
      otherBrowser,
      timestamp: new Date(),
      userEmail: profile?.email || "Unable to retrieve email",
      userName: profile?.displayName || "Unable to retrieve username",
    };
    try {
      await addFeedback(formData); // Call the Firebase addFeedback function
      console.log("Feedback submitted successfully:", JSON.stringify(formData, null, 2));
      setIsSubmitted(true);
      return true;

    } catch (error) {
      console.error("Error submitting feedback:", error);
      return false;
    }
  };

  const ErrorModal = ({ onClose }) => (
    <div className={classes["invalid-file-overlay"]}>
      <div className={classes["invalid-file-content"]}>
        <h2>Invalid file format</h2>
        <p>Accepted formats: JPEG, PNG, GIF, BMP, JPG images only.</p>
        <button className={classes["file-modal-button"]} onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );

  const MissingFieldsModal = ({ onClose }) => (
    <div className={classes["missing-fields-overlay"]}>
      <div className={classes["missing-fields-content"]}>
        <h2>Incomplete Form</h2>
        <p>Please fill in all required fields before submitting.</p>
        <button className={classes["missing-fields-close-button"]} onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );

  // Function to open the submit confirmation modal
  const openModal = () => {
    if (isFormValid()) {
      setIsModalVisible(true); // Open submit confirmation modal
    } else {
      setIsMissingFieldsModalVisible(true); // Open missing fields modal
    }
  };


  // Function to confirm submission
  const confirmSubmit = async () => {
    setIsSubmitting(true); // Disable buttons and set "Submitting..." text
    const isSuccess = await handleSubmit(); // Wait for submission
    setIsSubmitting(false); // Reset after submission attempt
    if (isSuccess) {
      setIsModalVisible(true); // Show modal only if submission was successful
    }
  };

  const handleExit = () => {
    window.location.href = "https://demo.growthbeans.com/"; // Redirect to homepage
  };

  return (
    <div className={classes["main-container"]}>
      <div className={classes["form-container"]}>
        <div className={classes["form-header"]}>GROWTH CIRCLES <br />How can we support you today?</div>

        <div className={classes["tab-container"]}>
          <div
            className={`${classes["tab"]} ${activeSection === "feedback-suggestions" ? classes["active"] : ""}`}
            onClick={() => showSection("feedback-suggestions")}
          >
            Leave a feedback for us!
          </div>
          <div
            className={`${classes["tab"]} ${activeSection === "report-problem" ? classes["active"] : ""}`}
            onClick={() => showSection("report-problem")}
          >
            Report a Problem
          </div>
          <div
            className={`${classes["tab"]} ${activeSection === "device-survey" ? classes["active"] : ""}`}
            onClick={() => showSection("device-survey")}
          >
            How can we improve your device experience?
          </div>
        </div>

        {/* Feedback & Suggestions Section */}
        <div className={`${classes["section"]} ${activeSection === "feedback-suggestions" ? classes["active"] : classes["hidden"]}`}>
          <div className={classes["question-container"]}>
            <label className={classes["question-label"]}>Feedback</label>
            <p className={classes["question-label"]}>We would greatly appreciate it if you could kindly take a moment to provide us with your valuable feedback.</p>
            <textarea className={classes["input-textarea"]} value={feedback} onChange={(e) => setFeedback(e.target.value)} required></textarea>
          </div>
          <div className={classes["question-container"]}>
            <label className={classes["question-label"]}>Suggestions</label>
            <p className={classes["question-label"]}>Do you have any suggestions in mind for the app that requires improvement?</p>
            <textarea className={classes["input-textarea"]} value={suggestions} onChange={(e) => setSuggestions(e.target.value)} required></textarea>
          </div>
          <div className={classes["question-container"]}>
            <label className={classes["question-label"]}>Rate your experience with the app.</label>
            <div className={classes["rating-container"]}>
              {[1, 2, 3, 4, 5].map((star) => (
                <span
                  key={star}
                  className={`${classes["rating-item"]} ${rating !== "No Rating" && parseInt(rating) >= star ? classes["selected"] : ""}`}
                  onClick={() => selectRating(star)}
                >
                  ★
                </span>
              ))}
            </div>
            <div style={{ height: '50px' }}></div>
          </div>
          <div className={`${classes["section"]} ${activeSection === "feedback-suggestions" ? classes["active"] : classes["hidden"]}`}>
            <OrganisationDropdown organisations={organisations} onSelect={setSelectedOrganisation} />
            {/* Other content */}
          </div>
          <div className={classes["button-group"]}>
            <div className={classes["top-buttons"]}>
              <button
                className={`${classes["next-button"]} ${classes["button"]}`}
                onClick={() => showSection("report-problem")}
              >
                Next
              </button>
            </div>
            <div className={classes["bottom-buttons"]}>
              <button className={`${classes["exit-button"]} ${classes["button"]}`} onClick={handleExit}>Exit</button>
              <button className={`${classes["submit-button"]} ${classes["button"]}`} onClick={openModal}>Submit</button>
            </div>
          </div>
        </div>

        {/* Report a Problem Section */}
        <div className={`${classes["section"]} ${activeSection === "report-problem" ? classes["active"] : classes["hidden"]}`}>
          <div className={classes["question-container"]}>
            <label className={classes["question-label"]}>What are the problems that you encountered while using the app?</label>
            <textarea className={classes["input-textarea"]} value={problem} onChange={(e) => setProblem(e.target.value)} placeholder="Describe the issue..." required />
          </div>
          <div className={classes["question-container"]}>
            <label className={classes["question-label"]}>Do you have a screenshot of your concern issue? You can upload it here.</label>
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleFileUpload}
              className={classes["file-upload-container"]}
              id="file-upload"
              style={{ display: 'none' }}
            />
            <label htmlFor="file-upload" className={classes["file-upload-label"]}>
              {files.length > 0 ? "Upload More" : "Choose Files"}
            </label>
            <div id="file-list">
              {files.map((file, index) => (
                <div key={index} className={classes["file-item"]}>
                  <p>{file.name}</p>
                  {file.type.startsWith("image/") && (
                    <img src={URL.createObjectURL(file)} alt={`Uploaded ${file.name}`} className={classes["uploaded-image"]} />
                  )}
                  <button onClick={() => deleteFile(file)} className={classes["delete-button"]}>Delete</button>
                </div>
              ))}
            </div>
            <div className={`${classes["section"]} ${activeSection === "report-problem" ? classes["active"] : classes["hidden"]}`}>
              <OrganisationDropdown organisations={organisations} onSelect={setSelectedOrganisation} />
              {/* Other content */}
            </div>
          </div>
          <div className={classes["button-group"]}>
            <div className={classes["top-buttons"]}>
              <button className={`${classes["back-button"]} ${classes["button"]}`} onClick={() => showSection("feedback-suggestions")}>Back</button>
              <button className={`${classes["next-button"]} ${classes["button"]}`} onClick={() => showSection("device-survey")}>Next</button>
            </div>
            <div className={classes["bottom-buttons"]}>
              <button className={`${classes["exit-button"]} ${classes["button"]}`} onClick={handleExit}>Exit</button>
              <button className={`${classes["submit-button"]} ${classes["button"]}`} onClick={openModal}>Submit</button>
            </div>
          </div>
        </div>

        {/* Device Survey Section */}
        <div className={`${classes["section"]} ${activeSection === "device-survey" ? classes["active"] : classes["hidden"]}`}>
          <p>For better assistance, please provide information about the device, operating system, and browser used for the Growth Circles web app. This can help us understand the issue(s) and provide you with a more appropriate solution.</p>
          <div style={{ height: '20px' }}></div>
          <p>You can also skip any field you're unsure of. Thank you.</p>
          <div className={classes["question-container"]}>
            <div style={{ height: '30px' }}></div>
            <label className={classes["question-label"]}>Device</label>
            <select className={classes["input-select"]} value={device} onChange={(e) => setDevice(e.target.value)} required>
              <option value="" disabled>Select</option>
              <option>Mobile Phone</option>
              <option>Tablet</option>
              <option>Laptop Computer</option>
              <option>Desktop Computer</option>
            </select>

            <p>Kindly specify the device model:</p>
            <textarea className={classes["input-textarea"]} placeholder="Device model (optional)" value={deviceModel} onChange={(e) => setDeviceModel(e.target.value)} />
          </div>
          <div className={classes["question-container"]}>
            <label className={classes["question-label"]}>Operating System</label>
            <select className={classes["input-select"]} value={os} onChange={(e) => setOs(e.target.value)} required>
              <option value="" disabled>Select</option>
              <option>Android</option>
              <option>iOS</option>
              <option>Windows</option>
              <option>Linux</option>
              <option>MacOS</option>
            </select>
          </div>
          <hr style={{ border: "1px solid #ddd", marginTop: "10px" }} />
          <div className={classes["question-container"]}>
            <div style={{ height: '20px' }}></div>
            <label className={classes["question-label"]}>Web Browser</label>
            <select className={classes["input-select"]} value={browser} onChange={(e) => setBrowser(e.target.value)} required>
              <option value="" disabled>Select</option>
              <option>Google Chrome</option>
              <option>Safari</option>
              <option>Mozilla Firefox</option>
              <option>Microsoft Edge</option>
              <option>Other</option>
            </select>

            <p>Other web browser used? Please specify here:</p>
            <textarea className={classes["input-textarea"]} placeholder="Other browser (optional)" value={otherBrowser} onChange={(e) => setOtherBrowser(e.target.value)} />
          </div>
          <div className={`${classes["section"]} ${activeSection === "device-survey" ? classes["active"] : classes["hidden"]}`}>
            <OrganisationDropdown organisations={organisations} onSelect={setSelectedOrganisation} />
            {/* Other content */}
          </div>
          <div className={classes["button-group"]}>
            <div className={classes["top-buttons"]}>
              <button className={`${classes["back-button"]} ${classes["button"]}`} onClick={() => showSection("report-problem")}>Back</button>
            </div>
            <div className={classes["bottom-buttons"]}>
              <button className={`${classes["exit-button"]} ${classes["button"]}`} onClick={handleExit}>Exit</button>
              <button className={`${classes["submit-button"]} ${classes["button"]}`} onClick={openModal}>Submit</button>
            </div>
          </div>
        </div>
      </div>

      {/* Submit Confirmation Modal */}
      {isModalVisible && (
        <div className={`${classes["modal-overlay"]} ${isModalVisible ? classes["active"] : ""}`}>
          <div className={classes["modal-content"]}>
            {!isSubmitted ? (
              <>
                <p>Are you sure you want to submit your feedback?</p>
                <button className={`${classes["modal-button"]} ${classes["cancel-button"]}`}
                  onClick={() => setIsModalVisible(false)}
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button
                  className={`${classes["modal-button"]} ${classes["submit-button"]}`}
                  onClick={confirmSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </>
            ) : (
              <>
                <p>Thank you! Your feedback has been submitted successfully!</p>
                <button className={classes["return-button"]} onClick={handleExit}>Return to Main Page</button>
              </>
            )}
          </div>
        </div>
      )}
      {isMissingFieldsModalVisible && (
        <MissingFieldsModal
          onClose={() => setIsMissingFieldsModalVisible(false)}
        />
      )}
      {isErrorModalVisible && (
        <ErrorModal
          onClose={() => setIsErrorModalVisible(false)}
        />
      )}
    </div>
  );
};

export default FeedbackForm;
