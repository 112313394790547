import { Routes, Route, Navigate } from "react-router-dom";
import FeedbackForm from "./pages/FeedbackForm/FeedbackForm";
import FeedbackData from "./pages/FeedbackData/FeedbackData";
import Home from "./pages/Home/Home";
import LoginLanding from "./pages/Login/LoginLanding";
import Login from "./pages/Login/Login";
import ForgetPassword from "./pages/Login/ForgetPassword";
import Signup from "./pages/Login/Signup";
import LoadingWidget from "./components/utility/LoadingWidget";
import SendEmailTest from "./pages/AllCircles/Admin/SendEmailTest";
import ProfileSettings from "pages/ProfileSettings/ProfileSettings";
import UpdatePassword from "pages/ProfileSettings/UpdatePassword";

import "./App.css";
import { useAuthContext } from "./hooks/useAuthContext";
import Welcome from "./pages/AllCircles/Welcome";
import InfoPanel from "./pages/AllCircles/InfoPanel/InfoPanel";

import ErrorPage from "pages/Error/ErrorPage";

import { useEffect, useState } from "react";
import NoGCSession from "pages/NoGCSession/NoGCSession";
import Users from "./pages/AllCircles/Admin/Users";

import AdminOrganisations from "./pages/AllCircles/Admin/AdminOrganisations";
import AdminTopics from "./pages/AllCircles/Admin/AdminTopics";
import AdminPathways from "./pages/AllCircles/Admin/AdminPathways";
import AdminSkills from "./pages/AllCircles/Admin/AdminSkills";

import PageWrapper from "./components/utility/PageWrapper";
import ResourceRoot from "./pages/Resources/ResourceRoot";
import ViewJournal from "./pages/AllCircles/ViewJournal/ViewJournal";

import ResourcesLandingPage from "./pages/Resources/ResourceLandingPage";
import Community from "./pages/Community/Community";
import Chats from "pages/Chats/Chats";
import Journal from "pages/Journal/Journal";
import MyCircles from "pages/MyCircles/MyCircles";
import MyRoles from "pages/MyRoles/MyRoles";
import NewShowAllGC from "pages/AllCircles/Admin/NewShowAllGC";

import ActivityShareReflection from "pages/AllCircles/NationalGalleryPages/ActivityShareReflection";

import CompletedFinal from "pages/AllCircles/Completed/CompletedFinal";
import Finished from "pages/AllCircles/Finished/Finished";

import DataDeletion from "pages/DataDeletion/DataDeletion";
import VerifiedPage from "pages/Verification/Verified";
import VerificationForm from "pages/Verification/VerificationForm";

import JoinSessions from "pages/AllCircles/JoinSessions/JoinSessions";
import ObserverPage from "pages/AllCircles/Observer/ObserverPage";
import CopyUser from "pages/Remedy/CopyUser";
import LoginAs from "pages/Remedy/LoginAs";
import UpdateDisplayNameLowerCase from "pages/UpdateDisplayNameLowerCase";
import GenericSessionPage from "pages/AllCircles/GenericSessionPage/GenericSessionPage";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import AdminVideos from "pages/AllCircles/Admin/AdminVideos";
import AdminAddNewVideo from "pages/AllCircles/Admin/AdminAddNewVideo";
import AdminEditVideo from "pages/AllCircles/Admin/AdminEditVideo";
import SignupInvite from "pages/Login/SignupInvite";
import ExportData from "pages/AllCircles/Admin/organisations/ExportData";
import ExportDataAll from "pages/AllCircles/Admin/organisations/ExportDataAll";
import ChangePassword from "pages/ChangePassword/ChangePassword";
import ExportDataCustomFeedback from "pages/AllCircles/Admin/organisations/ExportDataCustomFeedback";
import ExportDataCustomORS from "pages/AllCircles/Admin/organisations/ExportDataCustomORS";
import ExportUsers from "pages/AllCircles/Admin/Export/ExportUsers";

import MainDashboard from "pages/AllCircles/Admin/MainDashboard";
import GrowthCirclesTypeSettings from "components/Organisation/GrowthCirclesTypeSettings/GrowthCirclesTypeSettings";
import AdminGrowthCircleType from "pages/AllCircles/Admin/AdminGrowthCircleType";
import MySubscriptions from "pages/MySubscriptions/MySubscriptions";
import Staging from "pages/MySubscriptions/Staging";
import SelfDiscovery from "pages/SelfDiscovery/SelfDiscovery";
import SelfDiscoverySinglePage from "pages/SelfDiscovery/SelfDiscoverySinglePage";
import MyGrowth from "pages/MyGrowth/MyGrowth";
import FacilitatorDetails from "pages/AllCircles/Admin/Dashboard/FacilitatorDetails/FacilitatorDetails";
import ErrorOrganisation from "pages/Error/ErrorOrganisation";
import JourneyWithSage from "pages/JourneyWithSage/JourneyWithSage";

import SelfDiscoverySettings from "pages/AllCircles/Admin/SelfDiscovery/SelfDiscoverySettings";
import AdminTasks from "pages/AllCircles/Admin/AdminTasks";

export const LocalStorageKeys = {
  //Keys for feedbackform
  REFLECTION: {
    DRAG_RATINGS: "refPersonalDragValues",
    GC_SMILEY_RATINGS: "refGcSmileyRatings",
    OTHER_SMILEY_RATINGS: "refOtherSmileyRatings",
    PERSONAL_SMILEY_RATINGS: "refPersonalSmileyRatings",
    OPEN_ENDED_FIELDS: "refOpenEndedFields",
    OTHER_BADGE_RATINGS: "refBadgeRatings",
  },
  //Keys for IntroRating
  INTRO_RATING: "introRatings",
};

const App = () => {
  const { user, profile, authIsReady } = useAuthContext();
  const [profileFetched, setProfileFetched] = useState(false);
  const { selectedOrganisation } = useOrganisationContext();

  useEffect(() => {
    if (!profile || profileFetched) return;

    setProfileFetched(false);
  }, [profile, profileFetched, selectedOrganisation]);

  return (
    <>
      {!authIsReady && (
        <PageWrapper>
          <LoadingWidget />
        </PageWrapper>
      )}
      {authIsReady && (
        <>
          <Routes>
            <Route
              path="/"
              element={!user ? <Navigate replace to="/login" /> : <Home />}
            />
            <Route
              path="/my-circles"
              element={!user ? <Navigate replace to="/login" /> : <MyCircles />}
            />
            <Route
              path="/chats"
              element={!user ? <Navigate replace to="/login" /> : <Chats />}
            />
            <Route
              path="/community-page"
              element={!user ? <Navigate replace to="/login" /> : <Community />}
            />
            <Route
              path="/my-subscriptions"
              element={
                !user ? <Navigate replace to="/login" /> : <MySubscriptions />
              }
            />
            <Route
              path="/self-awareness"
              element={
                !user ? <Navigate replace to="/login" /> : <SelfDiscovery />
              }
            />
            <Route
              path="/journey-with-sage"
              element={
                !user ? <Navigate replace to="/login" /> : <JourneyWithSage />
              }
            />

            <Route
              path="/self-discovery/:id"
              element={
                !user ? (
                  <Navigate replace to="/login" />
                ) : (
                  <SelfDiscoverySinglePage />
                )
              }
            />

            <Route
              path="/login"
              element={user ? <Navigate replace to="/" /> : <LoginLanding />}
            />
            <Route
              path="/login/*"
              element={user ? <Navigate replace to="/" /> : <Login />}
            />
            <Route
              path="/signup"
              element={user ? <Navigate replace to="/" /> : <Signup />}
            />
            <Route
              path="/resources"
              element={
                !user ? (
                  <Navigate replace to="/login" />
                ) : (
                  <ResourcesLandingPage />
                )
              }
            />

            <Route path="/forget-password" element={<ForgetPassword />} />

            <Route
              path="/journal"
              element={!user ? <Navigate replace to="/login" /> : <Journal />}
            />
            <Route
              path="/my-account"
              element={
                !user ? <Navigate replace to="/login" /> : <ProfileSettings />
              }
            />
            <Route
              path="/my-account/notifications"
              element={
                !user ? <Navigate replace to="/login" /> : <ProfileSettings />
              }
            />
            <Route
              path="/my-growth"
              element={!user ? <Navigate replace to="/login" /> : <MyGrowth />}
            />
            <Route
              path="/my-account/update-password"
              element={
                !user ? <Navigate replace to="/login" /> : <UpdatePassword />
              }
            />
            <Route path="/resources" element={<ResourceRoot />} />
            {/* admin dashboard */}

            <Route
              path="/all-circles/admin/dashboard"
              element={
                !user ? <Navigate replace to="/login" /> : <MainDashboard />
              }
            />

            <Route
              path="/all-circles/admin/self-discovery"
              element={
                !user ? (
                  <Navigate replace to="/login" />
                ) : (
                  <SelfDiscoverySettings />
                )
              }
            />

            <Route
              path="/all-circles/admin/dashboard/facilitators"
              element={
                !user ? (
                  <Navigate replace to="/login" />
                ) : (
                  <FacilitatorDetails />
                )
              }
            />

            <Route
              path="/all-circles/admin/users"
              element={
                !profile?.access ? <Navigate replace to="/login" /> : <Users />
              }
            />

            {/* export data */}
            <Route
              path="/all-circles/admin/all-gc"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <NewShowAllGC />
                )
              }
            />

            <Route
              path="/all-circles/admin/org-export"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <ExportData />
                )
              }
            />

            <Route
              path="/all-circles/admin/export-users"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <ExportUsers />
                )
              }
            />

            <Route
              path="/all-circles/admin/org-export-ors"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <ExportDataCustomORS />
                )
              }
            />

            <Route
              path="/all-circles/admin/org-custom-export"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <ExportDataCustomFeedback />
                )
              }
            />

            <Route
              path="/all-circles/admin/org-export-all"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <ExportDataAll />
                )
              }
            />

            {/* end of export data */}

            <Route
              path="/all-circles/admin/manage-videos"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <AdminVideos />
                )
              }
            />

            <Route
              path="/all-circles/admin/tasks"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <AdminTasks />
                )
              }
            />

            <Route
              path="/all-circles/admin/growthcircles-type"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <GrowthCirclesTypeSettings />
                )
              }
            />

            <Route
              path="/all-circles/admin/growthcircles-type/:id"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <AdminGrowthCircleType />
                )
              }
            />

            <Route
              path="/all-circles/admin/add-new-video"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <AdminAddNewVideo />
                )
              }
            />

            <Route
              path="/all-circles/admin/edit-video/:id"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <AdminEditVideo />
                )
              }
            />

            <Route
              path="/all-circles/admin/manage-organisations"
              element={<AdminOrganisations />}
            />
            <Route
              path="/all-circles/admin/manage-topics"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <AdminTopics />
                )
              }
            />
            <Route
              path="/all-circles/admin/manage-pathways"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <AdminPathways />
                )
              }
            />
            <Route
              path="/all-circles/admin/manage-skills"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <AdminSkills />
                )
              }
            />
            <Route
              path="/all-circles/admin/skills"
              element={
                !profile?.access ? (
                  <Navigate replace to="/login" />
                ) : (
                  <AdminSkills />
                )
              }
            />

            <Route
              path="/organisation/invite/:id"
              element={!user ? <SignupInvite /> : <Navigate replace to="/" />}
            />

            <Route
              path="/organisation/invite/:group/:id"
              element={!user ? <SignupInvite /> : <Navigate replace to="/" />}
            />
            {/* end of dashboard */}

            <Route path="/all-circles/welcome/:id" element={<Welcome />} />

            <Route path="/all-circles/staging/:id" element={<Staging />} />
            <Route
              path="/all-circles/info-panel/:id"
              element={
                !user ? <InfoPanel /> : <Navigate replace to="/growthcircle" />
              }
            />

            <Route path="session-does-not-exist" element={<NoGCSession />} />

            <Route
              path="/all-circles/view-journal/:participantId"
              element={<ViewJournal />}
            />
            <Route
              path="/all-circles/activity-share-reflection"
              element={<ActivityShareReflection />}
            />

            <Route path="/all-circles/completed" element={<CompletedFinal />} />

            <Route path="/all-circles/finished" element={<Finished />} />

            <Route path="/app-feedback" element={<FeedbackForm />} />

            <Route
              path="/all-circles/admin/feedback-data"
              element={<FeedbackData />}
            />

            {/* END of NG Routes */}

            {/* SUSS Routes */}

            <Route
              path="/all-circles/join-session"
              element={<JoinSessions />}
            />
            <Route
              path="/all-circles/join-session-observer/:id"
              element={<ObserverPage />}
            />

            {/* temp */}
            <Route path="/admin/copy-user" element={<CopyUser />} />
            <Route path="/admin/login-as" element={<LoginAs />} />
            {/* end of temp */}

            <Route path="/my-roles" element={user ? <MyRoles /> : <Login />} />
            <Route
              path="/account/email-verification-form"
              element={user ? <VerificationForm /> : <Login />}
            />

            <Route
              path="/account/change-password"
              element={user ? <ChangePassword /> : <Login />}
            />

            <Route
              path="/account/email-verification/:id"
              element={<VerifiedPage />}
            />
            <Route
              path="/organisation-invite-error"
              element={<ErrorOrganisation />}
            />

            <Route path="/data-deletion/:id" element={<DataDeletion />} />
            <Route path="/all-circles/email-test" element={<SendEmailTest />} />

            <Route path="/*" element={<ErrorPage />} />

            <Route path="/growthcircle" element={<GenericSessionPage />} />

            {/* TODO Delete after using, page to add displayNameLowerCase to all users */}
            <Route
              path="/update-display-name-lower-case"
              element={<UpdateDisplayNameLowerCase />}
            />
          </Routes>
        </>
      )}
    </>
  );
};

export default App;
