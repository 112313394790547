import { Modal } from "flowbite-react";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useOrganisationTopics } from "hooks/useOrganisationTopics";
import usePromise from "hooks/utility/usePromise";
import OrganisationTopics from "interface/OrganisationTopicsInterface";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { useState } from "react";
import toast from "react-hot-toast";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaSave } from "react-icons/fa";

import { MdSort } from "react-icons/md";
import TopicSortCards from "./TopicSortCard";

type TopicSortButtonProps = {
  topics: OrganisationTopics[];
};

const TopicSortButton = ({ topics }: TopicSortButtonProps) => {
  const [isSort, setIsSort] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { saveOrder } = useOrganisationTopics();
  const { isLoading, resolve } = usePromise();
  const { selectedOrganisation } = useOrganisationContext();

  const [isShowing, setShowing] = useState(false);

  const isSortHandler = () => {
    setSelectedIds([]);
    const initialSelectedIds = topics
      .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
      .map((activity) => activity.id);
    setSelectedIds(initialSelectedIds);
    setIsSort(!isSort);
    setShowing(!isShowing);
  };

  const saveHandler = async () => {
    resolve(() => saveOrder(selectedIds)).then(() => {
      setIsSort(!isSort);
      isSortHandler();
      toast.success("New order saved.");
    });
  };

  return (
    <>
      <Modal show={isShowing} size="7xl" popup>
        <Modal.Body
          className="relative py-4"
          style={{ color: "var(--text-colour)" }}
        >
          <AiFillCloseCircle
            size={30}
            style={{ color: "var(--icon-colour-0)" }}
            className="absolute top-2 right-2 cursor-pointer"
            onClick={isSortHandler}
          />
          <div className={`text-center ${classes["container"]} flex-col`}>
            <p className="text-2xl font-normal font-poppins">
              <strong>Sort Topics Category</strong>
            </p>
            <div className="grid grid-cols-4 gap-4 my-9">
              {selectedOrganisation &&
                topics
                  .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
                  .map((topic, index) => (
                    <TopicSortCards
                      order={selectedIds}
                      key={index}
                      topic={topic}
                      setOrder={setSelectedIds}
                      isSortable={isSort}
                    />
                  ))}
            </div>
            {topics.length === selectedIds.length && (
              <button
                onClick={saveHandler}
                className={`${classes["button"]} mx-auto mt-8  text-green-200 rounded-full text-lg flex gap-4 justify-center items-center`}
              >
                <FaSave size={20} /> {isLoading ? "Saving..." : "Save"}
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <div className={`${classes["container"]} flex flex-col gap-2`}>
        <button
          className={`${classes["button"]}  flex gap-2 justify-center items-center my-4`}
          onClick={isSortHandler}
        >
          <MdSort size={20} /> <span className="text-md">Sort</span>
        </button>
      </div>
    </>
  );
};

export default TopicSortButton;
