import React, { useEffect, useState, useCallback } from "react";
import NavAdmin from "components/Admin/NavAdmin";
import SidebarAdmin from "components/Admin/SidebarAdmin";
import feedbackClasses from "./FeedbackData.module.css";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { OrganisationContextProvider } from "context/OrganisationContext";
import { getFeedbacks, deleteFeedback, FEEDBACK_RATING } from "models/feedback";
import { Timestamp } from "firebase/firestore";
import {
  BiComment,
  BiHighlight,
  BiErrorCircle,
  BiUserCircle,
  BiCog,
} from "react-icons/bi";
import { MdEmail, MdGroups } from "react-icons/md";
import { BsStar } from "react-icons/bs";
import {
  FaMobileAlt,
  FaDesktop,
  FaGlobe,
  FaCalendarAlt,
  FaLaptop,
} from "react-icons/fa";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";

interface Feedback {
  id: string;
  organisation?: string;
  feedback: string;
  suggestions: string;
  rating: FEEDBACK_RATING;
  problem: string;
  files: string[];
  device: string;
  deviceModel: string;
  os: string;
  browser: string;
  otherBrowser: string;
  timestamp: Date | string;
  userEmail?: string;
  userName?: string;
}

interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    hidden: boolean;
  }[];
}

type ChartDataPoint = {
  label: string;
  "Average Rating": number | null;
  "Number of Responses": number | null;
  n: number | null;
};

const FeedbackData: React.FC = () => {
  const [feedbackList, setFeedbackList] = useState<Feedback[]>([]);
  const [filteredFeedbackList, setFilteredFeedbackList] = useState<Feedback[]>(
    []
  );
  const [selectedFeedback, setSelectedFeedback] = useState<Feedback | null>(
    null
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [feedbackToDelete, setFeedbackToDelete] = useState<Feedback | null>(
    null
  );
  const [isDeleting, setIsDeleting] = useState(false);
  const [chartData, setChartData] = useState<ChartData | null>(null);

  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const paginatedFeedbackList = filteredFeedbackList.slice(
    (currentPage - 1) * itemsPerPage, // Calculate the starting index for current page
    currentPage * itemsPerPage // Calculate the ending index for current page
  );

  const totalPages = Math.ceil(filteredFeedbackList.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Define options for dropdown filters
  const organisationOptions = Array.from(
    new Set(feedbackList.map((feedback) => feedback.organisation || ""))
  );

  const osOptions = ["Android", "iOS", "Windows", "Linux", "MacOS"];
  const deviceOptions = [
    "Mobile Phone",
    "Tablet",
    "Laptop Computer",
    "Desktop Computer",
  ];
  const browserOptions = [
    "Google Chrome",
    "Safari",
    "Mozilla Firefox",
    "Microsoft Edge",
    "Other",
  ];
  const ratingOptions = ["1", "2", "3", "4", "5", "No Rating"];
  const formSectionOptions = ["Feedback", "Problem", "Device"];

  const monthOptions = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Filter states
  const [osFilter, setOsFilter] = useState<string[]>(["All", ...osOptions]);
  const [deviceFilter, setDeviceFilter] = useState<string[]>([
    "All",
    ...deviceOptions,
  ]);
  const [browserFilter, setBrowserFilter] = useState<string[]>([
    "All",
    ...browserOptions,
  ]);
  const [ratingFilter, setRatingFilter] = useState<string[]>([
    "All",
    ...ratingOptions,
  ]);
  const [dateFilter, setDateFilter] = useState<string[]>(["All"]);
  const [formSectionFilter, setFormSectionFilter] = useState<string[]>([
    "All",
    ...formSectionOptions,
  ]);
  const [monthFilter, setMonthFilter] = useState<string[]>([]);
  const [filterType, setFilterType] = useState("average");
  const [organisationFilter, setOrganisationFilter] = useState<string[]>([
    "All",
    ...organisationOptions,
  ]);

  const calculateChartData = useCallback(
    (feedbacks: Feedback[], filterType: string) => {
      const isMonthBased =
        monthFilter.includes("All") ||
        (monthFilter.length > 0 && !monthFilter.includes("All"));

      const ratingsByTime: Record<string, number[]> = {};
      const responsesByTime: Record<string, number> = {};

      // Filter by rating first
      const filteredFeedbacks = feedbacks.filter((feedback) => {
        if (ratingFilter.includes("All")) return true; // Include all ratings
        if (
          feedback.rating === "No Rating" &&
          ratingFilter.includes("No Rating")
        )
          return true;
        return ratingFilter.includes(feedback.rating.toString());
      });

      filteredFeedbacks.forEach((feedback) => {
        const date = new Date(feedback.timestamp);
        const year = date.getFullYear().toString();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const timeKey = isMonthBased ? `${month}-${year}` : year;

        // Count all responses
        if (!responsesByTime[timeKey]) responsesByTime[timeKey] = 0;
        responsesByTime[timeKey] += 1;

        // Only count numeric ratings for average calculation
        if (feedback.rating !== "No Rating") {
          const numericRating = parseInt(feedback.rating.toString(), 10);
          if (!ratingsByTime[timeKey]) ratingsByTime[timeKey] = [];
          ratingsByTime[timeKey].push(numericRating);
        }
      });

      if (filteredFeedbacks.length === 0) {
        setChartData({
          labels: [],
          datasets: [
            {
              label: "Average Rating",
              data: [],
              hidden: filterType !== "average",
            },
            {
              label: "Number of Responses",
              data: [],
              hidden: filterType !== "responses",
            },
          ],
        });
        return;
      }

      const chartData = Object.keys(responsesByTime).map((timeKey) => {
        const ratings = ratingsByTime[timeKey] || [];
        const average =
          ratings.length > 0
            ? ratings.reduce((sum, rating) => sum + rating, 0) / ratings.length
            : 0;

        return {
          timeKey,
          average,
          responseCount: responsesByTime[timeKey],
        };
      });

      const labels = Array.from(
        new Set(chartData.map((item) => item.timeKey))
      ).sort();

      const ratingData = labels.map((label) => {
        const dataPoint = chartData.find((item) => item.timeKey === label);
        return dataPoint ? dataPoint.average : 0;
      });

      const responseData = labels.map((label) => {
        const dataPoint = chartData.find((item) => item.timeKey === label);
        return dataPoint ? dataPoint.responseCount : 0;
      });

      setChartData({
        labels,
        datasets: [
          {
            label: "Average Rating",
            data: ratingData,
            hidden: filterType !== "average",
          },
          {
            label: "Number of Responses",
            data: responseData,
            hidden: filterType !== "responses",
          },
        ],
      });
    },
    [monthFilter, ratingFilter]
  );

  useEffect(() => {
    if (
      organisationFilter.includes("All") &&
      organisationFilter.length !== organisationOptions.length + 1
    ) {
      setOrganisationFilter(["All", ...organisationOptions]);
    }
  }, [organisationOptions, organisationFilter]);

  const handleCheckboxChange = (
    filterSetter: React.Dispatch<React.SetStateAction<string[]>>,
    value: string,
    options: string[]
  ) => {
    filterSetter((prev) => {
      let newOptions = [...prev];

      if (value === "All") {
        // If "All" is clicked, toggle between selecting all or none
        return newOptions.includes("All") ? [] : ["All", ...options];
      } else {
        // Toggle individual options
        if (newOptions.includes(value)) {
          newOptions = newOptions.filter((item) => item !== value); // Deselect the specific option
        } else {
          newOptions.push(value); // Select the specific option
        }

        // If all individual options are selected, include "All"
        const individualOptions = options.filter((item) => item !== "All");
        if (individualOptions.every((option) => newOptions.includes(option))) {
          newOptions = ["All", ...individualOptions];
        } else {
          // Remove "All" if not all individual options are selected
          newOptions = newOptions.filter((item) => item !== "All");
        }
      }

      return newOptions;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getFeedbacks();
        const formattedData = data
          .map((feedback) => ({
            ...feedback,
            id: feedback.id,
            timestamp:
              feedback.timestamp instanceof Timestamp
                ? feedback.timestamp.toDate()
                : new Date(feedback.timestamp),
          }))
          .sort(
            (a, b) =>
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          );

        setFeedbackList(formattedData);
        setFilteredFeedbackList(formattedData);

        const years = [
          "All",
          ...new Set(
            formattedData.map((f) =>
              new Date(f.timestamp).getFullYear().toString()
            )
          ),
        ];
        setDateFilter(years);

        calculateChartData(formattedData, filterType); // Call the memoized function
      } catch (error) {
        console.error("Error fetching feedback data:", error);
        setFeedbackList([]);
      }
    };

    fetchData();
  }, [calculateChartData, filterType]); // Include all dependencies

  const matchSectionFilter = useCallback(
    (feedback: Feedback) => {
      const conditions = {
        Feedback: feedback.feedback && feedback.suggestions && feedback.rating,
        Problem: feedback.problem && feedback.files.length > 0,
        Device: feedback.device && feedback.os && feedback.browser,
        All: true,
      };

      return (
        formSectionFilter.length === 0 ||
        formSectionFilter.includes("All") ||
        formSectionFilter.some((filter) => conditions[filter])
      );
    },
    [formSectionFilter]
  );

  useEffect(() => {
    const isAllDeselected =
      organisationFilter.length === 0 ||
      (organisationFilter.length === 1 && organisationFilter[0] === "All") ||
      (!osFilter.includes("All") && osFilter.length === 0) ||
      (!deviceFilter.includes("All") && deviceFilter.length === 0) ||
      (!browserFilter.includes("All") && browserFilter.length === 0) ||
      (!ratingFilter.includes("All") && ratingFilter.length === 0) ||
      (!dateFilter.includes("All") && dateFilter.length === 0) ||
      (!formSectionFilter.includes("All") && formSectionFilter.length === 0);

    if (isAllDeselected) {
      setFilteredFeedbackList([]); // No data to display
      calculateChartData([], filterType); // Update chart data with empty array
      setCurrentPage(1); // Reset to the first page
      return;
    }

    const filteredList = feedbackList.filter((feedback) => {
      const feedbackDate = new Date(feedback.timestamp);

      const monthNamesToIndex: { [key: string]: number } = {
        January: 0,
        February: 1,
        March: 2,
        April: 3,
        May: 4,
        June: 5,
        July: 6,
        August: 7,
        September: 8,
        October: 9,
        November: 10,
        December: 11,
      };

      return (
        (organisationFilter.length > 0
          ? organisationFilter.includes("All") ||
            (feedback.organisation &&
              organisationFilter.includes(feedback.organisation))
          : true) &&
        (osFilter.length > 0
          ? osFilter.includes(feedback.os || "") || osFilter.includes("All")
          : true) &&
        (deviceFilter.length > 0
          ? deviceFilter.includes(feedback.device || "") ||
            deviceFilter.includes("All")
          : true) &&
        (browserFilter.length > 0
          ? browserFilter.includes(feedback.browser || "") ||
            browserFilter.includes("All")
          : true) &&
        (ratingFilter.length > 0
          ? ratingFilter.includes("All") ||
            ratingFilter.includes(feedback.rating.toString()) ||
            (feedback.rating === "No Rating" &&
              ratingFilter.includes("No Rating"))
          : true) &&
        (dateFilter.length > 0
          ? dateFilter.includes("All") ||
            dateFilter.includes(feedbackDate.getFullYear().toString())
          : true) &&
        (monthFilter.length > 0
          ? monthFilter.includes("All") ||
            monthFilter.some(
              (month) => feedbackDate.getMonth() === monthNamesToIndex[month]
            )
          : true) &&
        matchSectionFilter(feedback)
      );
    });

    setFilteredFeedbackList(filteredList);
    calculateChartData(filteredList, filterType); // Recalculate chart data with filterType
    setCurrentPage(1); // Reset to first page after filtering
  }, [
    organisationFilter,
    osFilter,
    deviceFilter,
    browserFilter,
    ratingFilter,
    dateFilter,
    monthFilter,
    formSectionFilter,
    feedbackList,
    matchSectionFilter,
    calculateChartData,
    filterType,
  ]);

  interface DropdownFilterProps {
    label: string;
    options: string[];
    selectedOptions: string[];
    onOptionToggle: (value: string) => void;
    enableSearch?: boolean; // To enable search functionality
  }
  //console.log("Filtered Feedback:", filteredFeedbackList);
  const DropdownFilter: React.FC<DropdownFilterProps> = ({
    label,
    options,
    selectedOptions,
    onOptionToggle,
    enableSearch = false, // Default is false
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    const toggleDropdown = () => {
      setIsOpen((prev) => !prev);
    };

    const handleOptionToggle = (value: string) => {
      onOptionToggle(value);
    };

    const filteredOptions = enableSearch
      ? options.filter((option) =>
          option.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : options;

    return (
      <div className={feedbackClasses.dropdownContainer}>
        <button
          className={feedbackClasses.dropdownButton}
          onClick={toggleDropdown}
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            color: "#333",
            padding: "8px 12px",
            borderRadius: "4px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "200px",
          }}
        >
          <span>{label}</span>
          <span
            style={{
              marginLeft: "8px",
              transform: isOpen ? "rotate(180deg)" : "none",
              transition: "transform 0.2s ease",
            }}
          >
            ▼
          </span>
        </button>
        {isOpen && (
          <div className={feedbackClasses.dropdownMenu}>
            {enableSearch && (
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search..."
                className={feedbackClasses.searchInput}
              />
            )}
            {filteredOptions.map((option) => (
              <label key={option} className={feedbackClasses.dropdownItem}>
                <input
                  type="checkbox"
                  checked={selectedOptions.includes(option)}
                  onChange={() => handleOptionToggle(option)}
                />
                {option}
              </label>
            ))}
          </div>
        )}
      </div>
    );
  };

  const getColourForYear = (label: string): string => {
    const year = label.split("-")[label.split("-").length - 1]; // Extract the year from the label
    switch (year) {
      case "2023":
        return "rgba(36, 165, 251, 0.7)"; // Colour for 2023
      case "2024":
        return "rgba(153, 102, 255, 0.7)"; // Colour for 2024
      case "2025":
        return "rgba(251, 161, 26, 0.7)"; // Colour for 2025
      case "2026":
        return "rgba(72, 229, 33, 0.7)"; // Colour for 2026
      default:
        return "rgba(255, 99, 132, 0.7)"; // Default colour for other years
    }
  };

  useEffect(() => {
    const isMonthBased =
      monthFilter.includes("All") ||
      (monthFilter.length > 0 && !monthFilter.includes("All"));
    const adjustedFilterType = isMonthBased
      ? `${filterType}-month`
      : filterType;

    calculateChartData(filteredFeedbackList, adjustedFilterType);
  }, [monthFilter, filterType, filteredFeedbackList, calculateChartData]);

  const formatTimestamp = (timestamp: Date | string) => {
    const date = new Date(timestamp);
    return isNaN(date.getTime()) ? "Invalid Date" : date.toLocaleDateString();
  };

  const confirmDelete = (feedback: Feedback) => {
    setFeedbackToDelete(feedback);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    if (feedbackToDelete) {
      setIsDeleting(true);
      try {
        await deleteFeedback(feedbackToDelete.id);
        setFeedbackList(
          feedbackList.filter((feedback) => feedback.id !== feedbackToDelete.id)
        );
        setShowDeleteModal(false);
        setFeedbackToDelete(null);
      } catch (error) {
        console.error("Error deleting feedback:", error);
      } finally {
        setIsDeleting(false); // Reset deletion state
      }
    }
  };

  const getPaginationButtons = (
    currentPage: number,
    totalPages: number
  ): (string | number)[] => {
    const buttons: (string | number)[] = [];

    if (totalPages <= 3) {
      // If total pages are 3 or fewer, show all pages
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(i);
      }
    } else {
      // Always include the first page
      buttons.push(1);

      if (currentPage === 1) {
        // First page: show next page and ellipsis if needed
        buttons.push(2);
        if (totalPages > 3) buttons.push("...");
        buttons.push(totalPages);
      } else if (currentPage === totalPages) {
        // Last page: show ellipsis and the two previous pages
        buttons.push("...");
        buttons.push(totalPages - 1);
        buttons.push(totalPages);
      } else if (currentPage === 2) {
        // Second page: show the first three pages and ellipsis if needed
        buttons.push(2);
        buttons.push(3);
        if (totalPages > 3) buttons.push("...");
        buttons.push(totalPages);
      } else if (currentPage === totalPages - 1) {
        // Second last page: show ellipsis and the last three pages
        buttons.push("...");
        buttons.push(totalPages - 2);
        buttons.push(totalPages - 1);
        buttons.push(totalPages);
      } else {
        // General case for pages in the middle
        buttons.push("...");
        buttons.push(currentPage - 1);
        buttons.push(currentPage);
        buttons.push(currentPage + 1);
        buttons.push("...");
        buttons.push(totalPages);
      }
    }
    return buttons;
  };

  return (
    <div>
      <NavAdmin />
      <div className={`${classes.container} min-h-screen`}>
        <SidebarAdmin />
        <div className={`${classes.main} mb-20`}>
          <div className={classes.title}>
            <h4>Feedback Data</h4>
          </div>
          <OrganisationContextProvider>
            {/* Filter Inputs */}
            <div className={feedbackClasses.filtersAndChart}>
              <div className={feedbackClasses.filterContainer}>
                {/* Organisation Filter */}
                <label className={feedbackClasses.filterLabel}>
                  Organisation:
                  <DropdownFilter
                    label="Organisation"
                    options={["All", ...organisationOptions]}
                    selectedOptions={organisationFilter}
                    onOptionToggle={(value) =>
                      handleCheckboxChange(
                        setOrganisationFilter,
                        value,
                        organisationOptions
                      )
                    }
                    enableSearch={true}
                  />
                </label>

                {/* OS Filter */}
                <label className={feedbackClasses.filterLabel}>
                  OS:
                  <DropdownFilter
                    label="OS"
                    options={["All", ...osOptions]}
                    selectedOptions={osFilter}
                    onOptionToggle={(value) =>
                      handleCheckboxChange(setOsFilter, value, [
                        "All",
                        ...osOptions,
                      ])
                    }
                  />
                </label>

                {/* Device Filter */}
                <label className={feedbackClasses.filterLabel}>
                  Device:
                  <DropdownFilter
                    label="Device"
                    options={["All", ...deviceOptions]}
                    selectedOptions={deviceFilter}
                    onOptionToggle={(value) =>
                      handleCheckboxChange(setDeviceFilter, value, [
                        "All",
                        ...deviceOptions,
                      ])
                    }
                  />
                </label>

                {/* Browser Filter */}
                <label className={feedbackClasses.filterLabel}>
                  Browser:
                  <DropdownFilter
                    label="Browser"
                    options={["All", ...browserOptions]}
                    selectedOptions={browserFilter}
                    onOptionToggle={(value) =>
                      handleCheckboxChange(setBrowserFilter, value, [
                        "All",
                        ...browserOptions,
                      ])
                    }
                  />
                </label>

                {/* Rating Filter */}
                <label className={feedbackClasses.filterLabel}>
                  Rating:
                  <DropdownFilter
                    label="Rating"
                    options={["All", ...ratingOptions]}
                    selectedOptions={ratingFilter}
                    onOptionToggle={(value) =>
                      handleCheckboxChange(setRatingFilter, value, [
                        "All",
                        ...ratingOptions,
                      ])
                    }
                  />
                </label>

                {/* Year Filter */}
                <label className={feedbackClasses.filterLabel}>
                  Year:
                  <DropdownFilter
                    label="Year"
                    options={[
                      "All",
                      ...Array.from(
                        new Set(
                          feedbackList.map((feedback) =>
                            new Date(feedback.timestamp).getFullYear()
                          )
                        )
                      )
                        .sort()
                        .map((year) => year.toString()),
                    ]} // Convert years to strings
                    selectedOptions={dateFilter}
                    onOptionToggle={(value) =>
                      handleCheckboxChange(setDateFilter, value, [
                        "All",
                        ...Array.from(
                          new Set(
                            feedbackList.map((feedback) =>
                              new Date(feedback.timestamp).getFullYear()
                            )
                          )
                        )
                          .sort()
                          .map((year) => year.toString()),
                      ])
                    }
                  />
                </label>

                {/* Month Filter */}
                <label className={feedbackClasses.filterLabel}>
                  Month:
                  <DropdownFilter
                    label="Month"
                    options={["All", ...monthOptions]}
                    selectedOptions={monthFilter}
                    onOptionToggle={(value) =>
                      handleCheckboxChange(setMonthFilter, value, [
                        "All",
                        ...monthOptions,
                      ])
                    }
                  />
                </label>

                {/* Form Section Filter */}
                <label className={feedbackClasses.filterLabel}>
                  Form Section:
                  <DropdownFilter
                    label="Form Section"
                    options={["All", ...formSectionOptions]}
                    selectedOptions={formSectionFilter}
                    onOptionToggle={(value) =>
                      handleCheckboxChange(setFormSectionFilter, value, [
                        "All",
                        ...formSectionOptions,
                      ])
                    }
                  />
                </label>
              </div>

              {/* Charts */}
              <div className={feedbackClasses.filtersAndChart}>
                <div className={feedbackClasses.chartContainer}>
                  <div className={feedbackClasses.dropdownContainer}>
                    <label className={feedbackClasses.filterLabel}>
                      Select Chart Type:
                      <select
                        value={filterType}
                        onChange={(e) => {
                          setFilterType(e.target.value);
                          const isMonthBased =
                            monthFilter.length > 0 &&
                            !monthFilter.includes("All");
                          const adjustedFilterType = isMonthBased
                            ? `${e.target.value}-month`
                            : e.target.value;
                          calculateChartData(
                            filteredFeedbackList,
                            adjustedFilterType
                          );
                        }}
                        className={feedbackClasses.dropdown}
                      >
                        <option value="average">Average Rating</option>
                        <option value="responses">Number of Responses</option>
                      </select>
                    </label>
                    {/* Filter Summary */}
                    <div
                      style={{
                        marginBottom: "10px",
                        padding: "8px 12px",
                        backgroundColor: "#f4f6f8",
                        borderRadius: "8px",
                        border: "1px solid #e0e0e0",
                        fontSize: "10px",
                        lineHeight: "1.6",
                      }}
                    >
                      <h4 style={{ color: "#007BFF", fontWeight: "600" }}>
                        Selected Filters
                      </h4>
                      <p
                        style={{
                          margin: "0",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "12px",
                        }}
                      >
                        <span>
                          <strong>Organisation:</strong>{" "}
                          {organisationFilter.includes("All")
                            ? "All"
                            : organisationFilter.join(", ")}
                        </span>
                        <span>
                          <strong>OS:</strong>{" "}
                          {osFilter.includes("All")
                            ? "All"
                            : osFilter.join(", ")}
                        </span>
                        <span>
                          <strong>Device:</strong>{" "}
                          {deviceFilter.includes("All")
                            ? "All"
                            : deviceFilter.join(", ")}
                        </span>
                        <span>
                          <strong>Browser:</strong>{" "}
                          {browserFilter.includes("All")
                            ? "All"
                            : browserFilter.join(", ")}
                        </span>
                        <span>
                          <strong>Rating:</strong>{" "}
                          {ratingFilter.includes("All")
                            ? "All"
                            : ratingFilter.join(", ")}
                        </span>
                        <span>
                          <strong>Year:</strong>{" "}
                          {dateFilter.includes("All")
                            ? "All"
                            : dateFilter.join(", ")}
                        </span>
                        <span>
                          <strong>Month:</strong>{" "}
                          {monthFilter.includes("All")
                            ? "All"
                            : monthFilter.join(", ")}
                        </span>
                        <span>
                          <strong>Section:</strong>{" "}
                          {formSectionFilter.includes("All")
                            ? "All"
                            : formSectionFilter.join(", ")}
                        </span>
                      </p>
                    </div>
                  </div>

                  {chartData &&
                  chartData.labels.length > 0 &&
                  chartData.datasets.some((dataset) =>
                    dataset.data.some((value) => value > 0)
                  ) ? (
                    <ResponsiveContainer width="90%" height={450}>
                      <BarChart
                        data={chartData.labels.map<ChartDataPoint>(
                          (label, index) => {
                            return {
                              label,
                              "Average Rating":
                                typeof chartData.datasets[0]?.data[index] ===
                                "number"
                                  ? parseFloat(
                                      (
                                        chartData.datasets[0].data[
                                          index
                                        ] as number
                                      ).toFixed(1)
                                    )
                                  : null,
                              "Number of Responses":
                                chartData.datasets[1]?.data[index] ?? null,
                              n: chartData.datasets[1]?.data[index] ?? null,
                            };
                          }
                        )}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="label" interval={0} fontSize={10} />
                        <YAxis
                          domain={
                            filterType === "average"
                              ? [0, 5]
                              : (() => {
                                  const maxResponse = Math.max(
                                    ...(chartData.datasets[1]?.data || [0])
                                  );
                                  const buffer = 5; // Add a small buffer for better spacing
                                  return [
                                    0,
                                    Math.ceil((maxResponse + buffer) / 10) * 10,
                                  ]; // Adjust dynamically
                                })()
                          }
                          ticks={
                            filterType === "average"
                              ? [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5]
                              : (() => {
                                  const maxResponse = Math.max(
                                    ...(chartData.datasets[1]?.data || [0])
                                  );
                                  // If the maximum response is greater than 500, the step size is 100.
                                  // If the maximum response is greater than 100 but less than or equal to 500, the step size is 50.
                                  // If the maximum response is greater than 50 but less than or equal to 100, the step size is 50.
                                  // Otherwise, the step size is 5.
                                  const step =
                                    maxResponse > 500
                                      ? 100
                                      : maxResponse > 100
                                      ? 50
                                      : maxResponse > 50
                                      ? 10
                                      : 5;
                                  const upperBound =
                                    Math.ceil(maxResponse / step) * step;
                                  const tickValues: number[] = [];
                                  for (let i = 0; i <= upperBound; i += step) {
                                    tickValues.push(i);
                                  }
                                  return tickValues;
                                })()
                          }
                        />
                        <Tooltip
                          formatter={(value, name, props) => {
                            if (
                              name === "Average Rating" &&
                              typeof value === "number"
                            ) {
                              const roundedValue = parseFloat(value.toFixed(1));
                              const numResponses = props.payload?.n ?? 0;
                              return [
                                `${roundedValue} (n = ${numResponses})`,
                                name,
                              ];
                            }
                            return [value, name];
                          }}
                        />
                        <Legend verticalAlign="top" />
                        {filterType === "average" ? (
                          <Bar dataKey="Average Rating">
                            {chartData.labels.map((label, index) => (
                              <Cell
                                key={index}
                                fill={getColourForYear(label)}
                              />
                            ))}
                          </Bar>
                        ) : (
                          <Bar dataKey="Number of Responses">
                            {chartData.labels.map((label, index) => (
                              <Cell
                                key={index}
                                fill={getColourForYear(label)}
                              />
                            ))}
                          </Bar>
                        )}
                      </BarChart>
                    </ResponsiveContainer>
                  ) : (
                    <p className={feedbackClasses.noDataText}>
                      No data available.
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4 py-4">
              {/* Total Feedback Submitted */}
              <div className={feedbackClasses.totalFeedback}>
                <p>Total of {feedbackList.length} Feedback</p>
              </div>
              {paginatedFeedbackList.length > 0 ? (
                paginatedFeedbackList.map((feedback, index) => (
                  <div key={index} className={feedbackClasses.feedbackItem}>
                    <div className={feedbackClasses.iconTextContainer}>
                      <MdGroups className={feedbackClasses.icon} />
                      <strong>Organisation:</strong> {feedback.organisation}
                    </div>
                    <div className={feedbackClasses.iconTextContainer}>
                      <BiComment className={feedbackClasses.icon} />
                      <strong>Feedback:</strong> {feedback.feedback}
                    </div>
                    <div className={feedbackClasses.iconTextContainer}>
                      <BiHighlight className={feedbackClasses.icon} />
                      <strong>Suggestions:</strong> {feedback.suggestions}
                    </div>
                    <div className={feedbackClasses.iconTextContainer}>
                      <BsStar className={feedbackClasses.icon} />
                      <strong>Rating:</strong> {feedback.rating || "No Rating"}
                    </div>
                    <div className={feedbackClasses.iconTextContainer}>
                      <BiErrorCircle className={feedbackClasses.icon} />
                      <strong>Problem:</strong> {feedback.problem}
                    </div>
                    <div className={feedbackClasses.iconTextContainer}>
                      <FaMobileAlt className={feedbackClasses.icon} />
                      <strong>Device:</strong> {feedback.device}
                    </div>
                    <div className={feedbackClasses.iconTextContainer}>
                      <FaDesktop className={feedbackClasses.icon} />
                      <strong>Device Model:</strong> {feedback.deviceModel}
                    </div>
                    <div className={feedbackClasses.iconTextContainer}>
                      <BiCog className={feedbackClasses.icon} />
                      <strong>OS:</strong> {feedback.os}
                    </div>
                    <div className={feedbackClasses.iconTextContainer}>
                      <FaLaptop className={feedbackClasses.icon} />
                      <strong>Browser:</strong> {feedback.browser}
                    </div>
                    <div className={feedbackClasses.iconTextContainer}>
                      <FaGlobe className={feedbackClasses.icon} />
                      <strong>Other Browser:</strong> {feedback.otherBrowser}
                    </div>
                    <div className={feedbackClasses.iconTextContainer}>
                      <FaCalendarAlt className={feedbackClasses.icon} />
                      <strong>Date Submitted:</strong>{" "}
                      {formatTimestamp(feedback.timestamp)}
                    </div>
                    <div className={feedbackClasses.iconTextContainer}>
                      <BiUserCircle className={feedbackClasses.icon} />
                      <strong>Username:</strong> {feedback.userName || "N/A"}
                    </div>
                    <div className={feedbackClasses.iconTextContainer}>
                      <MdEmail className={feedbackClasses.icon} />
                      <strong>Email:</strong> {feedback.userEmail || "N/A"}
                    </div>

                    {/* View Details button */}
                    <button
                      onClick={() => setSelectedFeedback(feedback)}
                      className={feedbackClasses["view-details-button"]}
                    >
                      View Details
                    </button>

                    {/* Delete button */}
                    <button
                      onClick={() => confirmDelete(feedback)}
                      className={feedbackClasses["delete-button"]}
                    >
                      <span
                        style={{ fontSize: "20px", transform: "scale(1.3)" }}
                      >
                        -
                      </span>
                    </button>
                  </div>
                ))
              ) : (
                <p>No feedback found with the selected filters.</p>
              )}
            </div>

            {/* Pagination Controls */}
            {paginatedFeedbackList.length > 0 && totalPages > 1 && (
              <div className={feedbackClasses.pagination}>
                <button
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                  className={feedbackClasses.paginationButton}
                >
                  {"<"}
                </button>
                {getPaginationButtons(currentPage, totalPages).map(
                  (btn, index) =>
                    btn === "..." ? (
                      <span key={index} className={feedbackClasses.ellipsis}>
                        {btn}
                      </span>
                    ) : (
                      <button
                        key={index}
                        onClick={() =>
                          typeof btn === "number" && setCurrentPage(btn)
                        }
                        className={`${feedbackClasses.paginationButton} ${
                          btn === currentPage
                            ? feedbackClasses.activeButton
                            : ""
                        }`}
                      >
                        {btn}
                      </button>
                    )
                )}
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className={feedbackClasses.paginationButton}
                >
                  {">"}
                </button>
              </div>
            )}
          </OrganisationContextProvider>
        </div>
      </div>

      {/* Modal for showing feedback details */}
      {selectedFeedback && (
        <div className={feedbackClasses.modalOverlay}>
          <div className={feedbackClasses.modalContent}>
            <button
              onClick={() => setSelectedFeedback(null)}
              className={feedbackClasses.closeButton}
            >
              &times;
            </button>
            <div className={feedbackClasses.modalScrollableContent}>
              <h3>
                <strong>Uploaded Files:</strong>
              </h3>
              {selectedFeedback.files.length > 0 ? (
                selectedFeedback.files.map((file, idx) => (
                  <img
                    key={idx}
                    src={file}
                    alt={`Uploaded ${idx + 1}`}
                    className={feedbackClasses.uploadedImage}
                  />
                ))
              ) : (
                <p>No files uploaded.</p>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Delete confirmation modal */}
      {showDeleteModal && (
        <div className={feedbackClasses.modalOverlay}>
          <div className={feedbackClasses.modalContent}>
            <button
              onClick={() => setShowDeleteModal(false)}
              className={feedbackClasses.closeButton}
              disabled={isDeleting}
            >
              &times;
            </button>
            <p>Are you sure you want to delete this feedback?</p>
            <div className={feedbackClasses.buttonGroup}>
              <button
                onClick={handleDelete}
                className={feedbackClasses["confirm-delete-button"]}
                disabled={isDeleting}
              >
                {isDeleting ? "Deleting..." : "Yes, I'm sure"}
              </button>
              <button
                onClick={() => setShowDeleteModal(false)}
                className={feedbackClasses["cancel-button"]}
                disabled={isDeleting} // Button disabled while deleting
              >
                No, Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedbackData;
