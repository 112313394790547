import EmptySpace from "components/utility/EmptySpace";
import { Dropdown, Modal } from "flowbite-react";

import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import usePromise from "hooks/utility/usePromise";
import {
  componentMapping,
  getProperNameComponent,
} from "pages/AllCircles/GenericSessionPage/ComponentMapping";
import { lazy, Suspense, useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import ComponentDetails from "./ComponentDetails";
import { FaSort, FaPlus } from "react-icons/fa";

import Organisation from "interface/OrganisationInterface";
import { UpdateData } from "firebase/firestore";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import ComponentOrderCard from "./ComponentOrderCard";
import { setDefaultComponents } from "models/organisation";

import { timestamp } from "../../../firebase/config";
import { AiFillCloseCircle, AiFillInfoCircle } from "react-icons/ai";

import RefreshOverview from "../GrowthCirclesTypeSettings/RefreshOverview";
import { useTour } from "@reactour/tour";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import PhonePreview from "components/utility/PreviewComponents/PhonePreview";
const ComponentInstructionPanel = lazy(
  () => import("./ComponentInstructionPanel")
);
const TemplateOverview = lazy(
  () => import("../GrowthCirclesTypeSettings/TemplateOverview")
);
export default function ComponentSettings() {
  const { setIsOpen: setIsTourOpen } = useTour();
  const { selectedOrganisation, updateSelectedOrganisation } =
    useOrganisationContext();
  const { isLoading, resolve } = usePromise();
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [isSorting, setIsSorting] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [saveAs, setSaveAs] = useState<boolean>(false);

  const isSortHandler = () => {
    setIsSorting(!isSorting);
  };

  const [componentArr, setComponentArr] = useState(
    selectedOrganisation!.components
  );

  const [info, setInfo] = useState("");

  useEffect(() => {
    setComponentArr(selectedOrganisation!.components);
    // eslint-disable-next-line
  }, [selectedOrganisation]);

  const handleSubmit = () => {
    var data: UpdateData<Organisation>;

    if (saveAs) {
      data = {
        components: componentArr,
        defaultComponents: componentArr,
        savedDefaultAt: timestamp.fromDate(new Date()),
      };
    } else {
      data = { components: componentArr };
    }
    resolve(() => updateSelectedOrganisation(data))
      .then(() => {
        toast.success("settings was successfully saved");
        setIsSorting(false);
      })
      .catch((error) => toast.error(`problem saving error : ${error}`));
  };

  const handleDelete = (component: string) => {
    setComponentArr((prev) => prev.filter((c) => c !== component));
  };

  const handleInfo = (component: string) => {
    setInfo(component);
  };

  function isStringAtEnd(array: string[], targetString: string) {
    if (array.length === 0) {
      return false; // If the array is empty, the target string cannot be at the end
    }

    const lastElement = array[array.length - 1];
    return lastElement === targetString;
  }

  const testHandler = () => {
    var target = "feedback-after";
    var target2 = "feedback-org-after";
    if (
      isStringAtEnd(componentArr, target) ||
      isStringAtEnd(componentArr, target2)
    ) {
      toast.success(`Component Test Success.`);
    } else {
      toast.error(
        `${target} or ${target2} must be at the end of the page flow please change.`
      );
    }
  };

  /**
   * Pad all organisation roles with default components if they are missing.
   */
  const handleRefreshComponents = async () => {
    if (selectedOrganisation) {
      setIsRefreshing(true);
      setDefaultComponents(selectedOrganisation.id).then(() => {
        if (selectedOrganisation.defaultComponents) {
          setComponentArr(selectedOrganisation.defaultComponents);

          const data = { components: selectedOrganisation.defaultComponents };

          resolve(() => updateSelectedOrganisation(data))
            .then(() => {
              toast.success("Components was refreshed successfully.");
              setIsRefreshing(false);
              setShow(false);
            })
            .catch((error) => toast.error(`problem saving error : ${error}`));
        }
      });
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const oldIndex = componentArr.indexOf(active.id as string);
    const newIndex = componentArr.indexOf(over.id as string);

    setComponentArr(arrayMove(componentArr, oldIndex, newIndex));
  };

  return (
    <>
      <Modal size={"7xl"} show={isVisible}>
        <Modal.Body className="relative min-h-[80vh] bg-default">
          {" "}
          <div>
            <AiFillCloseCircle
              onClick={() => setIsVisible(!isVisible)}
              className="absolute top-5 right-5 cursor-pointer"
              size={30}
              style={{ color: "var(--icon-colour-0)" }}
            />
            {selectedOrganisation && (
              <Suspense fallback={"Loading..."}>
                <TemplateOverview
                  showDefault={true}
                  data={selectedOrganisation}
                />
              </Suspense>
            )}
          </div>
        </Modal.Body>
      </Modal>{" "}
      <Modal size={"7xl"} show={show}>
        <Modal.Body
          className={`${classes["container"]}  relative min-h-[80vh] bg-default`}
        >
          <div className="w-full my-8">
            {!isLoading && (
              <AiFillCloseCircle
                onClick={() => setShow(!show)}
                className="absolute top-5 right-5 cursor-pointer"
                size={30}
                style={{ color: "var(--icon-colour-0)" }}
              />
            )}
            {selectedOrganisation && (
              <RefreshOverview data={selectedOrganisation} />
            )}
            {selectedOrganisation &&
              selectedOrganisation.defaultComponents &&
              selectedOrganisation.defaultComponents?.length > 0 && (
                <div className="mb-16 text-center">
                  <button
                    className={`${classes.button}`}
                    onClick={handleRefreshComponents}
                  >
                    {isLoading ? "Please wait..." : "Confirm"}
                  </button>
                </div>
              )}
            <EmptySpace />
          </div>
        </Modal.Body>
      </Modal>
      <div className="mb-[100px]">
        <EmptySpace />
        <button
          className={`${classes["button"]}`}
          onClick={() => setIsTourOpen(true)}
        >
          Show Tutorial
        </button>
        <EmptySpace />

        <div>
          <div className="text-xl my-4 text-slate-600">
            <p className="font-semibold">GrowthCircles Session Pages</p>
          </div>

          <div className="space-y-4">
            <p className="text-sm text-gray-600">
              Pages within the GrowthCircles session are individual sections or
              elements that can be organized and saved according to your
              preferences. Please be aware that certain pages may rely on
              others, so it's essential to test their functionalities before
              finalizing your arrangements.
            </p>

            <Suspense fallback="loading...">
              <ComponentInstructionPanel
                isRefreshing={isRefreshing}
                show={show}
                setShow={setShow}
              />
            </Suspense>
          </div>
        </div>
        <EmptySpace />
        <hr />
        <EmptySpace />
        <div>
          <div
            component-settings-tour="re-order-button"
            onClick={isSortHandler}
            className={`
              cursor-pointer my-2 flex items-center gap-2 
              w-fit px-4 py-2 rounded-md
              border-2 transition-all duration-200
              ${
                isSorting
                  ? "border-[#EB8181] text-[#EB8181]"
                  : "border-gray-200 text-gray-600 hover:border-[#EB8181] hover:text-[#EB8181]"
              }
            `}
          >
            <FaSort
              size={16}
              className={`transition-colors duration-200 ${
                isSorting
                  ? "text-[#EB8181]"
                  : "text-gray-400 group-hover:text-[#EB8181]"
              }`}
            />
            <span className="font-medium">Re-Order</span>
          </div>

          {isSorting && (
            <div className="mb-4 text-sm text-gray-600 flex items-center gap-2">
              <svg
                className="w-4 h-4 text-gray-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              Grab the ⋮⋮ handle on the left of each component to drag and
              reorder. Release to drop in the new position and click save to
              apply changes.
            </div>
          )}
        </div>
        <div className="flex w-full gap-5">
          <div className="w-full max-w-3xl relative pl-8">
            {/* Main vertical line */}
            <div
              className="absolute left-[25px] top-[24px] bottom-8 w-[2px]"
              style={{ backgroundColor: "var(--icon-colour-0)", opacity: 0.3 }}
            />

            <div className="relative ">
              {" "}
              {/* Container for cards */}
              {isSorting ? (
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext
                    items={componentArr}
                    strategy={verticalListSortingStrategy}
                  >
                    {componentArr.map((component, index) => (
                      <ComponentOrderCard
                        key={component}
                        component={component}
                        index={index}
                        handleDelete={() => handleDelete(component)}
                        handleInfo={() => handleInfo(component)}
                        info={info}
                        isSorting={isSorting}
                      />
                    ))}
                  </SortableContext>
                </DndContext>
              ) : (
                componentArr.map((component, index) => (
                  <ComponentOrderCard
                    key={component}
                    component={component}
                    index={index}
                    handleDelete={() => handleDelete(component)}
                    handleInfo={() => handleInfo(component)}
                    info={info}
                    isSorting={isSorting}
                  />
                ))
              )}
            </div>
          </div>

          <div className="w-full">
            <PhonePreview bgColor="bg-default">
              <div className="flex flex-col justify-start items-center">
                <div className="mt-8 flex justify-center items-center"></div>
                {info ? (
                  <ComponentDetails info={info} />
                ) : (
                  <div className="flex flex-col items-center justify-center h-full text-gray-500">
                    <AiFillInfoCircle
                      className="cursor-pointer mb-10"
                      size={35}
                      style={{ color: "var(--icon-colour-0)" }}
                    />
                    <p className="text-center">
                      Click the <span className="text-[#EB8181]">ⓘ</span> icon
                      next to any component to preview it
                    </p>
                  </div>
                )}
              </div>
            </PhonePreview>
          </div>
        </div>
        <EmptySpace />
        <div className="relative">
          <Dropdown
            component-settings-tour="add-page=dropdown"
            label={
              <div className="flex items-center gap-2">
                <FaPlus size={16} />
                <span>Add page</span>
              </div>
            }
            className="w-full max-w-3xl border-[#EB8181] rounded-md"
            dismissOnClick={true}
            style={{
              backgroundColor: "white",
              border: "2px solid var(--icon-colour-0)",
              borderRadius: "0.5rem",
              color: "#EB8181",
            }}
          >
            <div className="max-h-[400px] overflow-y-auto p-4 ">
              {Object.keys(componentMapping)
                .filter(
                  (component) => componentMapping[component].type === "custom"
                )
                .map((component, index) => {
                  if (componentArr.includes(component)) {
                    return null;
                  }
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setComponentArr((prev) => [...prev, component]);
                      }}
                      className="relative flex items-center gap-4 p-4 my-2 bg-white rounded-lg border-2 border-gray-200 hover:border-[#EB8181] transition-colors cursor-pointer group"
                    >
                      {/* Left dot */}
                      <div className="absolute -left-3 top-1/2 transform -translate-y-1/2 w-2 h-2 rounded-full bg-[#EB8181] opacity-0 group-hover:opacity-100 transition-opacity" />

                      <div className="flex items-center gap-2">
                        <span className="bg-gray-100 w-8 h-8 rounded-full flex items-center justify-center text-gray-600">
                          {componentArr.length + 1}
                        </span>
                        <span className="font-medium text-gray-700">
                          {getProperNameComponent(component)}
                        </span>
                      </div>

                      <div className="ml-auto">
                        <FaPlus
                          className="text-gray-400 group-hover:text-[#EB8181] transition-colors"
                          size={16}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </Dropdown>
        </div>
        <EmptySpace />
        <div
          component-settings-tour="save-default-checkbox"
          style={{ color: "var(--text-colour)" }}
          className=" my-4 border p-5 border-gray-300"
        >
          <div className="cursor-pointer" onClick={() => setSaveAs(!saveAs)}>
            <input
              type="checkbox"
              checked={saveAs}
              onChange={() => setSaveAs(!saveAs)}
              className="mx-4"
              style={{
                height: "20px",
                width: "20px",
                borderRadius: "5px",
                backgroundColor: saveAs ? "var(--icon-colour-0)" : "",
              }}
            />
            Save as default component
          </div>
          {selectedOrganisation?.savedDefaultAt && (
            <p className="my-4 flex gap-2  items-center">
              The most recent default was saved on{" "}
              {selectedOrganisation.savedDefaultAt
                .toDate()
                .toLocaleString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                })}
              <AiFillInfoCircle
                onClick={() => setIsVisible(!isVisible)}
                className="cursor-pointer"
                size={30}
                style={{ color: "var(--icon-colour-0)" }}
              />
            </p>
          )}
        </div>
        {componentArr.length > 0 && (
          <div className="flex gap-4 w-1/2">
            <button
              component-settings-tour="save-pages-button"
              className={`${classes["button"]} w-[300px]`}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save"}
            </button>

            <button
              component-settings-tour="test-pages-button"
              className={`${classes["button"]} w-[300px]`}
              disabled={isLoading}
              onClick={testHandler}
            >
              {isLoading ? "Testing..." : "Test"}
            </button>
          </div>
        )}
      </div>
    </>
  );
}
