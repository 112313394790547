import { ResourceInterface } from "interface/ResourceInterface";
import ResourceCard from "./ResourceCard";

interface ResourceProps {
  resources: ResourceInterface[];
}

const ResourcesList = ({ resources }: ResourceProps) => {
  return (
    <div className="flex flex-col gap-4 max-w-4xl">
      {resources &&
        resources.map((resource, indx) => (
          <ResourceCard key={indx} resource={resource} />
        ))}
    </div>
  );
};

export default ResourcesList;
