import { ReactComponent as HappyFace } from "./happy.svg";
import { ReactComponent as SadFace } from "./sad.svg";
import { useEffect, useRef, useState } from "react";
import classes from "./SliderCustom.module.css";
import { useAuthContext } from "hooks/useAuthContext";

const SliderCustom = ({
  value = 1,
  setValue,
  withBackground = false,
  isDisabled = false,
  maxValue = 10,
  editable = false,
}) => {
  const outputRef = useRef<HTMLOutputElement>(null);
  const inputRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [initialValue, setInitialValue] = useState(1);
  const { profile } = useAuthContext();

  useEffect(() => {
    if (isDisabled === false && localStorage.getItem("FeedBackFormData")) {
      //do nothing
      if (value > maxValue) {
        setValue(1);
      }
    } else {
      setValue(1);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!profile) return;
  }, [profile]);

  const setBubble = (newVal: number, min: number, max: number) => {
    const percent = ((newVal - min) * 100) / (max - min);
    const bubblePosition = 8 - percent * 0.19;
    return `calc(${percent.toFixed(2)}% + (${bubblePosition}px))`;
  };

  const handleStart = (event) => {
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
    const clientY = event.touches ? event.touches[0].clientY : event.clientY;
    setStartPosition({ x: clientX, y: clientY });
    setInitialValue(value);
    setDragging(true);
    document.body.style.overflow = "hidden !important";
  };

  const handleMove = (event) => {
    if (!dragging || !outputRef.current || !outputRef.current.parentElement)
      return;
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
    const clientY = event.touches ? event.touches[0].clientY : event.clientY;
    const endPosition = { x: clientX, y: clientY };
    const dragValueX = endPosition.x - startPosition.x;

    const parentWidth = outputRef.current.parentElement.offsetWidth;
    const step = 1;
    const max = maxValue;
    const min = 1;

    let newVal =
      Math.round(((dragValueX / parentWidth) * (max - min)) / step) * step;
    newVal = Math.max(Math.min(initialValue + newVal, max), min);

    setValue(newVal);

    outputRef.current.style.left = setBubble(newVal, min, max);

    setStartPosition({ x: clientX, y: clientY });
  };

  const handleEnd = () => {
    setDragging(false);
    document.body.style.overflow = "auto";
  };

  const handleInputChange = (event) => {
    setValue(Number(event.target.value));
  };

  useEffect(() => {
    if (dragging && !isDisabled) {
      document.addEventListener("mousemove", handleMove);
      document.addEventListener("mouseup", handleEnd);
      document.addEventListener("mouseleave", handleEnd);
      document.addEventListener("touchmove", handleMove);
      document.addEventListener("touchend", handleEnd);
    } else {
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("mouseup", handleEnd);
      document.removeEventListener("mouseleave", handleEnd);
      document.removeEventListener("touchmove", handleMove);
      document.removeEventListener("touchend", handleEnd);
    }

    return () => {
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("mouseup", handleEnd);
      document.removeEventListener("mouseleave", handleEnd);
      document.removeEventListener("touchmove", handleMove);
      document.removeEventListener("touchend", handleEnd);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragging, profile]);

  return (
    <div
      className={`${classes["range-wrap"]} flex justify-center items-center self-center m-2`}
    >
      <SadFace
        fill={withBackground ? "#FDF5E6" : "transparent"}
        className={`absolute ${
          editable ? "-top-6" : "top-0"
        } left-0 cursor-pointer`}
        onClick={() => setValue(1)}
      />
      <input
        ref={inputRef}
        type="range"
        min={1}
        max={maxValue}
        step={1}
        value={value}
        className={`${classes.slider} shadow-md mt-8 border-2`}
        style={{
          borderColor: "var(--main-colour)",
        }}
        onChange={handleInputChange}
      />
      <output
        ref={outputRef}
        style={{
          left: setBubble(value, 1, maxValue),
          borderColor: "var(--main-colour)",
          color: "var(--main-colour)",
        }}
        className={`${classes.bubble} border-2 text-[12px] bg-default `}
        onMouseDown={handleStart}
        onTouchStart={handleStart}
      >
        {value}
      </output>
      <HappyFace
        fill={withBackground ? "#FDF5E6" : "transparent"}
        className={`absolute ${
          editable ? "-top-6" : "top-0"
        } right-0 cursor-pointer  `}
        onClick={() => setValue(maxValue)}
      />
    </div>
  );
};

export default SliderCustom;
