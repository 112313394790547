import Organisation from "interface/OrganisationInterface";
import { getProperNameComponent } from "pages/AllCircles/GenericSessionPage/ComponentMapping";
import { useState } from "react";
import ComponentDetails from "../ComponentSettings/ComponentDetails";
import PhonePreview from "components/utility/PreviewComponents/PhonePreview";
import { AiFillInfoCircle } from "react-icons/ai";

const TemplateOverview = ({
  data,
  onSelect,
  showDefault,
}: {
  data: Organisation;
  showDefault?: boolean;
  onSelect?: () => void;
}) => {
  const [info, setInfo] = useState("");

  const handleInfo = (component: string) => {
    setInfo(component);
  };

  const onSelectHandler = () => {
    if (onSelect) onSelect();
  };

  return (
    <div className="p-6">
      <div className="flex gap-8">
        {/* Left Panel - Components List */}
        <div className="w-[450px]">
          <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-200">
            <h2 className="text-lg font-medium text-gray-800 mb-4">
              Components Order
            </h2>
            <div className="space-y-3">
              {!showDefault
                ? data?.components.map((component, index) => (
                    <div
                      key={index}
                      onClick={() => handleInfo(component)}
                      className={`relative flex items-center p-4 rounded-lg border-2 transition-colors cursor-pointer
                        ${
                          component === info
                            ? "border-[#EB8181] bg-white"
                            : "border-gray-200 hover:border-[#EB8181] bg-white"
                        }`}
                    >
                      {/* Connecting elements */}
                      {index !== 0 && (
                        <div className="absolute -top-3 left-6 w-0.5 h-3 bg-[#EB8181]" />
                      )}
                      <div className="absolute -left-1 top-1/2 transform -translate-y-1/2 w-2 h-2 rounded-full bg-[#EB8181]" />

                      <div className="flex items-center gap-3 flex-1">
                        <span className="bg-gray-100 w-8 h-8 rounded-full flex items-center justify-center text-gray-600">
                          {index + 1}
                        </span>
                        <span className="font-medium text-gray-700">
                          {getProperNameComponent(component)}
                        </span>
                      </div>
                      <AiFillInfoCircle
                        className={`transition-colors ${
                          component === info
                            ? "text-[#EB8181]"
                            : "text-gray-400 hover:text-[#EB8181]"
                        }`}
                        size={22}
                      />
                    </div>
                  ))
                : data?.defaultComponents?.map((component, index) => (
                    // Same component structure for default components
                    <div
                      key={index}
                      onClick={() => handleInfo(component)}
                      className={`relative flex items-center p-4 rounded-lg border-2 transition-colors cursor-pointer
                        ${
                          component === info
                            ? "border-[#EB8181] bg-white"
                            : "border-gray-200 hover:border-[#EB8181] bg-white"
                        }`}
                    >
                      {/* Connecting elements */}
                      {index !== 0 && (
                        <div className="absolute -top-3 left-6 w-0.5 h-3 bg-[#EB8181]" />
                      )}
                      <div className="absolute -left-1 top-1/2 transform -translate-y-1/2 w-2 h-2 rounded-full bg-[#EB8181]" />

                      <div className="flex items-center gap-3 flex-1">
                        <span className="bg-gray-100 w-8 h-8 rounded-full flex items-center justify-center text-gray-600">
                          {index + 1}
                        </span>
                        <span className="font-medium text-gray-700">
                          {getProperNameComponent(component)}
                        </span>
                      </div>
                      <AiFillInfoCircle
                        className={`transition-colors ${
                          component === info
                            ? "text-[#EB8181]"
                            : "text-gray-400 hover:text-[#EB8181]"
                        }`}
                        size={22}
                      />
                    </div>
                  ))}
            </div>
          </div>
          {!showDefault && (
            <button
              onClick={onSelectHandler}
              className="w-full mt-4 px-6 py-2 bg-[#EB8181] text-white rounded-lg hover:bg-[#EB8181]/90 transition-colors"
            >
              Use Template
            </button>
          )}
        </div>

        {/* Right Panel - Preview */}
        <div className="flex-1">
          {info ? (
            <PhonePreview bgColor="bg-default">
              <ComponentDetails info={info} />
            </PhonePreview>
          ) : (
            <div className="h-full flex items-center justify-center text-gray-500 text-lg">
              Select a component to preview
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TemplateOverview;
