import { useContext, useEffect, useState } from "react";
import { REFLECT_TITLE } from "strings/SharingPanel";
import classes from "./Reflect.module.css";
import ViewReflection from "./ViewReflection";
import ViewJournalContext from "../ViewJournalContext";
import { getOrgByName } from "models/organisation";
import Organisation from "interface/OrganisationInterface";
import OrgViewReflectionQuestions from "./OrgViewReflectionQuestions";
import { Participant } from "interface/ParticipantInterface";
import { sortLocationSelectionData } from "utility/locationSelectionData";
import ReflectionViewQuestions from "./ReflectionViewQuestions";

interface ViewReflectProps {
  compulsoryReflectionQuestions: string[];
  personalReflectionQuestion: string[];
  generalReflectionQuestion: string[];
  hasLightBox: Function;
  oldPath: string;
  topic: string;
  organisation: string;
  journal: Participant;
}

const ViewReflect = ({
  compulsoryReflectionQuestions,
  personalReflectionQuestion,
  generalReflectionQuestion,
  oldPath,
  topic,
  organisation,
  journal,
}: ViewReflectProps) => {
  const [org, setOrg] = useState<Organisation>();

  useEffect(() => {
    const _orgConvert = sortLocationSelectionData.includes(organisation)
      ? "Growthbeans"
      : organisation;

    if (!org) getOrgByName(_orgConvert).then((organ) => setOrg(organ));

    //console.log(personalReflectionQuestion);
    // console.log(generalReflectionQuestion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    personalReflectionQuestion,
    generalReflectionQuestion,
    compulsoryReflectionQuestions,
  ]);
  const { userJournal } = useContext(ViewJournalContext);

  return (
    <div className={classes["reflect-container"]}>
      <strong className={classes["title"]}>{REFLECT_TITLE}</strong>
      {organisation !== "NTU Career & Attachment Office" &&
        personalReflectionQuestion &&
        generalReflectionQuestion && (
          <ReflectionViewQuestions
            path={oldPath}
            topic={topic}
            compulsoryReflectionQuestion={compulsoryReflectionQuestions}
            personalReflectionQuestion={personalReflectionQuestion}
            generalReflectionQuestion={generalReflectionQuestion}
            organisation={org}
          />
        )}

      {organisation === "NTU Career & Attachment Office" && (
        <OrgViewReflectionQuestions
          organisation={org}
          isCustom={false}
          path={oldPath}
          topic={topic}
          compulsoryReflectionQuestions={compulsoryReflectionQuestions}
          personalReflectionQuestion={personalReflectionQuestion}
          generalReflectionQuestion={generalReflectionQuestion}
        />
      )}

      {(userJournal.reflection || userJournal.notes) && <ViewReflection />}
    </div>
  );
};

export default ViewReflect;
