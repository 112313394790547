import { ChangeEventHandler, FC } from "react";
import classes from "./InputField.module.css";

interface PreValueProps {
  label: string;
  value: string;
}

export interface Option {
  value: string;
  label: string;
}

export const questionTypes: Option[] = [
  { value: "text", label: "Short Text" },
  { value: "textArea", label: "Long Text" },
  { value: "smileyRating", label: "Smiley Rating" },
  { value: "smileyRatingWithMax", label: "Custom Smiley Rating" },
  { value: "slider", label: "Slider" },
  { value: "sliderWithMax", label: "Custom Slider" },
  { value: "badgeRating", label: "Badge Rating" },
  { value: "multipleChoice", label: "Multiple Choice" },
  { value: "yesOrNo", label: "Yes/No" },
  { value: "SpecialThanks", label: "Special Thanks" },
  { value: "imageUpload", label: "Image Upload Question" },
];

interface Props {
  id?: string;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  defaultValue?: string | number | readonly string[];
  value?: string | number | readonly string[];
  options?: (string | Option)[];
  containerClassName?: string;
  inputClassName?: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  preValues?: PreValueProps;
}

/**
 * Select field with list of options.
 *
 * @param id Identifier for the select field.
 * @param onChange Change handler whenever an option is selected.
 * @param defaultValue Default value selected.
 * @param value Current value selected.
 * @param options List of option values.
 * @param containerClassName ClassName for select field.
 * @param inputClassName ClassName for option field.
 * @returns SelectField component.
 */
const SelectField: FC<Props> = ({
  id,
  onChange,
  defaultValue,
  value,
  options,
  containerClassName,
  inputClassName,
  required,
  placeholder,
}) => {
  return (
    <div
      className={
        containerClassName
          ? containerClassName
          : `${classes["input-placeholder"]}`
      }
    >
      <select
        id={id}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        className={inputClassName ? inputClassName : classes.input}
        required={required}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options?.map((option, index) => {
          if (typeof option === "string") {
            return (
              <option key={index} value={option}>
                {option}
              </option>
            );
          }
          return (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default SelectField;
