import { useState } from "react";
import { FaCloudUploadAlt, FaTrash } from "react-icons/fa";
import { projectStorage } from "../../firebase/config";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import { Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { AiFillCloseCircle } from "react-icons/ai";
import ImageCarousel from "components/utility/PreviewComponents/ImageCarousel";

const ALLOWED_FILE_TYPES = {
  "image/jpeg": true,
  "image/png": true,
  "image/gif": true,
};

interface Props {
  label: string;
  isDisabled?: boolean;
  value?: string[];
  onChange: (images: string[]) => void;
  maxImages?: number;
  setHasModal?: (b: boolean) => any;
  instanceId: string;
}

const ImageUploadQuestion = ({
  label,
  isDisabled = false,
  value = [],
  onChange,
  maxImages = 5,
  setHasModal,
  instanceId,
}: Props) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [imageToDelete, setImageToDelete] = useState<{
    url: string;
    index: number;
  } | null>(null);
  const [showLightbox, setShowLightbox] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const validateFile = (file: File) => {
    const fileType = file.type;
    if (!Object.keys(ALLOWED_FILE_TYPES).includes(fileType)) {
      return {
        valid: false,
        error: `File type "${file.name}" is not supported. Only JPG, PNG, and GIF files are allowed.`,
      };
    }
    return { valid: true };
  };

  const uploadToFirebase = async (file: File): Promise<string> => {
    const validation = validateFile(file);
    if (!validation.valid) {
      toast.error(`${validation.error}`);
      throw new Error(validation.error);
    }

    const fileId = uuidv4();
    const fileExtension = file.name.split(".").pop();
    const path = `feedback-images/${instanceId}/${fileId}.${fileExtension}`;

    try {
      const storageRef = projectStorage.ref(path);
      await storageRef.put(file);
      const url = await storageRef.getDownloadURL();
      return url;
    } catch (error) {
      console.error("Upload error:", error);
      throw error;
    }
  };

  const handleFiles = async (files: File[]) => {
    if (isDisabled || isUploading) return;

    const remainingSlots = maxImages - value.length;
    const filesToProcess = files.slice(0, remainingSlots);

    if (filesToProcess.length === 0) return;

    setIsUploading(true);
    try {
      const uploadPromises = filesToProcess.map(uploadToFirebase);
      const urls = await Promise.all(uploadPromises);
      onChange([...value, ...urls]);
      toast.success("Images uploaded successfully");
    } catch (error) {
      toast.error("Failed to upload images");
      console.error(error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);

    if (isDisabled || value.length >= maxImages) return;

    const files = Array.from(e.dataTransfer.files).filter((file) =>
      file.type.startsWith("image/")
    );

    handleFiles(files);
  };

  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isDisabled || !e.target.files || value.length >= maxImages) return;

    const files = Array.from(e.target.files).filter((file) =>
      file.type.startsWith("image/")
    );

    handleFiles(files);
  };

  const handleDeleteClick = (url: string, index: number) => {
    setImageToDelete({ url, index });
    if (setHasModal) {
      setHasModal(true);
    }
  };

  const confirmDelete = async () => {
    if (!imageToDelete) return;

    try {
      const imageRef = projectStorage.refFromURL(imageToDelete.url);
      await imageRef.delete();

      const newImages = value.filter((_, i) => i !== imageToDelete.index);

      if (currentImageIndex >= newImages.length) {
        setCurrentImageIndex(Math.max(0, newImages.length - 1));
      }

      onChange(newImages);
      toast.success("Image removed successfully");
    } catch (error) {
      toast.error("Failed to remove image");
      console.error(error);
    } finally {
      setImageToDelete(null);
      if (setHasModal) {
        setHasModal(false);
      }
    }
  };

  return (
    <>
      <div className="w-full space-y-4">
        <label className="block text-gray-900 font-medium mb-1">{label}</label>

        <div
          className={`relative border-2 border-dashed rounded-lg p-8 text-center transition-colors
            ${
              isDragging
                ? "border-[#EB8181] bg-red-50/30"
                : "border-gray-300 hover:border-[#EB8181]"
            }
            ${
              isDisabled || isUploading
                ? "opacity-50 cursor-not-allowed"
                : "cursor-pointer"
            }`}
          onDragOver={(e) => {
            e.preventDefault();
            setIsDragging(true);
          }}
          onDragLeave={() => setIsDragging(false)}
          onDrop={handleDrop}
        >
          <input
            type="file"
            multiple
            accept="image/*;capture=camera"
            className="hidden"
            onChange={handleFileInput}
            disabled={isDisabled || isUploading || value.length >= maxImages}
            id={`image-upload-${instanceId}`}
          />
          <label
            htmlFor={`image-upload-${instanceId}`}
            className={`flex flex-col items-center ${
              isDisabled || isUploading
                ? "cursor-not-allowed"
                : "cursor-pointer"
            }`}
          >
            <FaCloudUploadAlt size={40} className="text-gray-400 mb-2" />
            <p className="text-gray-600">
              {isUploading
                ? "Uploading images..."
                : "Drag & drop images here or click to browse"}
            </p>
            <p className="text-sm text-gray-500 mt-1">
              {value.length}/{maxImages} images uploaded
            </p>
          </label>
        </div>

        {value.length > 0 && (
          <div className="mt-4">
            <div className="relative">
              <ImageCarousel
                images={value}
                hasLightBox={showLightbox}
                setLightBoxHandler={() => {
                  setShowLightbox(!showLightbox);
                  if (setHasModal) setHasModal(!showLightbox);
                }}
                className="max-w-2xl mx-auto"
                currentIndex={currentImageIndex}
                setCurrentIndex={setCurrentImageIndex}
              />
              {!isDisabled && !isUploading && (
                <button
                  onClick={() =>
                    handleDeleteClick(
                      value[currentImageIndex],
                      currentImageIndex
                    )
                  }
                  className="absolute top-2 right-2 z-10 p-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors"
                >
                  <FaTrash size={14} />
                </button>
              )}
            </div>
          </div>
        )}
      </div>

      <Modal show={!!imageToDelete} size="md">
        <Modal.Body className="relative">
          <AiFillCloseCircle
            onClick={() => {
              setImageToDelete(null);
              if (setHasModal) setHasModal(false);
            }}
            size={25}
            className="cursor-pointer absolute top-2 right-2"
            color="var(--icon-colour-0)"
          />
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400" />
            <h3 className="mb-5 text-lg font-normal text-gray-500">
              Are you sure you want to delete this image?
            </h3>
          </div>
        </Modal.Body>
        <Modal.Footer className={`${classes.container} flex justify-center`}>
          <div className="flex justify-center gap-4">
            <button className={`${classes.button}`} onClick={confirmDelete}>
              Yes, delete
            </button>
            <button
              className={`${classes.button}`}
              onClick={() => {
                setImageToDelete(null);
                if (setHasModal) setHasModal(false);
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImageUploadQuestion;
