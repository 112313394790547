/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment, Suspense, lazy, useEffect, useState } from "react";
import SidebarAccount from "./SidebarAccount";
import { ReactComponent as Xbutton } from "../../assets/Xbutton.svg";
import classes from "./SidebarModal.module.css";

//import hook and context
import { useLogout } from "../../hooks/useLogout";
import { useAuthContext } from "../../hooks/useAuthContext";

//import for navigation
import { Link } from "react-router-dom";
import { Modal } from "flowbite-react";

import {
  P_CAN_ACCESS_SELFAWARENESS,
  P_CAN_OBSERVE,
  P_CAN_VIEW_DASHBOARD,
  P_GENERATE_QR_CODE,
} from "models/permission";

import { useVideo } from "hooks/useVideos";
import { AiFillCloseCircle } from "react-icons/ai";

import useParticipants from "hooks/useParticipants";
import {
  getGCSessionByHost,
  prepareGrowthCircleSession,
} from "models/growthCircleSession";
import { Profile } from "interface/ProfileInterface";
import { GrowthCircleSession } from "interface/GrowthCircleSessionInterface";
import { useFirestore } from "hooks/useFirestore";
import usePromise from "hooks/utility/usePromise";
import SimpleLoader from "components/Loaders/SimpleLoader";
import ReadTermsModal from "components/Login/ReadTermsModal";
import usePermissionContext from "hooks/permissions/usePermissionContext";
import QrCodeDisplay from "components/QRcode/QrCodeDisplay";
import toast from "react-hot-toast";
import VersionInfo from "components/VersionInfo/VersionInfo";
import ReadPrivacyModal from "components/Login/ReadPrivacyModal";

import useGetParticipants from "hooks/useGetParticipants";
import ParticipantListView from "components/ParticipantListView/ParticipantListView";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";

const VideoListsPage = lazy(() => import("components/Video/VideoListsPage"));

interface BackdropProps {
  sidebar?: boolean;
  backdropClick: () => void;
  show?: boolean;
  isVisible?: boolean;
  showParticipants?: boolean;
  callBack?: () => void;
}

const SidebarOverlay: React.FC<BackdropProps> = ({
  sidebar,
  backdropClick,
  callBack,
}) => {
  const { logout, isPending } = useLogout();
  const { user, profile } = useAuthContext();
  const { getOrgsWithPermission } = usePermissionContext();
  const [show, setShow] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const canQR = getOrgsWithPermission(profile, P_GENERATE_QR_CODE);
  const organisations = getOrgsWithPermission(profile, P_CAN_OBSERVE);
  const administrator = getOrgsWithPermission(profile, P_CAN_VIEW_DASHBOARD);
  const selfAwareness = getOrgsWithPermission(
    profile,
    P_CAN_ACCESS_SELFAWARENESS
  );
  const [ownedGCSession, setOwnedGCSession] = useState<GrowthCircleSession>();
  const { participants } = useGetParticipants();
  const { sessionRole } = useParticipants();
  const { updateDocument } = useFirestore("users");
  const { getOrganisationVideos, videos } = useVideo();
  const { isLoading, resolve } = usePromise();
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [selfCollapse, setSelfCollapse] = useState(false);
  const [showParticipants, setShowParticipants] = useState(false);
  const { growthCircleSession } = useGrowthCircleContext();

  const showTermsHandler = () => {
    backdropClick();
    setShowTerms(true);
    if (callBack) callBack();
  };

  const showPrivacyHandler = () => {
    backdropClick();
    setShowPrivacy(true);
    if (callBack) callBack();
  };

  const hideTermsHandler = () => {
    setShowTerms(false);
    if (callBack) callBack();
  };

  const hidePrivacyHandler = () => {
    setShowPrivacy(false);
    if (callBack) callBack();
  };

  const qrModalHandler = () => {
    backdropClick();
    setShow(!show);
    if (callBack) callBack();
  };

  const participantHandler = () => {
    backdropClick();
    setShowParticipants(!showParticipants);
    if (callBack) callBack();
  };

  const setAboutModal = () => {
    setVisible(!isVisible);
    if (callBack) callBack();
  };

  const setShowHandler = () => {
    setShow(!show);
    if (callBack) callBack();
  };

  const aboutModalHandler = () => {
    backdropClick();
    //TODO: this should be in firebase
    setVisible(!isVisible);
    if (callBack) callBack();
  };

  //TODO : convert to global function same on MyGCSession
  const QRgenerate = async (profile: Profile) => {
    try {
      //Check if user can create QR code and profile facilGC is not set or not equal to session ID
      if (
        (canQR.length !== 0 && profile.facilGC === "") ||
        (canQR.length !== 0 && profile.facilGC === undefined)
      ) {
        const session = await prepareGrowthCircleSession(profile, canQR);
        setOwnedGCSession(session);
        updateDocument(profile.uid, { facilGC: session?.id });
      } else {
        // if user already have a GrowthCircle Session no need to check again just set the existing session
        const growthCircleSession = await getGCSessionByHost(profile.id);
        // if (ownedGCSession) {
        //   checkExpireGC(ownedGCSession);
        // }

        setOwnedGCSession(growthCircleSession);
      }
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };

  useEffect(() => {
    if (!profile) {
      return;
    }

    resolve(() => QRgenerate(profile));
    getOrganisationVideos();

    // eslint-disable-next-line
  }, [profile, sessionRole]);

  return (
    <>
      <>
        <Modal
          show={isVisible}
          size={"7xl"}
          className="z-[7000] mt-[19%] md:mt-0"
        >
          <Modal.Body className="relative min-h-[80vh]">
            <div className="absolute right-0 top-0 p-2">
              <AiFillCloseCircle
                className="cursor-pointer"
                onClick={setAboutModal}
                style={{ color: "var(--main-colour)" }}
                size={30}
              />
            </div>
            <div className="text-center flex flex-col justify-center items-center">
              {videos && videos.length > 0 && (
                <Suspense fallback={<div>Loading...</div>}>
                  <VideoListsPage videos={videos} />
                </Suspense>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </>
      {profile && ownedGCSession && canQR.length > 0 && (
        <>
          <Modal
            show={show}
            size="2xl"
            popup={true}
            className="z-[7000] mt-[19%] md:mt-0"
          >
            <Modal.Body className="relative py-8">
              <AiFillCloseCircle
                style={{ color: "var(--main-colour)" }}
                onClick={setShowHandler}
                className="absolute top-2 right-2 cursor-pointer"
                size={30}
              />
              {!isLoading ? (
                <div className="text-center flex flex-col justify-center items-center">
                  <h3 className="mb-5 text-2xl  text-start font-semibold text-standard">
                    My QR Code
                  </h3>
                  <QrCodeDisplay
                    size={200}
                    value={`${window.location.origin}/all-circles/welcome/${ownedGCSession.id}`}
                  />
                  <p className="py-8 flex flex-col">
                    Scan QR or click/tap to copy
                    <span
                      className="block py-4"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${window.location.origin}/all-circles/welcome/${ownedGCSession.id}`
                        );
                        toast.success("successfully copied to clipboard");
                      }}
                    >
                      {`${window.location.origin}/all-circles/welcome/${ownedGCSession.id}`}
                    </span>
                    <span>Invite code :</span>
                    <span className="py-2 text-5xl font-semibold">
                      {ownedGCSession.invite_code}
                    </span>
                  </p>
                </div>
              ) : (
                <div className="text-center flex flex-col justify-center items-center h-96">
                  <SimpleLoader />
                </div>
              )}
            </Modal.Body>
          </Modal>

          {/* participant modal */}
          {participants.length > 0 && (
            <Modal
              show={showParticipants}
              size="3xl"
              popup={true}
              className="z-[7000] mt-[19%] md:mt-0"
            >
              <Modal.Body className="relative py-8 min-h-[30vh]">
                <AiFillCloseCircle
                  style={{ color: "var(--main-colour)" }}
                  onClick={() => {
                    setShowParticipants(!showParticipants);
                    if (callBack) callBack();
                  }}
                  className="absolute top-2 right-2 cursor-pointer"
                  size={30}
                />
                {!isLoading && growthCircleSession ? (
                  <div className="text-center flex flex-col justify-center items-center">
                    <h3 className="mb-5 text-2xl  text-start font-semibold text-standard">
                      My Participants
                    </h3>
                    <p className="text-standard">
                      Click on a participant below to assign them as a co-host
                      or remove them from the current session.
                    </p>

                    <ParticipantListView
                      growthCircleSession={growthCircleSession}
                      participants={participants}
                    />
                  </div>
                ) : (
                  <div className="text-center flex flex-col justify-center items-center h-96">
                    <SimpleLoader />
                  </div>
                )}
              </Modal.Body>
            </Modal>
          )}
        </>
      )}

      <ReadTermsModal
        canClose={true}
        show={showTerms}
        onClick={hideTermsHandler}
        section="Terms"
      />

      <ReadPrivacyModal
        canClose={true}
        show={showPrivacy}
        onClick={hidePrivacyHandler}
        section={"Privacy"}
      />

      <div className={` ${classes.sidebar} ${sidebar ? classes.active : ""}`}>
        <div className={`${classes.border} relative`}>
          <Xbutton
            className={`${classes.close} shrink-0`}
            onClick={backdropClick}
          />
          {user && (
            <>
              <ul>
                <SidebarAccount name="Your Account" />
              </ul>
              <ul>
                {(administrator.length > 0 || profile?.access === "admin") && (
                  <a href="/all-circles/admin/dashboard">
                    <li>Dashboard</li>
                  </a>
                )}
                {profile && selfAwareness.length > 0 && (
                  <button
                    className="text-left w-full"
                    onClick={() => setSelfCollapse(!selfCollapse)}
                  >
                    <li>Self Discovery</li>
                  </button>
                )}
                <ul className={`${selfCollapse ? "" : "hidden"}`}>
                  <Link to={`/journey-with-sage`}>
                    <li className="text-left w-full ml-2">Journey with Sage</li>
                  </Link>
                  <Link to={`/self-awareness`}>
                    <li className="ml-2">Self Awareness</li>
                  </Link>
                </ul>
                <Link to="/my-circles">
                  <li>My Circles</li>
                </Link>{" "}
                {profile &&
                  canQR.length > 0 &&
                  sessionRole?.name !== "trainee" && (
                    <>
                      <button
                        className="text-left w-full ml-2"
                        onClick={qrModalHandler}
                      >
                        <li>QR Code</li>
                      </button>
                      {participants.length > 0 &&
                        growthCircleSession &&
                        growthCircleSession?.facilOwner === profile.uid && (
                          <button
                            className="text-left w-full ml-2"
                            onClick={participantHandler}
                          >
                            <li>My Participants</li>
                          </button>
                        )}
                    </>
                  )}
                {organisations.length > 0 && (
                  <li>
                    <Link to="/all-circles/join-session">View Sessions</Link>
                  </li>
                )}
                <Link to="/my-growth">
                  <li>My Growth </li>
                </Link>
                <Link to="/journal">
                  <li>My Journal</li>
                </Link>
                <Link to="/my-roles">
                  <li>Growth Circle Roles</li>
                </Link>
              </ul>
              <div className={classes.divider} />
            </>
          )}
          <ul>
            <button className="text-left w-full" onClick={aboutModalHandler}>
              <li>About Growth Circles</li>
            </button>
            {user && (
              <Link to={`/resources`}>
                <li>Resources</li>
              </Link>
            )}
            <a
              href="https://growthbeans.typeform.com/to/nnqZ06xg"
              target="_blank"
            >
              <li>Signpost a friend</li>
            </a>
          </ul>
          <div className={classes.divider} />
          <ul>
            <a href="https://www.growthbeans.com/faq.html" target="_blank">
              <li>FAQ</li>
            </a>
            <Link to="/app-feedback">
              <li>Submit an App Feedback</li>
            </Link>
            <a
              href="https://www.growthbeans.com/contact-us.html"
              target="_blank"
            >
              <li>Contact Us</li>
            </a>
          </ul>
          <div className={classes.divider} />
          {user && (
            <ul>
              <li className={classes.logout}>
                <button onClick={logout}>
                  {isPending ? "Logging out.." : "Log out"}
                </button>
              </li>
            </ul>
          )}
          <div className="mx-10 xl:mt-[25vh]  pb-20 ">
            <p
              className="cursor-pointer"
              style={{ color: "var(--main-colour)" }}
              onClick={showTermsHandler}
            >
              Terms & Conditions
            </p>
            <p
              className="py-4 cursor-pointer"
              style={{ color: "var(--main-colour)" }}
              onClick={showPrivacyHandler}
            >
              Privacy Policy
            </p>
            <p style={{ color: "var(--main-colour)" }} className={`text-sm`}>
              Powered by GrowthBeans
            </p>
            <VersionInfo />
          </div>
        </div>
      </div>
    </>
  );
};

const Backdrop: React.FC<BackdropProps> = ({
  sidebar,
  backdropClick,
  show,
  isVisible,
  showParticipants,
}) => {
  return (
    <div
      className={`${classes.backdrop} ${
        sidebar || show || isVisible || showParticipants ? classes.active : ""
      }`}
      onClick={backdropClick}
    />
  );
};

const SidebarModal: React.FC<BackdropProps> = ({
  backdropClick,
  sidebar,
  show,
  isVisible,
  showParticipants,
  callBack,
}) => {
  return (
    <Fragment>
      <SidebarOverlay
        callBack={callBack}
        backdropClick={backdropClick}
        sidebar={sidebar || show || isVisible || showParticipants}
      />
      {sidebar && <Backdrop backdropClick={backdropClick} />}
      {show && <Backdrop backdropClick={backdropClick} />}
      {isVisible && <Backdrop backdropClick={backdropClick} />}
    </Fragment>
  );
};
export default SidebarModal;
