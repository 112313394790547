import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { getProperNameComponent } from "pages/AllCircles/GenericSessionPage/ComponentMapping";
import { AiFillDelete, AiFillInfoCircle } from "react-icons/ai";

interface ComponentOrderCardProps {
  component: string;
  index: number;
  handleDelete: () => void;
  handleInfo: () => void;
  info: string;
  isSorting: boolean;
}

const ComponentOrderCard = ({
  component,
  index,
  handleDelete,
  handleInfo,
  info,
  isSorting,
}: ComponentOrderCardProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: component,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : 0,
    position: isDragging ? ("relative" as const) : undefined,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div className="relative">
      {/* Connecting line */}
      {index !== 0 && (
        <div className="absolute -top-2 left-[2.25rem] w-0.5 h-4 bg-[#EB8181]" />
      )}

      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        className={`relative flex items-center justify-between p-4 my-2 bg-white rounded-lg border-2 hover:border-[#EB8181] transition-colors select-none ${
          info === component ? "border-[#EB8181]" : "border-gray-200"
        } ${isDragging ? "shadow-2xl" : "shadow-sm"}`}
      >
        {/* Left connecting dot */}
        <div className="absolute -left-3 top-1/2 transform -translate-y-1/2 w-2 h-2 rounded-full bg-[#EB8181]" />

        <div className="flex items-center gap-4">
          {isSorting && (
            <div
              {...listeners}
              className="cursor-grab active:cursor-grabbing text-gray-400"
            >
              ⋮⋮
            </div>
          )}
          <div className="flex items-center gap-2">
            <span className="bg-gray-100 w-8 h-8 rounded-full flex items-center justify-center text-gray-600">
              {index + 1}
            </span>
            <span className="font-medium text-gray-700">
              {getProperNameComponent(component)}
            </span>
          </div>
        </div>
        <div className="flex gap-3">
          <AiFillInfoCircle
            onClick={handleInfo}
            className="cursor-pointer hover:text-[#EB8181] transition-colors"
            size={22}
            style={{
              color: info === component ? "#EB8181" : "var(--icon-colour-0)",
            }}
          />
          <AiFillDelete
            onClick={handleDelete}
            className="cursor-pointer hover:text-red-500 transition-colors"
            size={22}
            style={{ color: "var(--icon-colour-0)" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ComponentOrderCard;
