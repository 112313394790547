import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";
import { GrowthCircleContextProvider } from "./context/GrowthCircleContext";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { ThemeContextProvider } from "context/ThemeContext";
// import { scan } from "react-scan";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container as HTMLElement);

// if (typeof window !== "undefined") {
//   scan({
//     enabled: true,
//     log: true, // logs render info to console (default: false)
//   });
// }

root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <GrowthCircleContextProvider>
        <ThemeContextProvider>
          <App />
        </ThemeContextProvider>
      </GrowthCircleContextProvider>
    </AuthContextProvider>
  </BrowserRouter>
);
