import OrganisationTopics from "interface/OrganisationTopicsInterface";
import { useState } from "react";

interface TopicSortCardProps {
  topic: OrganisationTopics;
  isSortable: boolean;
  order: String[];
  setOrder: Function;
}

const TopicSortCards = ({
  isSortable,
  topic,
  setOrder,
  order,
}: TopicSortCardProps) => {
  const [selected, setIsSelected] = useState(isSortable ? true : false);

  const selectedHandler = () => {
    setIsSelected(!selected);
    setOrder((prevSelectedIds) => {
      if (!selected) {
        return [...prevSelectedIds, topic.id];
      } else {
        return prevSelectedIds.filter((id) => id !== topic.id);
      }
    });
  };

  return (
    <div
      onClick={isSortable ? selectedHandler : () => {}}
      className={`relative bg-white  min-h-[150px] shadow-md text-slate-700 ${
        isSortable ? "cursor-pointer" : ""
      } ${isSortable && selected ? "border-4 " : "border border-slate-400/70"}`}
      style={{
        borderColor: isSortable && selected ? "var(--icon-colour-0)" : "",
      }}
    >
      <>
        {" "}
        <div className="border border-b-slate-400/70 p-2 flex justify-between">
          <p>{topic.category}</p>
        </div>
        {topic.id && selected && isSortable && (
          <p
            style={{ backgroundColor: "var(--icon-colour-0)" }}
            className="absolute bottom-2 right-2 text-md font-semibold rounded-full text-default w-10 h-10 flex items-center justify-center text-center"
          >
            {selected ? order.indexOf(topic.id) + 1 : ""}
          </p>
        )}
      </>
    </div>
  );
};

export default TopicSortCards;
