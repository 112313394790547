import { useCallback, useEffect, useMemo, useState } from "react";
import useOrganisationContext from "./organisation/useOrganisationContext";

import { projectFirestore, timestamp } from "../firebase/config";
import { Activity } from "interface/ActivityInterface";
import { DocumentData } from "firebase/firestore";

export const useOrganisationActivityPage = () => {
  const { selectedOrganisation } = useOrganisationContext();

  const activitiesInitialState: Activity[] = [];
  const [activities, setActivities] = useState(activitiesInitialState);
  const [isLoading, setIsLoading] = useState(true); // Initialize as true

  const AddActivity = useCallback(
    async (
      activityName: string,
      description: string,
      order: number,
      activityType: string,
      url: string
    ) => {
      try {
        const data = {
          createdAt: timestamp.fromDate(new Date()),
          title: activityName,
          organisationId: selectedOrganisation?.id,
          description,
          hasImage: false,
          order: order + 1,
          activityType,
          url,
          showContent: false,
        };
        const ref = await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("activities")
          .add(data);
        await ref.update({ id: ref.id });
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const saveActivityGeneralTitle = async (activityTitle: string) => {
    try {
      const data = { activityTitle };
      await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)

        .update(data);
    } catch (error) {
      console.error(error);
    }
  };

  const updateActivityStatus = async (
    activityId: string,
    isEnabled: boolean
  ) => {
    try {
      const data = { isEnabled };
      await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("activities")
        .doc(activityId)
        .update(data);
    } catch (error) {
      console.error(error);
    }
  };

  const saveActivityGeneralInstruction = async (
    activityInstruction: string
  ) => {
    try {
      const data = { activityInstruction };
      await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)

        .update(data);
    } catch (error) {
      console.error(error);
    }
  };

  const saveOrder = useCallback(
    async (selectedIds: string[]) => {
      try {
        const batch = projectFirestore.batch();

        selectedIds.forEach((activityId, index) => {
          const activityRef = projectFirestore
            .collection("organisations")
            .doc(selectedOrganisation?.id)
            .collection("activities")
            .doc(activityId);

          batch.update(activityRef, { order: index + 1 });
        });

        await batch.commit();
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const saveActivitySettings = useCallback(
    async (
      checkedTopics: string[],
      hasImage: boolean,
      selectedOption: number | string,
      activityId: string,
      selectedImgUrl: string
    ) => {
      try {
        await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("activities")
          .doc(activityId)
          .update({
            assignedTopics: checkedTopics,
            hasImage,
            selectedImageIndex: selectedOption,
            selectedImageURL: selectedImgUrl,
          });
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const saveEditableContent = useCallback(
    async (title: string, description: string, activityId: string) => {
      try {
        const data = {
          title,
          description,
        };
        await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("activities")
          .doc(activityId)
          .update(data);
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const saveEditableContentTitle = useCallback(
    async (title: string, activityId: string) => {
      try {
        const data = {
          title,
        };
        await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("activities")
          .doc(activityId)
          .update(data);
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const saveEditableContentDesc = useCallback(
    async (description: string, activityId: string) => {
      try {
        const data = {
          description,
        };
        await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("activities")
          .doc(activityId)
          .update(data);
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const DeleteActivity = useCallback(
    async (activityId: string) => {
      try {
        await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("activities")
          .doc(activityId)
          .delete();
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const DeleteActivityImage = useCallback(
    async (activityId: string) => {
      try {
        await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("activities")
          .doc(activityId)
          .update({
            selectedImageIndex: -1,
            selectedImageURL: "",
          });
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const updateActivitiesWithImageText = useCallback(
    async (imageText: string) => {
      try {
        const activitiesRef = projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("activities");

        const activitiesSnapshot = await activitiesRef
          .where("selectedImageURL", "==", imageText)
          .get();

        const updatePromises = activitiesSnapshot.docs.map((doc) => {
          const activityId = doc.id;
          return activitiesRef.doc(activityId).update({
            selectedImageIndex: -1,
            selectedImageURL: "",
            hasImage: false,
          });
        });

        await Promise.all(updatePromises);
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const activitiesSnapshotCallback = useMemo(() => {
    return (snapshot: DocumentData) => {
      const updatedActivities: Activity[] = [];
      snapshot.forEach((doc) => {
        updatedActivities.push({
          id: doc.id,
          ...doc.data(),
        } as Activity);
      });
      setActivities(updatedActivities);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      // Array population completed
    };
    // eslint-disable-next-line
  }, []);

  const saveImageSetState = async (
    activityId: string,
    selectedImageURL: string
  ) => {
    try {
      const hasImage: boolean = selectedImageURL === "" ? false : true;
      const selectedImageIndex: number = selectedImageURL !== "" ? -1 : -1;
      const data = {
        selectedImageURL,
        hasImage,
        selectedImageIndex,
      };
      await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("activities")
        .doc(activityId)
        .update(data);
    } catch (error) {
      console.error(error);
    }
  };

  const savePDFurl = async (activityId: string, pdfUrl: string) => {
    try {
      const data = {
        pdfUrl,
      };
      await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("activities")
        .doc(activityId)
        .update(data);
    } catch (error) {
      console.error(error);
    }
  };

  const saveEditableContentInstruction = async (
    customInstruction: string,
    activityId: string
  ) => {
    try {
      const data = { customInstruction };
      await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("activities")
        .doc(activityId)
        .update(data);
    } catch (error) {
      console.error(error);
    }
  };

  const saveEditableReflectionPlaceholder = async (
    customReflectionPlaceholder: string,
    activityId: string
  ) => {
    try {
      const data = { customReflectionPlaceholder };
      await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("activities")
        .doc(activityId)
        .update(data);
    } catch (error) {
      console.error(error);
    }
  };

  const updateActivityType = async (
    activityId: string,
    activityType: string,
    url: string,
    assignedTopics: string[],
    customInstructionModal: string,
    imageRequiredIs: number,
    hasInfoModal: boolean,
    showContent: boolean,
    selectedFeedback: { [key: string]: boolean }
  ) => {
    try {
      const data = {
        activityType,
        url,
        assignedTopics,
        customInstructionModal,
        imageRequiredIs,
        hasInfoModal,
        showContent,
        selectedFeedback,
      };
      await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("activities")
        .doc(activityId)
        .update(data);
    } catch (error) {
      console.error(error);
    }
  };

  const updatePDFpageNumber = async (
    activityId: string,
    numberOfPage: number
  ) => {
    try {
      const data = { numberOfPage };
      await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("activities")
        .doc(activityId)
        .update(data);
    } catch (error) {
      console.error(error);
    }
  };

  const updateFocusPageNumber = async (
    activityId: string,
    focusPage: string[]
  ) => {
    try {
      const data = { focusPage };
      await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("activities")
        .doc(activityId)
        .update(data);
    } catch (error) {
      console.error(error);
    }
  };

  const updateActivityModalInfo = async (
    activityId: string,
    hasInfoModal: boolean
  ) => {
    try {
      const data = { hasInfoModal };
      await projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("activities")
        .doc(activityId)
        .update(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!selectedOrganisation) return; // No need to subscribe without selectedOrganisation

    const unsubscribe = projectFirestore
      .collection("organisations")
      .doc(selectedOrganisation.id)
      .collection("activities")
      .orderBy("order", "asc")
      .onSnapshot(activitiesSnapshotCallback);

    return () => {
      unsubscribe();
    };
  }, [selectedOrganisation, activitiesSnapshotCallback]);

  return {
    activities,
    isLoading,
    AddActivity,
    DeleteActivity,
    saveOrder,
    saveEditableContent,
    saveActivitySettings,
    DeleteActivityImage,
    updateActivitiesWithImageText,
    saveEditableContentTitle,
    saveEditableContentDesc,
    saveImageSetState,
    saveActivityGeneralTitle,
    saveActivityGeneralInstruction,
    updateActivityType,
    saveEditableContentInstruction,
    saveEditableReflectionPlaceholder,
    savePDFurl,
    updatePDFpageNumber,
    updateFocusPageNumber,
    updateActivityModalInfo,
    updateActivityStatus,
  };
};
