import classes from "./SettingsIntentions.module.css";
import { useAuthContext } from "hooks/useAuthContext";
import EmptySpace from "components/utility/EmptySpace";
import SubmitIntention from "components/SettingIntentions/SubmitIntention";
import Instruction from "components/utility/Instruction";
import React, { useEffect, useState, useRef } from "react";
import BackAndNext from "components/utility/BackAndNext";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useOrganisationIntentions } from "hooks/useOrganisationIntentions";
import { Intention } from "interface/IntentionInterface";
import { IoMdRefreshCircle } from "react-icons/io";
import OthersIntentionRandom from "components/SettingIntentions/OthersIntentionRandom";

export default function SettingRandomIntentionsComponent({
  backAndNextHandler,
}: ComponentProps) {
  const { profile } = useAuthContext();
  const { selectedOrganisation } = useOrganisationContext();
  const { intentions } = useOrganisationIntentions();
  const [question, setQuestion] = useState("");
  const [isResetting, setIsResetting] = useState(false);
  const [randomizedQuestions, setRandomizedQuestions] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const isInitialized = useRef(false);

  // Separate function to shuffle questions
  const shuffleQuestions = (questions: Intention[]) => {
    return questions
      .map((item) => ({ question: item.question, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map((item) => item.question);
  };

  // Initialize or reset questions
  const initializeQuestions = () => {
    if (!intentions?.length) return;

    const shuffled = shuffleQuestions(intentions);
    setRandomizedQuestions(shuffled);
    setCurrentIndex(0);
    setQuestion(shuffled[0]);
  };

  // Handle manual refresh click
  const randomizeIntentionQuestion = () => {
    try {
      setIsResetting(true);

      if (
        !randomizedQuestions.length ||
        currentIndex >= randomizedQuestions.length - 1
      ) {
        // If we're at the end or have no questions, reshuffle
        initializeQuestions();
      } else {
        // Move to next question
        const nextIndex = currentIndex + 1;
        setCurrentIndex(nextIndex);
        setQuestion(randomizedQuestions[nextIndex]);
      }
    } catch (error) {
      console.error("Error randomizing questions:", error);
    } finally {
      setIsResetting(false);
    }
  };

  // Initialize questions when component mounts or intentions change
  useEffect(() => {
    if (
      selectedOrganisation?.randomizeIntentions &&
      intentions?.length &&
      !isInitialized.current
    ) {
      isInitialized.current = true;
      initializeQuestions();
    }
    // eslint-disable-next-line
  }, [intentions, selectedOrganisation?.randomizeIntentions]);

  return (
    <React.Fragment>
      <EmptySpace height={"2rem"} />

      {selectedOrganisation?.randomizeIntentions && (
        <div
          className={`${classes.container} flex items-center justify-center`}
        >
          <div className="w-full">
            <Instruction text={question} />
          </div>
          {intentions && intentions.length > 1 && (
            <button
              onClick={randomizeIntentionQuestion}
              disabled={isResetting}
              className="transition-opacity"
            >
              <IoMdRefreshCircle
                size={35}
                className={`${
                  isResetting ? "opacity-50" : ""
                } text-[var(--main-colour)]`}
              />
            </button>
          )}
        </div>
      )}

      <EmptySpace height={"2rem"} />
      <SubmitIntention profile={profile} question={question} />
      <EmptySpace height={"5px"} />
      <OthersIntentionRandom
        randomize={
          (selectedOrganisation && selectedOrganisation.randomizeIntentions) ||
          false
        }
      />
      <EmptySpace height={"30px"} />
      <BackAndNext {...backAndNextHandler} />
    </React.Fragment>
  );
}
