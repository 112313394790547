import { useAuthContext } from "hooks/useAuthContext";
import { useEffect, useState } from "react";
import classes from "./NGFooter.module.css";
import GrowthCollectiveLogo from "assets/NG/Logos/GrowthCollective.webp";
// import GBLogo from "assets/gblogo.png";
import { GrowthCircleSession } from "interface/GrowthCircleSessionInterface";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import {
  getDownloadURL,
  listAll,
  ref,
  StorageReference,
} from "firebase/storage";
import { projectStorage } from "../../firebase/config";

interface NGFooterProps {
  type?: string;
  GrowthCircle?: GrowthCircleSession;
}

// Add this to cache the URLs
const logoCache: { [key: string]: string } = {};

const NGFooter = ({ type, GrowthCircle }: NGFooterProps) => {
  const { profile } = useAuthContext();
  const { selectedOrganisation } = useOrganisationContext();
  const [logoUrl, setLogoUrl] = useState<string>("");

  const fetchLogo = async () => {
    if (!selectedOrganisation?.id) return;

    // Check cache first
    if (logoCache[selectedOrganisation.id]) {
      setLogoUrl(logoCache[selectedOrganisation.id]);
      return;
    }

    try {
      let storageRef: StorageReference;

      if (selectedOrganisation?.isFooterMain) {
        storageRef = ref(
          projectStorage,
          `organisations/logo/${selectedOrganisation?.id}`
        );
      } else {
        storageRef = ref(
          projectStorage,
          `organisations/logo/footer/${selectedOrganisation?.id}`
        );
      }

      const result = await listAll(storageRef);
      if (result.items.length > 0) {
        const url = await getDownloadURL(result.items[0]);
        logoCache[selectedOrganisation.id] = url; // Cache the URL
        setLogoUrl(url);
      }
    } catch (error) {
      console.error(error);
      setLogoUrl("");
    }
  };

  useEffect(() => {
    if (!profile || !selectedOrganisation) return;
    fetchLogo();
    // eslint-disable-next-line
  }, [selectedOrganisation?.id]); // Only re-run if org ID changes

  return (
    <div
      className={`fixed bottom-0 bg-default w-full border-t px-4 flex justify-between items-center z-50`}
      style={{ borderColor: "var(--main-colour)" }}
    >
      <div className="flex gap-2 justify-start items-center w-full">
        {selectedOrganisation?.footerSettings === "logo-with-collectives" && (
          <div className="py-2">
            <img
              src={GrowthCollectiveLogo}
              alt="GrowthCollective"
              width={75}
              height={20}
            />
          </div>
        )}
        {logoUrl ? (
          <div className="overflow-hidden py-2">
            <img
              src={logoUrl}
              alt={selectedOrganisation?.name}
              className="object-contain max-h-[50px] w-[130px]"
            />
          </div>
        ) : (
          <div className="py-2">
            <img
              src={GrowthCollectiveLogo}
              alt="GrowthCollective"
              width={75}
              height={20}
            />
          </div>
        )}
      </div>
      <p className={`${classes.powered} text-sm md:text-lg`}>
        Powered by Growthbeans
      </p>
    </div>
  );
};

export default NGFooter;
