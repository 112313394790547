import { useState, useEffect } from "react";

interface PhonePreviewProps {
  children: React.ReactNode;
  bgColor?: string;
}

const PhonePreview = ({
  children,
  bgColor = "bg-white",
}: PhonePreviewProps) => {
  const [time, setTime] = useState<string>(
    new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: false,
    })
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(
        new Date().toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          hour12: false,
        })
      );
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="flex justify-center items-center p-8">
      {/* Phone Container */}
      <div
        className={`relative w-[380px] h-[750px] ${bgColor} rounded-[2.5rem] shadow-xl border-[12px] border-black overflow-hidden`}
      >
        {/* Status Bar */}
        <div className="absolute z-50 top-0 inset-x-0">
          <div className="relative h-7 bg-black">
            {/* Left - Time */}
            <div className="absolute top-[6px] left-6 text-white text-[13px] font-medium">
              {time}
            </div>

            {/* Center - Notch */}
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-[120px] h-[25px] bg-black rounded-b-[1.5rem]">
              {/* Camera */}
              <div className="absolute top-[8px] right-[30px] w-[8px] h-[8px] bg-[#1f1f1f] rounded-full">
                <div className="absolute top-[2px] right-[2px] w-[3px] h-[3px] bg-[#0f0f0f] rounded-full" />
              </div>
              {/* Speaker */}
              <div className="absolute top-[10px] left-[45px] w-[30px] h-[4px] bg-[#1f1f1f] rounded-full" />
            </div>

            {/* Right - Signal & Battery */}
            <div className="absolute top-[6px] right-6 flex items-center gap-1.5">
              <div className="text-white">
                <svg width="18" height="12" viewBox="0 0 18 12">
                  <path
                    fillRule="evenodd"
                    d="M16 0h2v12h-2V0zm-4 3h2v9h-2V3zm-4 3h2v6H8V6zm-4 3h2v3H4V9zM0 10h2v2H0v-2z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="text-white">
                <svg width="24" height="12" viewBox="0 0 24 12">
                  <path
                    d="M19.75 1C19.75.59 19.41.25 19 .25H5c-.41 0-.75.34-.75.75v10c0 .41.34.75.75.75h14c.41 0 .75-.34.75-.75V1z"
                    stroke="currentColor"
                    fill="none"
                  />
                  <path
                    d="M21.5 4v4c.83 0 1.5-.67 1.5-1.5v-1c0-.83-.67-1.5-1.5-1.5z"
                    fill="currentColor"
                  />
                  <rect
                    x="5"
                    y="1"
                    width="14"
                    height="10"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        {/* Content Area */}
        <div className="h-full flex flex-col justify-start overflow-y-auto pt-8 pb-4 px-3">
          {children}
        </div>

        {/* Home Indicator */}
        <div className="absolute bottom-1 left-1/2 transform -translate-x-1/2 w-[120px] h-1 bg-black rounded-full" />
      </div>
    </div>
  );
};

export default PhonePreview;
