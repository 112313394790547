import { useCallback, useEffect, useState } from "react";
import useOrganisationContext from "./useOrganisationContext";
import { projectFirestore, timestamp } from "../../firebase/config";
import { DocumentData } from "firebase/firestore";
import {
  FeedbackQuestion,
  FeedbackQuestions,
} from "models/componentSettings/feedback/utility/feedback";
import { v4 as uuid } from "uuid";
import toast from "react-hot-toast";
import { FeedbackLayout } from "interface/FeedbackLayoutInterface";

export const useOrgFeedbackQuestions = () => {
  const { selectedOrganisation } = useOrganisationContext();
  const [feedbackLayout, setFeedbackLayout] = useState<FeedbackLayout[]>([]);

  const removeSpaces = (text: string) => {
    // Use a regular expression to replace all spaces with an empty string
    return text.replace(/\s/g, "");
  };

  const AddNewFeedbackSection = useCallback(
    async (name: string) => {
      try {
        const data = {
          title: name,
          createdAt: timestamp.fromDate(new Date()),
          order: (feedbackLayout?.length ?? 0) + 1,
          category: removeSpaces(name),
        };

        const ref = await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("feedbackQuestions")
          .add(data);
        await ref.update({ id: ref.id });
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line
    [selectedOrganisation]
  );

  const saveOrder = useCallback(
    async (selectedIds: string[]) => {
      try {
        const batch = projectFirestore.batch();

        selectedIds.forEach((sectionId, index) => {
          const sectionRef = projectFirestore
            .collection("organisations")
            .doc(selectedOrganisation?.id)
            .collection("feedbackQuestions")
            .doc(sectionId);

          batch.update(sectionRef, { order: index + 1 });
        });

        await batch.commit();
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const saveReOrdering = async (
    questionId: string,
    FeedbackLayout: FeedbackLayout
  ) => {
    try {
      projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("feedbackQuestions")
        .doc(questionId)
        .update(FeedbackLayout);
    } catch (error) {
      console.error(error);
    }
  };

  const DeleteFeedbackSection = useCallback(
    async (id: string) => {
      try {
        await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("feedbackQuestions")
          .doc(id)
          .delete();
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line
    [selectedOrganisation]
  );

  const AddNewQuestionFeedback = useCallback(
    async (
      id: string,
      questionName: string,
      questionType: string,
      questionContent: string,
      maxValue: number | undefined
    ) => {
      try {
        // Construct the path to the document you want to update
        const docRef = projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("feedbackQuestions")
          .doc(id);

        // Fetch the existing data
        const docSnapshot = await docRef.get();

        // Explicitly specify the type for existingData as DocumentData
        let existingData: DocumentData = {};

        if (docSnapshot.exists) {
          existingData = docSnapshot.data() as DocumentData;
        }

        // Check if the questionName already exists in subCategories (case-insensitive)
        const isDuplicate = existingData.subCategories?.some(
          (subCategory: any) =>
            subCategory.questionName.toLowerCase() ===
            questionName.toLowerCase()
        );

        if (isDuplicate) {
          // Handle the duplicate questionName here (e.g., show an error message)
          toast.error("Error duplicate question ID: " + questionName);
          throw new Error("Duplicate questionName: " + questionName);
        }

        // Create or update the subCategories array
        const updatedSubCategories = existingData.subCategories || [];
        //TODO: move this to a database or constant
        const defaultValues = {
          text: "",
          textArea: "",
          smileyRating: 3,
          smileyRatingWithMax: 5,
          slider: 5,
          sliderWithMax: 5,
          badgeRating: "",
          multipleChoice: [],
          yesOrNo: "Yes",
          SpecialThanks: "",
          imageUpload: [], // Default empty array for images
        };
        if (questionType === "imageUpload") {
          updatedSubCategories.push({
            uid: uuid(),
            questionName: questionName,
            type: questionType,
            question: {
              default: questionContent,
            },
            maxImages: maxValue ?? 5, // Use maxValue for maxImages if provided
            defaultResponse: defaultValues[questionType],
          });
        } else if (
          questionType === "smileyRatingWithMax" ||
          questionType === "sliderWithMax"
        ) {
          updatedSubCategories.push({
            uid: uuid(),
            questionName: questionName,
            type: questionType,
            question: {
              default: questionContent,
            },
            maxValue: maxValue ?? 5,
            defaultResponse: defaultValues[questionType],
          });
        } else {
          updatedSubCategories.push({
            uid: uuid(),
            questionName: questionName,
            type: questionType,
            question: {
              default: questionContent,
            },
            defaultResponse: defaultValues[questionType],
          });
        }

        // Update the document with the new or updated subCategories array
        await docRef.set(
          {
            subCategories: updatedSubCategories,
          },
          { merge: true }
        );
        toast.success("New question section was successfully added");
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const deleteQuestionFeedback = useCallback(
    async (id: string, questionName: string) => {
      try {
        const docRef = projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("feedbackQuestions")
          .doc(id);

        // Fetch the existing data
        const docSnapshot = await docRef.get();

        if (docSnapshot.exists) {
          const existingData = docSnapshot.data();

          if (existingData) {
            // Check if the questionName exists in the subCategories array
            const updatedSubCategories = existingData.subCategories || [];
            const questionIndex = updatedSubCategories.findIndex(
              (item: FeedbackQuestion) => item.questionName === questionName
            );

            if (questionIndex !== -1) {
              // Remove the question from the subCategories array
              updatedSubCategories.splice(questionIndex, 1);

              // Update the document with the modified subCategories array
              await docRef.set(
                {
                  subCategories: updatedSubCategories,
                },
                { merge: true }
              );
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const updateQuestionFeedback = useCallback(
    async (
      questionId: string,
      questionLabel: string,
      questionType: string,
      questionName: string,
      uid: string | undefined,
      maxValue: number | undefined
    ) => {
      try {
        const docRef = projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("feedbackQuestions")
          .doc(questionId);

        // Fetch the existing data
        const docSnapshot = await docRef.get();

        if (docSnapshot.exists) {
          const existingData = docSnapshot.data();

          if (existingData) {
            // Clone the subCategories array to avoid mutation
            const updatedSubCategories = [
              ...(existingData.subCategories || []),
            ];

            // Find the index of the subcategory with the specified UID
            const subCategoryIndex = updatedSubCategories.findIndex(
              (item: any) => item.uid === uid
            );

            if (subCategoryIndex !== -1) {
              // Update the specific subcategory
              updatedSubCategories[subCategoryIndex] = {
                ...updatedSubCategories[subCategoryIndex], // Keep existing fields
                questionName,
                question: {
                  default: questionLabel,
                },
                maxValue: maxValue ?? 5,
                maxImages: maxValue ?? 5,
                type: questionType,
              };

              // Update the document with the modified subCategories array
              await docRef.update({
                subCategories: updatedSubCategories,
              });
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [selectedOrganisation]
  );

  const organisationFeedbackMemo = () => {
    try {
      const unsubscribe = projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("feedbackQuestions")
        .orderBy("order", "asc")
        .onSnapshot((snapshot) => {
          const updatedFeedback: FeedbackLayout[] = [];
          snapshot.forEach((doc) => {
            updatedFeedback.push(doc.data() as FeedbackLayout);
          });
          setFeedbackLayout(updatedFeedback);
        });

      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.log(error);
    }
  };

  const getOrganisationFeedbackLayoutByName = (organisation: string) => {
    try {
      const unsubscribe = projectFirestore
        .collection("organisations")
        .where("name", "==", organisation)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            // Handle the case where no document matches the query
            return [];
          }

          // Assuming there's only one matching document, you can access it like this
          const organisationDoc = querySnapshot.docs[0];
          const feedbackQuestionsCollection =
            organisationDoc.ref.collection("feedbackQuestions");

          // Query the sub-collection and order by "order"
          return feedbackQuestionsCollection
            .orderBy("order", "asc")
            .get()
            .then((snapshot) => {
              const updatedFeedback: DocumentData[] = [];
              snapshot.forEach((doc) => {
                updatedFeedback.push({
                  id: doc.id,
                  ...doc.data(),
                });
              });
              return updatedFeedback;
            });
        })
        .catch((error) => {
          console.error(error);
          throw error; // Re-throw the error to reject the promise
        });

      // Return the unsubscribe function directly
      return unsubscribe;
    } catch (error) {
      console.log(error);
      throw error; // Re-throw the error to reject the promise
    }
  };

  const AddNewChoice = async (
    id: string | undefined,
    uid: string | undefined,
    choice: string
  ) => {
    try {
      if (id && uid) {
        // Get the reference to the document
        const docRef = projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("feedbackQuestions")
          .doc(id);

        // Get the document snapshot
        const docSnapshot = await docRef.get();

        // Check if the document exists
        if (docSnapshot.exists) {
          // Get the data
          const data = docSnapshot.data() as FeedbackQuestions;

          // Find the subCategory item with the matching uid
          const updatedSubCategory = data.subCategories.map((item: any) => {
            if (item.uid === uid) {
              // If the uid matches, add the choice to the choices array
              return {
                ...item,
                choices: item.choices ? [...item.choices, choice] : [choice],
              };
            }
            return item;
          });

          // Update the document with the modified subCategory
          await docRef.update({ subCategories: updatedSubCategory });
        } else {
          console.log("Document does not exist");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const RemoveChoice = async (
    id: string | undefined,
    uid: string | undefined,
    choiceToRemove: string
  ) => {
    try {
      if (id && uid) {
        // Get the reference to the document
        const docRef = projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("feedbackQuestions")
          .doc(id);

        // Get the document snapshot
        const docSnapshot = await docRef.get();

        // Check if the document exists
        if (docSnapshot.exists) {
          // Get the data
          const data = docSnapshot.data() as FeedbackQuestions;

          // Find the subCategory item with the matching uid
          const updatedSubCategory = data.subCategories.map((item: any) => {
            if (item.uid === uid) {
              // If the uid matches, remove the choice from the choices array
              const updatedChoices = item.choices.filter(
                (choice: string) => choice !== choiceToRemove
              );
              return {
                ...item,
                choices: updatedChoices,
              };
            }
            return item;
          });

          // Update the document with the modified subCategory
          await docRef.update({ subCategories: updatedSubCategory });
        } else {
          console.log("Document does not exist");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const UpdateChoice = async (
    id: string | undefined,
    uid: string | undefined,
    oldChoice: string,
    newChoice: string
  ) => {
    try {
      if (id && uid) {
        // Get the reference to the document
        const docRef = projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation?.id)
          .collection("feedbackQuestions")
          .doc(id);

        // Get the document snapshot
        const docSnapshot = await docRef.get();

        // Check if the document exists
        if (docSnapshot.exists) {
          // Get the data
          const data = docSnapshot.data() as FeedbackQuestions;

          // Find the subCategory item with the matching uid
          const updatedSubCategory = data.subCategories.map((item: any) => {
            if (item.uid === uid) {
              // If the uid matches, update the choice in the choices array
              const updatedChoices = item.choices.map((choice: string) =>
                choice === oldChoice ? newChoice : choice
              );
              return {
                ...item,
                choices: updatedChoices,
              };
            }
            return item;
          });

          // Update the document with the modified subCategory
          await docRef.update({ subCategories: updatedSubCategory });
        } else {
          console.log("Document does not exist");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateFeedbackSectionTitle = async (
    sectionId: string,
    newTitle: string
  ) => {
    try {
      if (selectedOrganisation) {
        await projectFirestore
          .collection("organisations")
          .doc(selectedOrganisation.id)
          .collection("feedbackQuestions")
          .doc(sectionId)
          .update({ title: newTitle });
      } else {
        console.error("Problem updating feedback title");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    organisationFeedbackMemo();
    // eslint-disable-next-line
  }, [selectedOrganisation]);

  return {
    AddNewFeedbackSection,
    DeleteFeedbackSection,
    deleteQuestionFeedback,
    AddNewQuestionFeedback,
    updateQuestionFeedback,
    getOrganisationFeedbackLayoutByName,
    saveOrder,
    feedbackLayout,
    AddNewChoice,
    RemoveChoice,
    UpdateChoice,
    saveReOrdering,
    updateFeedbackSectionTitle,
  };
};
