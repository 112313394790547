import RatingIconQuestion from "components/FeedBackForm/RatingIconQuestion";
import SliderQuestion from "components/FeedBackForm/SliderQuestion";
import SmileyRatingQuestion from "components/FeedBackForm/SmileyRatingQuestion";
import TextAreaQuestion from "components/FeedBackForm/TextAreaQuestion";
import TextQuestion from "components/FeedBackForm/TextQuestion";

import useBadges from "hooks/feedback/useBadges";
import useFeedback from "hooks/feedback/useFeedback";

import { FeedbackQuestion } from "models/componentSettings/feedback/utility/feedback";
import { Role } from "models/organisationRole";

import { lazy, ReactNode, Suspense, useEffect, useState } from "react";

import { FaEdit, FaTimes } from "react-icons/fa";

import useParticipants from "hooks/useParticipants";
import { useAuthContext } from "hooks/useAuthContext";

import { Participant } from "interface/ParticipantInterface";
import { FeedbackLayout } from "interface/FeedbackLayoutInterface";
import MultipleChoice from "components/FeedBackForm/MultipleChoice";

import YesOrNo from "components/FeedBackForm/YesOrNo";
import { v4 as uuidv4 } from "uuid";
import SpecialThanks from "components/SpecialThanks/SpecialThanks";
import SmileyRatingQuestionWithMax from "components/FeedBackForm/SmileyRatingQuestionWithMax";
import ImageUploadQuestion from "components/FeedBackForm/ImageUploadQuestion";

const EditFeedbackQuestionModal = lazy(
  () => import("./SubComponents/EditFeedbackQuestionModal")
);
const DeleteFeedbackQuestionModal = lazy(
  () => import("./SubComponents/DeleteFeedbackQuestionModal")
);

export type SmileyRatingQuestionProps = {
  viewOnly?: boolean;
  sectionId: string;
  questionCategory?: string;
  questions?: FeedbackQuestion[];
  sessionRole?: Role | null;
  children?: ReactNode;
  label?: string;
  editable?: boolean;
  feedbackResponses?: Object;
  participants?: Participant[];
  FeedbackLayout?: FeedbackLayout[];
  setHasModal?: (b: boolean) => any;
  adminEditing?: boolean;
};

export default function QuestionViewGroup({
  children,
  label = "",
  questionCategory = "",
  questions = [],
  sessionRole,
  editable,
  sectionId,
  viewOnly,
  feedbackResponses,
  participants,
  FeedbackLayout,
  setHasModal,
  adminEditing,
}: SmileyRatingQuestionProps) {
  const defaultResponses = {};
  questions.forEach(
    (question) =>
      (defaultResponses[question.questionName] = question.defaultResponse)
  );
  const { values, setValue } = useFeedback(
    questionCategory,
    defaultResponses ?? 1
  );

  const { ParticipantRecords } = useParticipants();
  const { profile } = useAuthContext();

  const sortedParticipantRecords = [...ParticipantRecords].sort((a, b) => {
    if (a.userId === profile?.uid) return 1;
    if (b.userId === profile?.uid) return -1;
    return 0;
  });

  const [question, setQuestion] = useState<FeedbackQuestion>();

  const { badges, toggleBadge } = useBadges();
  const [deleteShow, setDeleteShow] = useState(false);

  const [showEdit, setShowEdit] = useState(false);

  const deleteHandler = async (question: FeedbackQuestion) => {
    setQuestion(question);
    setDeleteShow(!deleteShow);
  };

  const editHandler = async (question: FeedbackQuestion) => {
    setQuestion(question);

    setShowEdit(!showEdit);
  };

  // Function to calculate the average of a specific section and key across participants
  function calculateAverageForSectionAndKey(
    participants: Participant[],
    section: string,
    key: string,
    defaultResponse: string | number | string[]
  ) {
    let sum = 0;
    let count = 0;

    participants.forEach((participant) => {
      if (
        participant.feedbackReflection &&
        participant.feedbackReflection[section] &&
        participant.feedbackReflection[section][key]
      ) {
        const value = participant.feedbackReflection[section][key];
        const numericValue = Number(value);

        if (!isNaN(numericValue)) {
          sum += numericValue;
          count++;
        }
      } else {
        // Use the defaultResponse if the key is missing or the value is not numeric
        const numericDefault = Number(defaultResponse);

        if (!isNaN(numericDefault)) {
          sum += numericDefault;
          count++;
        }
      }
    });

    if (count === 0) {
      return 0; // Avoid division by zero
    }

    return sum / count;
  }

  useEffect(() => {
    if (!ParticipantRecords) return;
    if (!profile) return;

    // eslint-disable-next-line
  }, [profile, ParticipantRecords]);

  return (
    <div className="text-center mb-[10%] w-[80%] mx-auto ">
      {question && (
        <>
          <Suspense>
            <DeleteFeedbackQuestionModal
              sectionId={sectionId}
              question={question}
              deleteShow={deleteShow}
              setDeleteShow={setDeleteShow}
            />
          </Suspense>
          <Suspense>
            <EditFeedbackQuestionModal
              sectionId={sectionId}
              showEdit={showEdit}
              setShowEdit={setShowEdit}
              question={question}
            />
          </Suspense>
        </>
      )}

      {label && (
        <p
          style={{ color: "var(--main-colour)" }}
          className={`text-lg font-semibold text-center`}
        >
          {label}
        </p>
      )}
      {questions.map((questionDetails, index) => {
        const commonProps = {
          label:
            questionDetails.question[sessionRole?.name ?? ""] ??
            questionDetails.question.default,
          key: questionDetails.questionName,
        };

        const average =
          participants &&
          feedbackResponses &&
          calculateAverageForSectionAndKey(
            participants,
            questionCategory,
            questionDetails.questionName,
            questionDetails.defaultResponse
          );

        const GlobalStyle =
          "border my-2 py-4 px-2 min-h-[160px] rounded-lg hover:border-[#EB8181] hover:bg-red-50/30 transition-all cursor-pointer";
        const GlobalEditDeleteStyle =
          "border p-2 rounded-lg bg-white absolute top-2 -right-8 flex flex-col justify-center items-center gap-4";

        switch (questionDetails.type) {
          case "text":
            return (
              <div className={`relative ${GlobalStyle}`} key={index}>
                {editable && (
                  <div className={`${GlobalEditDeleteStyle}`}>
                    <FaTimes
                      onClick={() => deleteHandler(questionDetails)}
                      size={20}
                      className="text-red-700 cursor-pointer"
                    />
                    <FaEdit
                      onClick={() => editHandler(questionDetails)}
                      size={20}
                      className="text-sky-600 cursor-pointer"
                    />
                  </div>
                )}
                <TextQuestion
                  isDisabled={viewOnly}
                  {...commonProps}
                  value={
                    feedbackResponses &&
                    feedbackResponses[questionCategory] &&
                    feedbackResponses[questionCategory][
                      questionDetails.questionName
                    ] !== undefined
                      ? feedbackResponses[questionCategory][
                          questionDetails.questionName
                        ]
                      : values[questionDetails.questionName]
                  }
                  onChange={(event) =>
                    setValue(questionDetails.questionName, event.target.value)
                  }
                />
              </div>
            );
          case "textArea":
            return (
              <div className={`relative ${GlobalStyle}`} key={index}>
                {editable && (
                  <div className={`${GlobalEditDeleteStyle}`}>
                    <FaTimes
                      onClick={() => deleteHandler(questionDetails)}
                      size={20}
                      className="text-red-700 cursor-pointer"
                    />
                    <FaEdit
                      onClick={() => editHandler(questionDetails)}
                      size={20}
                      className="text-sky-600 cursor-pointer"
                    />
                  </div>
                )}
                <TextAreaQuestion
                  isDisabled={viewOnly}
                  {...commonProps}
                  value={
                    feedbackResponses &&
                    feedbackResponses[questionCategory] &&
                    feedbackResponses[questionCategory][
                      questionDetails.questionName
                    ] !== undefined
                      ? feedbackResponses[questionCategory][
                          questionDetails.questionName
                        ]
                      : values[questionDetails.questionName]
                  }
                  onChange={(event) =>
                    setValue(questionDetails.questionName, event.target.value)
                  }
                />
              </div>
            );
          case "slider":
            return (
              <div className={`relative ${GlobalStyle}`} key={index}>
                {editable && (
                  <div className={`${GlobalEditDeleteStyle}`}>
                    <FaTimes
                      onClick={() => deleteHandler(questionDetails)}
                      size={20}
                      className="text-red-700 cursor-pointer"
                    />
                    <FaEdit
                      onClick={() => editHandler(questionDetails)}
                      size={20}
                      className="text-sky-600 cursor-pointer"
                    />
                  </div>
                )}
                <SliderQuestion
                  isDisabled={viewOnly}
                  isEditing={editable || adminEditing}
                  {...commonProps}
                  value={
                    feedbackResponses &&
                    feedbackResponses[questionCategory] &&
                    feedbackResponses[questionCategory][
                      questionDetails.questionName
                    ] !== undefined
                      ? Number(
                          feedbackResponses[questionCategory][
                            questionDetails.questionName
                          ]
                        )
                      : !isNaN(Number(values[questionDetails.questionName]))
                      ? Number(values[questionDetails.questionName])
                      : 0 // Default value for NaN or if feedbackResponses is not available
                  }
                  setValue={(value) =>
                    setValue(questionDetails.questionName, value.toString())
                  }
                />
                {viewOnly && (
                  <div className="pb-6">
                    Average rating: {`${average && average.toFixed(2)}`}
                  </div>
                )}
              </div>
            );
          case "sliderWithMax":
            return (
              <div className={`relative ${GlobalStyle}`} key={index}>
                {editable && (
                  <div className={`${GlobalEditDeleteStyle}`}>
                    <FaTimes
                      onClick={() => deleteHandler(questionDetails)}
                      size={20}
                      className="text-red-700 cursor-pointer"
                    />
                    <FaEdit
                      onClick={() => editHandler(questionDetails)}
                      size={20}
                      className="text-sky-600 cursor-pointer"
                    />
                  </div>
                )}
                <SliderQuestion
                  isDisabled={viewOnly}
                  isEditing={editable || adminEditing}
                  maxRating={questionDetails.maxValue ?? 10}
                  {...commonProps}
                  value={
                    feedbackResponses &&
                    feedbackResponses[questionCategory] &&
                    feedbackResponses[questionCategory][
                      questionDetails.questionName
                    ] !== undefined
                      ? Number(
                          feedbackResponses[questionCategory][
                            questionDetails.questionName
                          ]
                        )
                      : !isNaN(Number(values[questionDetails.questionName]))
                      ? Number(values[questionDetails.questionName])
                      : 1 // Default value for NaN or if feedbackResponses is not available
                  }
                  setValue={(value) =>
                    setValue(questionDetails.questionName, value.toString())
                  }
                />
                {viewOnly && (
                  <div className="pb-6">
                    Average rating: {`${average && average.toFixed(2)}`}
                  </div>
                )}
              </div>
            );
          case "smileyRating":
            return (
              <div className={`relative ${GlobalStyle}`} key={index}>
                {editable && (
                  <div className={`${GlobalEditDeleteStyle}`}>
                    <FaTimes
                      onClick={() => deleteHandler(questionDetails)}
                      size={20}
                      className="text-red-700 cursor-pointer"
                    />
                    <FaEdit
                      onClick={() => editHandler(questionDetails)}
                      size={20}
                      className="text-sky-600 cursor-pointer"
                    />
                  </div>
                )}
                <SmileyRatingQuestion
                  viewOnly={viewOnly}
                  {...commonProps}
                  value={
                    feedbackResponses &&
                    feedbackResponses[questionCategory] &&
                    feedbackResponses[questionCategory][
                      questionDetails.questionName
                    ] !== undefined
                      ? Number(
                          feedbackResponses[questionCategory][
                            questionDetails.questionName
                          ]
                        )
                      : Number(values[questionDetails.questionName])
                  }
                  name={questionDetails.questionName}
                  onChange={(event) =>
                    setValue(
                      questionDetails.questionName,
                      event.target.getAttribute("data-set") ?? ""
                    )
                  }
                />
                {viewOnly && (
                  <div className="pb-6">
                    Average rating: {`${average && average.toFixed(2)}`}
                  </div>
                )}
              </div>
            );
          case "smileyRatingWithMax":
            return (
              <div className={`relative ${GlobalStyle}`} key={index}>
                {editable && (
                  <div className={`${GlobalEditDeleteStyle}`}>
                    <FaTimes
                      onClick={() => deleteHandler(questionDetails)}
                      size={20}
                      className="text-red-700 cursor-pointer"
                    />
                    <FaEdit
                      onClick={() => editHandler(questionDetails)}
                      size={20}
                      className="text-sky-600 cursor-pointer"
                    />
                  </div>
                )}
                <SmileyRatingQuestionWithMax
                  {...commonProps}
                  value={
                    feedbackResponses &&
                    feedbackResponses[questionCategory] &&
                    feedbackResponses[questionCategory][
                      questionDetails.questionName
                    ] !== undefined
                      ? Number(
                          feedbackResponses[questionCategory][
                            questionDetails.questionName
                          ]
                        )
                      : Number(values[questionDetails.questionName])
                  }
                  name={questionDetails.questionName}
                  viewOnly={viewOnly}
                  onChange={(event) =>
                    setValue(
                      questionDetails.questionName,
                      event.currentTarget.getAttribute("data-set") ?? ""
                    )
                  }
                  maxRating={questionDetails.maxValue}
                />
                {viewOnly && (
                  <div className="pb-6">
                    Average rating: {`${average && average.toFixed(2)}`}
                  </div>
                )}
              </div>
            );
          case "badgeRating":
            return (
              <div className={`relative `} key={index}>
                {editable && (
                  <div className={`${GlobalEditDeleteStyle}`}>
                    <FaTimes
                      onClick={() => deleteHandler(questionDetails)}
                      size={20}
                      className="text-red-700 cursor-pointer"
                    />
                    <FaEdit
                      onClick={() => editHandler(questionDetails)}
                      size={20}
                      className="text-sky-600 cursor-pointer"
                    />
                  </div>
                )}
                <RatingIconQuestion
                  participants={sortedParticipantRecords}
                  badges={
                    feedbackResponses &&
                    feedbackResponses["feedbackForGroup"] &&
                    feedbackResponses["feedbackForGroup"]["Badges"] !==
                      undefined
                      ? feedbackResponses["feedbackForGroup"]["Badges"]
                      : badges
                  }
                  toggleBadge={viewOnly ? () => {} : toggleBadge}
                />
                {feedbackResponses &&
                  feedbackResponses[questionCategory] &&
                  feedbackResponses[questionCategory][
                    questionDetails.questionName
                  ]}
              </div>
            );
          case "multipleChoice":
            return (
              <div className={`relative ${GlobalStyle}`} key={index}>
                {editable && (
                  <div className={`${GlobalEditDeleteStyle}`}>
                    <FaTimes
                      onClick={() => deleteHandler(questionDetails)}
                      size={20}
                      className="text-red-700 cursor-pointer"
                    />
                    <FaEdit
                      onClick={() => editHandler(questionDetails)}
                      size={20}
                      className="text-sky-600 cursor-pointer"
                    />
                  </div>
                )}
                <MultipleChoice
                  isDisabled={viewOnly}
                  {...commonProps}
                  editable={editable}
                  id={sectionId}
                  uid={questionDetails.uid}
                  choices={questionDetails.choices}
                  values={
                    feedbackResponses &&
                    feedbackResponses[questionCategory] &&
                    feedbackResponses[questionCategory][
                      questionDetails.questionName
                    ] !== undefined
                      ? feedbackResponses[questionCategory][
                          questionDetails.questionName
                        ]
                      : values[questionDetails.questionName]
                  }
                  questionName={questionDetails.questionName}
                  setValue={setValue}
                />
              </div>
            );
          case "yesOrNo":
            return (
              <div className={`relative ${GlobalStyle}`} key={index}>
                {editable && (
                  <div className={`${GlobalEditDeleteStyle}`}>
                    <FaTimes
                      onClick={() => deleteHandler(questionDetails)}
                      size={20}
                      className="text-red-700 cursor-pointer"
                    />
                    <FaEdit
                      onClick={() => editHandler(questionDetails)}
                      size={20}
                      className="text-sky-600 cursor-pointer"
                    />
                  </div>
                )}
                <YesOrNo
                  id={uuidv4()}
                  questionName={questionDetails.questionName}
                  setValue={setValue}
                  {...commonProps}
                  isDisabled={viewOnly}
                  values={
                    feedbackResponses &&
                    feedbackResponses[questionCategory] &&
                    feedbackResponses[questionCategory][
                      questionDetails.questionName
                    ] !== undefined
                      ? feedbackResponses[questionCategory][
                          questionDetails.questionName
                        ]
                      : values[questionDetails.questionName]
                  }
                />
              </div>
            );
          case "SpecialThanks":
            return (
              <div key={index}>
                {!viewOnly && (
                  <div className={`relative ${GlobalStyle}`}>
                    {editable && (
                      <div className={`${GlobalEditDeleteStyle}`}>
                        <FaTimes
                          onClick={() => deleteHandler(questionDetails)}
                          size={20}
                          className="text-red-700 cursor-pointer"
                        />
                        <FaEdit
                          onClick={() => editHandler(questionDetails)}
                          size={20}
                          className="text-sky-600 cursor-pointer"
                        />
                      </div>
                    )}
                    <SpecialThanks
                      isDisabled={adminEditing}
                      setHasModal={setHasModal}
                      participants={ParticipantRecords}
                    />
                  </div>
                )}
              </div>
            );
          case "imageUpload":
            return (
              <div className={`relative ${GlobalStyle}`} key={index}>
                {editable && (
                  <div className={`${GlobalEditDeleteStyle}`}>
                    <FaTimes
                      onClick={() => deleteHandler(questionDetails)}
                      size={20}
                      className="text-red-700 cursor-pointer"
                    />
                    <FaEdit
                      onClick={() => editHandler(questionDetails)}
                      size={20}
                      className="text-sky-600 cursor-pointer"
                    />
                  </div>
                )}
                <ImageUploadQuestion
                  {...commonProps}
                  setHasModal={setHasModal}
                  isDisabled={viewOnly || adminEditing}
                  value={(() => {
                    try {
                      const feedbackValue =
                        feedbackResponses?.[questionCategory]?.[
                          questionDetails.questionName
                        ];
                      if (feedbackValue) return JSON.parse(feedbackValue);

                      const defaultValue = values[questionDetails.questionName];
                      if (defaultValue) return JSON.parse(defaultValue);

                      return [];
                    } catch (e) {
                      return [];
                    }
                  })()}
                  onChange={(images) =>
                    setValue(
                      questionDetails.questionName,
                      JSON.stringify(images)
                    )
                  }
                  instanceId={questionDetails.questionName}
                  maxImages={questionDetails.maxImages ?? 5}
                />
              </div>
            );
          default:
            return <></>;
        }
      })}

      {children ?? ""}
    </div>
  );
}
