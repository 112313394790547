/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from "react";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useResourcesOrganisation } from "hooks/useResourcesOrganisation";
import PageWrapper from "components/utility/PageWrapper";
import Header from "components/Header/Header";
import { FaExternalLinkAlt, FaLink } from "react-icons/fa";

/**
 * Page that shows the ground rules for users when they first start a GC session
 */
const ResourceLandingPage = () => {
  const { selectedOrganisation } = useOrganisationContext();
  const { resources } = useResourcesOrganisation();

  useEffect(() => {
    // eslint-disable-next-line
  }, [selectedOrganisation]);

  return (
    <PageWrapper pageType={"groundRules"}>
      <Header />
      <div className="max-w-4xl mx-auto px-6 py-8">
        {/* Header Section */}
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">Resources</h1>
          <p className="text-gray-600">
            Access helpful resources and external links for additional support
            and information.
          </p>
        </div>

        <div className="space-y-8">
          {/* Organisation Resources Section */}
          {resources.length > 0 && (
            <div className="bg-white rounded-lg border border-gray-200 p-6">
              <div className="flex items-center gap-2 mb-4">
                <div className="w-2 h-2 rounded-full bg-[#EB8181]" />
                <h2 className="text-lg font-semibold text-gray-800">
                  Organisation Resources
                </h2>
              </div>
              <div className="space-y-3">
                {resources.map((res, ind) => (
                  <a
                    key={ind}
                    href={res.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-between p-4 rounded-lg border border-gray-200 hover:border-[#EB8181] transition-colors group"
                  >
                    <div className="flex items-center gap-3">
                      <div className="p-2 bg-red-50 rounded-full">
                        <FaLink className="text-[#EB8181]" />
                      </div>
                      <span className="font-medium text-gray-700 group-hover:text-[#EB8181] transition-colors">
                        {res.title}
                      </span>
                    </div>
                    <FaExternalLinkAlt className="text-gray-400 group-hover:text-[#EB8181] transition-colors" />
                  </a>
                ))}
              </div>
            </div>
          )}

          {/* General Resources Section */}
          {selectedOrganisation?.useDefaultResources !== false && (
            <div className="bg-white rounded-lg border border-gray-200 p-6">
              <div className="flex items-center gap-2 mb-4">
                <div className="w-2 h-2 rounded-full bg-gray-400" />
                <h2 className="text-lg font-semibold text-gray-800">
                  General Resources
                </h2>
              </div>
              <div className="space-y-3">
                {[
                  {
                    url: "https://www.imh.com.sg/CHAT/Pages/default.aspx",
                    title: "IMH CHAT",
                  },
                  { url: "https://mindline.sg/", title: "Mindline.sg" },
                  {
                    url: "https://www.healthhub.sg/programmes/186/MindSG/Discover",
                    title: "HealthHub SG",
                  },
                  {
                    url: "https://aic.buzz/Top5_MH",
                    title: "AIC Mental Health Resources",
                  },
                  {
                    url: "https://aic.buzz/MHListing",
                    title: "Mental Health Services Directory",
                  },
                  {
                    url: "https://aic.buzz/mindmatters-directory",
                    title: "Mind Matters Directory",
                  },
                ].map((resource, index) => (
                  <a
                    key={index}
                    href={resource.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-between p-4 rounded-lg border border-gray-200 hover:border-[#EB8181] transition-colors group"
                  >
                    <div className="flex items-center gap-3">
                      <div className="p-2 bg-gray-100 rounded-full">
                        <FaLink className="text-gray-400" />
                      </div>
                      <span className="font-medium text-gray-700 group-hover:text-[#EB8181] transition-colors">
                        {resource.title}
                      </span>
                    </div>
                    <FaExternalLinkAlt className="text-gray-400 group-hover:text-[#EB8181] transition-colors" />
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

// https://mindline.sg/
// https://www.healthhub.sg/programmes/186/MindSG/Discover
// https://aic.buzz/Top5_MH
// https://aic.buzz/MHListing
// aic.buzz/mindmatters-directory

export default ResourceLandingPage;
