import { useTour } from "@reactour/tour";
import SimpleLoader from "components/Loaders/SimpleLoader";
import EmptySpace from "components/utility/EmptySpace";
import InputField from "components/utility/Forms/InputField";

import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { useOrganisationTopics } from "hooks/useOrganisationTopics";
import usePromise from "hooks/utility/usePromise";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { FormEvent, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import TopicCategoryCards from "./TopicCategoryCards";
import TopicSortButton from "./TopicSortButton";
import OrganisationTopics from "interface/OrganisationTopicsInterface";

interface TopicsSettingsProps {
  topics: OrganisationTopics[];
}

const TopicSettings = ({ topics }: TopicsSettingsProps) => {
  const { setIsOpen: setIsTourOpen } = useTour();
  const { selectedOrganisation, updateSelectedOrganisation } =
    useOrganisationContext();
  const { AddNewCategory, updateOrganisationTopicSettings } =
    useOrganisationTopics();
  const [category, setCategory] = useState<string>("");
  const { isLoading, resolve } = usePromise();
  const [updating, setIsUpdating] = useState(false);
  const [checkboxState, setCheckboxState] = useState({
    inheritGeneralTopics: false,
    sessionCustomTopic: true,
  });

  useEffect(() => {
    if (!selectedOrganisation) return;
    setCheckboxState({
      inheritGeneralTopics: selectedOrganisation.inheritGeneralTopics ?? false,
      sessionCustomTopic: selectedOrganisation.sessionCustomTopic ?? true,
    });

    // eslint-disable-next-line
  }, [selectedOrganisation]);

  const categorySubmitHandler = (e: FormEvent) => {
    e.preventDefault();

    const isDuplicate = topics.some(
      (topic) => topic.category.toLowerCase() === category.toLowerCase()
    );

    if (category === "") {
      toast.error("Cannot process blank category or same value");
    } else if (isDuplicate) {
      toast.error("Category already exists");
    } else {
      resolve(() => AddNewCategory(category)).then(() => {
        toast.success("Category was added successfully");
      });
      setCategory("");
    }
  };

  const handleCheckboxChange = async (checkboxName: string) => {
    setIsUpdating(true);
    const updatedCheckboxState = {
      ...checkboxState,
      [checkboxName]: !checkboxState[checkboxName],
    };

    setCheckboxState(updatedCheckboxState);
    updateOrganisationTopicSettings(updatedCheckboxState).then(() => {
      updateSelectedOrganisation({ name: selectedOrganisation?.name }).then(
        () => {
          setIsUpdating(false);
          toast.success("Settings was updated successfully.");
        }
      );
    });
  };

  return (
    <div className="mb-[100px]">
      {selectedOrganisation &&
        selectedOrganisation.components.includes("choose-topic") && (
          <>
            <EmptySpace />
            <button
              className={`${classes["button"]}`}
              onClick={() => setIsTourOpen(true)}
            >
              Show Tutorial
            </button>
            <EmptySpace />
            <div className="flex items-center gap-4">
              <p className="text-xl my-4 text-slate-600">
                Topics for <strong>{selectedOrganisation.name}</strong>
              </p>
            </div>
            <p className=" mb-8 justify-start items-center gap-2 text-slate-500 text-lg">
              Please input the name of the topic category below to create a new
              category. Once you've successfully added the category, you can add
              topics by clicking the plus button. You can choose whether to
              inherit all the topics from the general topics or select topics
              with an information icon.
            </p>
            {!updating ? (
              <div className="flex flex-col items-start gap-2  text-2xl">
                <label
                  topics-settings-tour="inherit-general-topics"
                  className="cursor-pointer"
                >
                  <input
                    type="checkbox"
                    checked={selectedOrganisation.inheritGeneralTopics ?? false}
                    onChange={() =>
                      handleCheckboxChange("inheritGeneralTopics")
                    }
                    className="mx-4"
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "5px",
                      backgroundColor: selectedOrganisation.inheritGeneralTopics
                        ? "var(--icon-colour-0)"
                        : "",
                    }}
                  />
                  Inherit General Topics
                </label>

                <label
                  topics-settings-tour="participants-create-topics"
                  className="cursor-pointer"
                >
                  <input
                    type="checkbox"
                    checked={selectedOrganisation.sessionCustomTopic ?? true}
                    onChange={() => handleCheckboxChange("sessionCustomTopic")}
                    className="mx-4"
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "5px",
                      backgroundColor:
                        selectedOrganisation.sessionCustomTopic ||
                        selectedOrganisation.sessionCustomTopic === undefined
                          ? "var(--icon-colour-0)"
                          : "",
                    }}
                  />
                  Allow user/participant to create own topics
                </label>
              </div>
            ) : (
              <SimpleLoader />
            )}

            <form
              topics-settings-tour="add-topic-category"
              onSubmit={categorySubmitHandler}
              className="my-8 flex items-center gap-2"
            >
              <InputField
                text="Category name"
                value={category}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCategory(e.target.value)
                }
                isRequired={true}
              />
              <button disabled={isLoading} type="submit">
                {isLoading ? "Submitting..." : "Add Category"}
              </button>
            </form>

            {!isLoading && (
              <>
                <div className="w-[120px]">
                  <TopicSortButton topics={topics} />
                </div>
                <TopicCategoryCards
                  topics={topics.sort(
                    (a, b) => (a.order ?? 0) - (b.order ?? 0)
                  )}
                />
              </>
            )}
            {isLoading && <SimpleLoader />}
          </>
        )}
      {selectedOrganisation &&
        !selectedOrganisation.components.includes("choose-topic") && (
          <p className="py-8">Choose Topics is not included on pages tab</p>
        )}
    </div>
  );
};

export default TopicSettings;
