import EmptySpace from "components/utility/EmptySpace";
import { Modal } from "flowbite-react";
import { useGCSession } from "hooks/useGCSession";

import useParticipants from "hooks/useParticipants";
import { checkCanAdvanceNextActivity } from "models/organisationRole";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "assets/Xbutton.svg";
import Instruction from "components/utility/Instruction";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";
import BackAndNext from "components/utility/BackAndNext";
import { useOrganisationActivityPage } from "hooks/useOrganisationActivityPage";
import ActivityCard from "components/ActivityCard/ActivityCard";
import { updateUser } from "models/profile";
import SimpleLoader from "components/Loaders/SimpleLoader";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { ReactComponent as InfoIcon } from "assets/NG/Activity/info-icon.svg";
import { Activity } from "interface/ActivityInterface";
import { debounce } from "lodash";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import Lightbox, { ImagesListType } from "react-spring-lightbox";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import {
  BiDownArrowCircle,
  BiExpand,
  BiLeftArrowCircle,
  BiMinusCircle,
  BiPlusCircle,
  BiReset,
  BiRightArrowCircle,
  BiUpArrowCircle,
  BiXCircle,
} from "react-icons/bi";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import ImageCarousel from "components/utility/PreviewComponents/ImageCarousel";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const UploadImage = lazy(() => import("components/UploadImage/UploadImage"));

function checkIsMostVotedActivity(
  activityName: string,
  activities: { [key: string]: number }
) {
  const highestCount = Object.values(activities).reduce(
    (prev, curr) => (prev > curr ? prev : curr),
    0
  );
  return activities[activityName] === highestCount;
}

function checkHasTie(activities: [string, number][]) {
  const firstCount = activities[0] ? activities[0][1] : 0;
  const secondCount = activities[1] ? activities[1][1] : 0;
  return firstCount === secondCount;
}

// interface Props {
//   type: "SUSS" | "NG";
// }

/**
 * Page to navigate to next.
 * @param type Type of choose activity page.
 * @returns ChooseActivityPage component.
 */
export default function ChooseActivityComponent({
  setHasModal,
  backAndNextHandler,
}: ComponentProps) {
  const { updateSelectedActivity } = useGCSession();
  const {
    ParticipantRecord,
    ParticipantRecords,
    sessionRole,
    updateActivityTitle,
  } = useParticipants();

  const { growthCircleSession } = useGrowthCircleContext();
  const [isVisible, setIsVisible] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const canAdvance = checkCanAdvanceNextActivity(sessionRole);
  const { activities, isLoading } = useOrganisationActivityPage();
  const [focusActivity, setFocusActivity] = useState<Activity | null>(null);
  const [activeComponent, setActivityComponent] =
    useState("ChooseActivityCard");
  const { selectedOrganisation } = useOrganisationContext();
  const [inputValue, setInputValue] = useState("");
  const [page, setPage] = useState<number>(1);
  const [hasLightBox, setHasLightBox] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showLightBox, setShowLightBox] = useState(false);
  const [zoom, setZoom] = useState(1.1);
  const [top, setTop] = useState(0);
  const [right, setRight] = useState(0);
  const [proceedDelete, setProceedDelete] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInstruction, setShowInstruction] = useState(false);

  const incrementZoom = () => {
    // Increase zoom by a certain factor (e.g., 0.1)
    setZoom(zoom + 0.1);
  };

  const decrementZoom = () => {
    // Decrease zoom by a certain factor (e.g., 0.1)
    setZoom(zoom - 0.1);
  };

  const incrementTop = () => {
    // Increase top by a certain value (e.g., 10)
    setTop(top + 30);
  };

  const decrementTop = () => {
    // Decrease top by a certain value (e.g., 10)
    setTop(top - 30);
  };

  const incrementRight = () => {
    setRight(right + 30);
  };

  const decrementRight = () => {
    setRight(right - 30);
  };
  const resetHandler = () => {
    setZoom(1.1);
    setTop(0);
    setRight(0);
  };

  const setShowLightHandler = () => {
    setShowLightBox(!showLightBox);
    setHasModal(!showLightBox);
  };

  const images: ImagesListType = focusActivity
    ? [
        {
          src: ``,
          loading: "lazy",
          alt: "",
        },
      ]
    : [];

  const gotoPrevious = () => {
    activeIndex > 0 && setActiveIndex(activeIndex - 1);
  };

  const gotoNext = () => {
    activeIndex + 1 < images.length && setActiveIndex(activeIndex + 1);
  };

  // count all activity inside ParticipantRecords Array and get the highest count
  const activityCount = ParticipantRecords.reduce<{ [key: string]: number }>(
    (acc, cur) => {
      if (cur.activity) {
        acc[cur.activity] = (acc[cur.activity] || 0) + 1;
      }
      return acc;
    },
    {}
  );

  const sortedActivities = Object.entries(activityCount).sort(
    ([, countA], [, countB]) => (countB as number) - (countA as number)
  );

  const highestCountActivity = sortedActivities[0]
    ? sortedActivities[0]
    : ["", 0];
  const participant = ParticipantRecord[0];
  const selectedActivity = participant?.activity;

  const modalHandler = async () => {
    setIsVisible(!isVisible);
    setHasModal(!isVisible);
    if (!isVisible) {
      document.body.style.overflow = "unset";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  const InfoModalHandler = async () => {
    setShowInstruction(!showInstruction);
    setHasModal(!showInstruction);
    if (!showInstruction) {
      document.body.style.overflow = "unset";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  /**
   * If the user is a facilitator, and the highest voted activity is not the facilitator's activity, then show a
   * confirmation modal. Otherwise, allow the user to navigate to the next page
   */
  const nextHandler = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (canAdvance) {
      if (
        selectedActivity &&
        checkIsMostVotedActivity(selectedActivity, activityCount) &&
        !checkHasTie(sortedActivities)
      ) {
        proceedNextActivity(e);
      } else {
        setIsVisible(true);
        setHasModal(true);
        document.body.style.overflow = "unset";
      }
    } else {
      proceedNextActivity(e);
    }
    updateUser(ParticipantRecord[0].userId, {
      activity: selectedActivity,
    });

    const activity = activities.filter(
      (activity) => activity.title === selectedActivity
    );
    if (activity.length > 0) {
      setFocusActivity(activity[0]);
    }
  };

  const proceedNextActivity = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (canAdvance && selectedActivity) {
      setRefreshing(true);
      await updateSelectedActivity(
        selectedActivity,
        activityCount[selectedActivity]
      );
      setRefreshing(false);
      setHasModal(false);
      const activity = activities.filter(
        (act) => act.title === selectedActivity
      );
      if (activity && activity.length > 0) {
        if (
          activity[0].activityType === "Standard" ||
          activity[0].activityType === undefined
        ) {
          backAndNextHandler.nextCallback(e);
        } else {
          //change component view
          setActivityComponent("ActivityTypeReflection");
        }
      }
    } else {
      if (!selectedActivity) {
        return;
      }
      setActivityComponent("ActivityTypeReflection");

      const activity = activities.filter(
        (act) => act.title === selectedActivity
      );
      if (
        activity[0].activityType === "Standard" ||
        activity[0].activityType === undefined
      ) {
        backAndNextHandler.nextCallback(e);
      }
    }
  };

  const getVideoID = (url: string) => {
    // Regular expression to extract the video ID from the URL
    const regExp = /youtu\.be\/([\w-]+)/;

    // Executing the regular expression on the URL
    const match = url.match(regExp);

    // Check if a match is found and extract the video ID
    const videoID = match && match[1];

    return videoID;
  };

  const debouncedUpdate = debounce((value: string) => {
    if (value) {
      updateActivityTitle(value);
    }
  }, 1000); // 1000 ms debounce delay

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setInputValue(e.target.value);
    debouncedUpdate(inputValue);
  };

  useEffect(() => {
    if (ParticipantRecord[0]?.activityTitle) {
      setInputValue(ParticipantRecord[0].activityTitle);
    }

    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, ParticipantRecord]);

  useEffect(() => {
    if (
      focusActivity &&
      focusActivity.focusPage &&
      focusActivity.focusPage[0] === "All"
    ) {
      setPage(1);
    } else {
      const focusPageNumber = parseInt(
        (focusActivity &&
          focusActivity.focusPage &&
          focusActivity.focusPage[0]) ||
          "1",
        10
      );
      setPage(isNaN(focusPageNumber) ? 1 : focusPageNumber);
    }
  }, [focusActivity]);

  const goToPreviousPage = () => {
    if (focusActivity && focusActivity.focusPage) {
      const currentPageIndex = focusActivity.focusPage.indexOf(page.toString());
      if (currentPageIndex > 0 && focusActivity.focusPage) {
        const previousPage = focusActivity.focusPage[currentPageIndex - 1];
        setPage(parseInt(previousPage));
      }
    }
  };

  const goToNextPage = () => {
    if (focusActivity && focusActivity.focusPage) {
      const currentPageIndex = focusActivity.focusPage.indexOf(page.toString());
      if (currentPageIndex < focusActivity.focusPage.length - 1) {
        const nextPage = focusActivity.focusPage[currentPageIndex + 1];
        setPage(parseInt(nextPage));
      }
    }
  };

  useEffect(() => {
    if (!growthCircleSession) return;
  }, [growthCircleSession]);

  const setLightBoxHandler = () => {
    setHasModal(!hasLightBox);
    setHasLightBox(!hasLightBox);
  };

  const deleteModalHandler = () => {
    setHasModal(!showDeleteModal);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleConfirmDelete = () => {
    setHasModal(!showDeleteModal);
    setShowDeleteModal(!showDeleteModal);
  };

  const confirmDelete = () => {
    handleConfirmDelete();
    setProceedDelete(true);
    const timeout = setTimeout(() => {
      setProceedDelete(false);
    }, 1000); // 5000 milliseconds (5 seconds)

    return () => {
      clearTimeout(timeout);
    };
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (
      focusActivity &&
      focusActivity.customInstructionModal &&
      focusActivity.customInstructionModal !== ""
    )
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(focusActivity.customInstructionModal))
        )
      );
  }, [focusActivity, showInstruction]);

  return (
    <React.Fragment>
      {focusActivity &&
        focusActivity.customInstructionModal &&
        focusActivity.customInstructionModal !== "" && (
          <React.Fragment>
            <Modal
              show={showInstruction}
              size="md"
              className="min-h-screen"
              popup={true}
            >
              <Modal.Body className="p-5 relative">
                <div className="absolute right-0 top-0 p-2">
                  <CloseIcon
                    className="cursor-pointer"
                    onClick={InfoModalHandler}
                  />
                </div>
                <div className="text-center py-5 px-2">
                  <div className="text-slate-700 text-left">
                    {focusActivity &&
                      focusActivity.hasInfoModal &&
                      focusActivity.customInstructionModal !== "" && (
                        <Editor
                          toolbar={{ options: [] }}
                          readOnly={true}
                          editorState={editorState}
                          toolbarClassName="border-none"
                          wrapperClassName="border-none"
                          editorClassName="p-2 select-none"
                          onEditorStateChange={() => {}}
                        />
                      )}
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </React.Fragment>
        )}

      <React.Fragment>
        <Modal
          show={showDeleteModal}
          size="md"
          className="min-h-screen"
          popup={true}
        >
          <Modal.Body className="p-5 relative ">
            <div className="absolute right-0 top-0 p-2">
              <CloseIcon
                className="cursor-pointer"
                onClick={deleteModalHandler}
              />
            </div>
            <div className="text-center py-8  ">
              <h4 className="font-semibold mt-5 text-lg text-slate-700">
                You are about to delete this picture. Do you wish to proceed?
              </h4>
              <EmptySpace height={"20px"} />
              <div className="flex justify-center gap-4 mt-5">
                <button
                  type="button"
                  className="shadow-md bg-facil-colour px-12 rounded-3xl text-white py-3 hover:bg-green-700 transition duration-1000"
                  onClick={confirmDelete}
                >
                  <span>{"Yes"}</span>
                </button>
                <button
                  type="button"
                  className="shadow-md bg-facil-colour px-12 rounded-3xl py-3 text-white hover:bg-red-700 transition duration-1000"
                  onClick={deleteModalHandler}
                >
                  No
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
      <React.Fragment>
        <Modal show={isVisible} size="md" className="min-h-screen" popup={true}>
          <Modal.Body className="p-5 relative mt-[25%] md:mt-[5%]">
            <div className="absolute right-0 top-0 p-2">
              <CloseIcon className="cursor-pointer" onClick={modalHandler} />
            </div>
            <div className="text-center py-5 px-2">
              <h3 className="mb-5 py-4 text-lg font-normal text-gray-500 dark:text-gray-400">
                {checkHasTie(sortedActivities) ? (
                  <small className="block text-sm mt-5">
                    Since no activity got the most votes, should we keep going
                    with{" "}
                    <span className="font-semibold">{selectedActivity}</span> ?
                  </small>
                ) : (
                  <>
                    {`${highestCountActivity[1]} / ${ParticipantRecords.length}
                      of your group's participants picked `}
                    <span className="font-semibold">
                      {highestCountActivity[0]}
                    </span>
                    .
                    <small className="block text-sm mt-5">
                      Are you sure you wish to pick{" "}
                      <span className="font-semibold">{selectedActivity}</span>{" "}
                      instead of{" "}
                      <span className="font-semibold">
                        {highestCountActivity[0]}
                      </span>
                    </small>
                  </>
                )}
              </h3>

              <div className="flex justify-center gap-4">
                <button
                  type="button"
                  disabled={refreshing}
                  className="shadow-md bg-facil-colour px-12 rounded-3xl text-white py-3 hover:bg-green-700 transition duration-1000"
                  onClick={proceedNextActivity}
                >
                  <span>{refreshing === false ? "Yes " : "Processing..."}</span>
                </button>
                <button
                  type="button"
                  disabled={refreshing}
                  className="shadow-md bg-facil-colour px-12 rounded-3xl py-3 text-white hover:bg-red-700 transition duration-1000"
                  onClick={modalHandler}
                >
                  No
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
      {activeComponent === "ChooseActivityCard" ? (
        <>
          <div className="py-6 text-center flex flex-col items-center">
            <h3 className="font-bold text-xl w-72">
              <span className="block">
                {selectedOrganisation?.activityTitle ??
                  "Which activity would you like to do?"}
              </span>
            </h3>
            <Instruction
              text={
                selectedOrganisation?.activityInstruction ??
                "Select the activity for today's Growth Circle Session"
              }
            />
          </div>
          <div className="flex flex-wrap gap-4 items-center justify-center">
            {!isLoading ? (
              activities
                .filter(
                  (card) =>
                    card.isEnabled === undefined || card.isEnabled === true
                ) // Filter activities
                .map((card, index) => (
                  <ActivityCard
                    activity={card}
                    key={index}
                    text={card.title}
                    description={card.description}
                    width="180px"
                    height="210px"
                    hasImage={card.hasImage}
                  />
                ))
            ) : (
              <div className="h-96 flex justify-center items-center">
                {isLoading && <SimpleLoader />}
              </div>
            )}
          </div>
          <EmptySpace height={"40px"} />
          <BackAndNext
            {...backAndNextHandler}
            nextCallback={(e) => {
              nextHandler(e);
            }}
            nextAllowed={!!selectedActivity}
          />
        </>
      ) : (
        <>
          <div className="py-6 text-center flex flex-col items-center">
            {focusActivity && (
              <>
                {focusActivity.activityType !== "Image Upload" && (
                  <div className="flex justify-center items-center gap-2">
                    <h3 className="font-bold text-xl w-72">
                      <span className="block">
                        {focusActivity.customInstruction ?? "Instructions here"}
                      </span>
                    </h3>
                    {focusActivity.hasInfoModal && (
                      <InfoIcon
                        className="cursor-pointer"
                        onClick={InfoModalHandler}
                      />
                    )}
                  </div>
                )}
                {focusActivity.activityType !== "Image Upload" &&
                  focusActivity.hasInfoModal && (
                    <p className="text-sm mt-5">
                      Click on the 'i' icon to view more information.
                    </p>
                  )}
                {focusActivity.activityType === "Video URL" &&
                  focusActivity.url && (
                    <div className="my-4">
                      <iframe
                        src={`https://www.youtube.com/embed/${getVideoID(
                          focusActivity.url
                        )}`}
                        title="Youtube vid"
                        allow="accelerometer; encrypted-media; clipboard-write; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{
                          width: "auto",
                          height: "300px",
                          minWidth: "400px",
                        }}
                      ></iframe>
                    </div>
                  )}

                {focusActivity.activityType === "Image Upload" && (
                  <div className="w-full">
                    <div className="py-6 text-center flex flex-col justify-center items-center">
                      <div className="relative w-full">
                        <h3 className="font-bold text-xl mx-8">
                          <span className={`block text-[var(--main-colour)]  `}>
                            {growthCircleSession?.facilActivity}
                          </span>
                        </h3>
                        {focusActivity.hasInfoModal && (
                          <InfoIcon
                            className="cursor-pointer absolute right-0 top-0"
                            onClick={InfoModalHandler}
                          />
                        )}
                      </div>
                      {focusActivity.hasInfoModal && (
                        <p className="text-sm mt-5">
                          Click on the 'i' icon to view more information.
                        </p>
                      )}
                    </div>
                    {/* upload */}

                    <Suspense fallback={<div>Loading...</div>}>
                      <UploadImage
                        activity={focusActivity}
                        hasLightBox={hasLightBox}
                        setHasLightBox={setLightBoxHandler}
                        deleteEvent={deleteModalHandler}
                        confirmDelete={proceedDelete}
                        hasExceptionTerm={true}
                      />
                    </Suspense>

                    <EmptySpace height={"10px"} />
                  </div>
                )}

                {focusActivity.activityType === "Pdf Upload" &&
                  focusActivity.pdfUrl && (
                    <div
                      className="my-4 relative overflow-hidden"
                      id="pdfContainer"
                    >
                      <div
                        className={`${classes["container"]} flex gap-2 my-4 justify-center items-center`}
                      >
                        {focusActivity.focusPage &&
                          focusActivity.focusPage.length > 1 && (
                            <div className="flex gap-2">
                              {" "}
                              <button
                                className={`${classes["button"]}`}
                                onClick={goToPreviousPage}
                                disabled={
                                  page === parseInt(focusActivity.focusPage[0])
                                }
                              >
                                Prev
                              </button>
                              <button
                                className={`${classes["button"]}`}
                                onClick={goToNextPage}
                                disabled={
                                  page ===
                                  parseInt(
                                    focusActivity.focusPage[
                                      focusActivity.focusPage.length - 1
                                    ]
                                  )
                                }
                              >
                                Next
                              </button>
                            </div>
                          )}
                        <button
                          className={`${classes["button"]}`}
                          onClick={setShowLightHandler}
                        >
                          <BiExpand size={28} />
                        </button>
                      </div>
                      <Document
                        file={`${focusActivity.pdfUrl}#page=${page}`}
                        onLoadSuccess={() => {}}
                        loading={"Loading..."}
                      >
                        <Page
                          pageNumber={page}
                          scale={1.1}
                          loading={"Loading..."}
                        />
                      </Document>
                    </div>
                  )}
                {focusActivity &&
                  focusActivity.activityType === "Image Slider" && (
                    <div className="w-full max-w-lg mx-auto">
                      {focusActivity.sliderImages && (
                        <div className="my-4">
                          <ImageCarousel
                            images={focusActivity.sliderImages}
                            className="aspect-w-16 aspect-h-9"
                            setLightBoxHandler={setLightBoxHandler}
                            hasLightBox={hasLightBox}
                          />
                        </div>
                      )}
                    </div>
                  )}
                <div className="bg-white shadow-md rounded-lg w-full    ">
                  <p className="text-left text-base p-3">
                    {focusActivity.customReflectionPlaceholder ??
                      "What came up for you?"}
                  </p>
                  <div className="relative z-50  w-full p-2 rounded-md  ">
                    <input
                      defaultValue={ParticipantRecord[0]?.activityTitle}
                      onChange={onInputChange}
                      onBlur={onInputChange}
                      type="text"
                      name="title"
                      className={`w-full shadow-md border border-slate-400/[.4] rounded-lg  pl-7 placeholder:text-sm placeholder:text-[var(--main-colour)] mb-[20px] focus:border-slate-400/[.4]`}
                      placeholder="Answer here..."
                    />
                  </div>
                </div>

                <EmptySpace height={"40px"} />
                <BackAndNext
                  {...backAndNextHandler}
                  backCallback={() => {
                    setActivityComponent("ChooseActivityCard");
                  }}
                  nextAllowed={
                    (canAdvance ||
                      (ParticipantRecord[0]?.activityTitle !== undefined &&
                        ParticipantRecord[0]?.activityTitle !== "")) &&
                    inputValue !== "" &&
                    backAndNextHandler.nextAllowed
                  }
                />
              </>
            )}
          </div>
          <Lightbox
            isOpen={showLightBox}
            onPrev={gotoPrevious}
            onNext={gotoNext}
            images={images}
            currentIndex={activeIndex}
            renderHeader={() => (
              <div
                className={`py-4 px-1 flex gap-2 md:flex-col absolute top-0 left-0 z-1000`}
              >
                <BiPlusCircle
                  className="cursor-pointer"
                  size={40}
                  onClick={incrementZoom}
                />
                <BiMinusCircle
                  className="cursor-pointer"
                  onClick={decrementZoom}
                  size={40}
                />
                <BiUpArrowCircle
                  className="cursor-pointer"
                  size={40}
                  onClick={incrementTop}
                />
                <BiDownArrowCircle
                  className="cursor-pointer"
                  size={40}
                  onClick={decrementTop}
                />

                <BiLeftArrowCircle
                  onClick={incrementRight}
                  className="cursor-pointer"
                  size={40}
                />

                <BiRightArrowCircle
                  onClick={decrementRight}
                  className="cursor-pointer"
                  size={40}
                />

                <BiReset
                  onClick={resetHandler}
                  className="cursor-pointer"
                  size={40}
                />
                <BiXCircle
                  onClick={setShowLightHandler}
                  className="cursor-pointer"
                  size={40}
                />
              </div>
            )}
            renderImageOverlay={() =>
              focusActivity && (
                <div
                  style={{ marginTop: `${top}px`, marginRight: `${right}px` }}
                >
                  {" "}
                  <Document
                    file={`${focusActivity.pdfUrl}#page=${page}`}
                    onLoadSuccess={() => {}}
                    loading={"Loading..."}
                  >
                    <Page
                      pageNumber={page}
                      scale={zoom}
                      loading={"Loading..."}
                    />
                  </Document>
                </div>
              )
            }
            style={{ background: "grey" }}
            /* Handle closing */
            onClose={setShowLightHandler}
            /* Use single or double click to zoom */
            // singleClickToZoom

            /* react-spring config for open/close animation */
            pageTransitionConfig={{
              from: { transform: "scale(0.75)", opacity: 0 },
              enter: { transform: "scale(1)", opacity: 1 },
              leave: { transform: "scale(0.75)", opacity: 0 },
              config: { mass: 1, tension: 320, friction: 32 },
            }}
          />
        </>
      )}
    </React.Fragment>
  );
}
