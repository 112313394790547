import { lazy, Suspense, useEffect, useState } from "react";
import { useTour } from "@reactour/tour";
import EmptySpace from "components/utility/EmptySpace";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import { FeedbackLayout } from "interface/FeedbackLayoutInterface";
import classes from "pages/AllCircles/Admin/Admin.module.css";

const FeedbackQuestionForm = lazy(() => import("./FeedbackQuestionForm"));

const FeedbackQuestions = lazy(() => import("./FeedbackQuestions"));
const FeedbackQuestionsPreviews = lazy(
  () => import("./FeedbackQuestionsPreview")
);
const FeedbackInstructionsPanel = lazy(
  () => import("./FeedbackInstructionPanel")
);

interface FeedbackSettingsProps {
  feedbackLayout: FeedbackLayout[];
}

const FeedbackSettings = ({ feedbackLayout }: FeedbackSettingsProps) => {
  const { setIsOpen: setIsTourOpen } = useTour();
  const { selectedOrganisation } = useOrganisationContext();
  const [id, setId] = useState<string>("");
  const [isSorting, setIsSorting] = useState(false);

  useEffect(() => {
    if (!feedbackLayout) return;
    if (feedbackLayout[0]) {
      setId(feedbackLayout[0].id);
    }
    // eslint-disable-next-line
  }, [feedbackLayout]);

  return (
    <div className="mb-[100px]">
      {selectedOrganisation && (
        <>
          <EmptySpace />
          <button
            className={`${classes["button"]}`}
            onClick={() => setIsTourOpen(true)}
          >
            Show Tutorial
          </button>
          <EmptySpace />

          <div className="mb-8">
            <Suspense fallback={"loading..."}>
              <FeedbackInstructionsPanel />
            </Suspense>
          </div>
          <hr />
          <div className="flex gap-8 w-full">
            {/* Left Side - Form */}
            <Suspense fallback={"loading..."}>
              <FeedbackQuestionForm
                id={id}
                setId={setId}
                feedbackLayout={feedbackLayout}
              />
            </Suspense>
            {/* Center - Manage Sections */}
            <Suspense fallback={"loading..."}>
              <FeedbackQuestions
                setId={setId}
                activeQuestionId={id}
                questions={feedbackLayout}
                isSorting={isSorting}
                setIsSorting={setIsSorting}
              />
            </Suspense>

            {/* Right Side - Preview */}
            <Suspense fallback={"loading..."}>
              <FeedbackQuestionsPreviews
                setId={setId}
                activeQuestionId={id}
                questions={feedbackLayout}
              />
            </Suspense>
          </div>
        </>
      )}
    </div>
  );
};

export default FeedbackSettings;
