import { FC, MouseEvent } from "react";
import DiceComponent from "../Dice/DiceComponent";
import TriggerHelper from "components/TriggerHelper/TriggerHelper";
import RatingAverageComponent from "../RatingAverage/RatingAverageComponent";
import SettingIntentionsComponent from "../SettingIntentions/SettingIntentionsComponent";
import ChooseRolesComponent from "../ChooseRoles/ChooseRolesComponent";
import ChooseRolesHelper from "components/ChooseRolesHelper/ChooseRolesHelper";
import ViewChooseRolesComponent from "../ViewChooseRoles/ViewChooseRolesComponent";
import ChooseActivityComponent from "../ChooseActivity/ChooseActivityComponent";
import ActivityPageComponent from "../NationalGalleryPages/ActivityPageComponent";
import ActivityShareReflectionComponent from "../NationalGalleryPages/ActivityShareReflectionComponent";
import ChooseTopicComponent from "../ChooseTopic/ChooseTopicComponent";
import ViewChooseTopicComponent from "../ViewChooseTopic/ViewChooseTopicComponent";
import ChoosePathwayComponent from "../ChoosePathway/ChoosePathwayComponent";
import SharingPanelComponent from "../SharingPanel/SharingPanelComponent";
import SharingPanelHelper from "components/SharingPanelHelper/SharingPanelHelper";
import CheckOutAfterComponent from "../CheckOutFeedback/CheckOutAfterComponent";
import CheckInCheckOutAverageComponent from "../EndRatingAverage/CheckInCheckOutAverageComponent";
// import FeedbackAfterComponent from "../CheckOutFeedback/FeedbackAfterComponent";
import FeedbackOrgAfterComponent from "../CheckOutFeedback/organisation/FeedbackOrgAfterComponent";
import FeedbackAfterComponent from "../CheckOutFeedback/FeedbackAfterComponent";
import IntroOrgRatingComponent from "../IntroRating/IntroOrgRatingComponent";
import IntroRatingComponent from "../IntroRating/IntroRatingComponent";
import RatingOrgAverageComponent from "../RatingAverage/RatingOrgAverageComponent";
import CheckOrgOutAfterComponent from "../CheckOutFeedback/CheckOrgOutAfterComponent";
import CheckInCheckOutOrgAverageComponent from "../EndRatingAverage/CheckInCheckOutOrgAverageComponent";
import SettingRandomIntentionsComponent from "../SettingIntentions/SettingRandomIntentionsComponent";
import WelcomeComponent from "../Welcome/WelcomeComponent";
import EnterCodeComponent from "../EnterCode/EnterCodeComponent";
import WaitingAreaComponent from "../WaitingArea/WaitingAreaComponent";
import GroundRulesComponent from "../GroundRules/GroundRulesComponent";
import InfoPanelComponent from "../InfoPanel/InfoPanelComponent";

export interface BackAndNextProps {
  backCallback: (e: MouseEvent<HTMLButtonElement>) => any;
  nextCallback: (e: MouseEvent<HTMLButtonElement>) => any;
  backFailCallback?: (e: MouseEvent<HTMLButtonElement>) => any;
  nextFailCallback?: (e: MouseEvent<HTMLButtonElement>) => any;
  backAllowed: boolean | null | undefined;
  nextAllowed: boolean | null | undefined;
  nextChangeColour?: boolean;
  backHidden?: boolean | null | undefined;
  isFixed?: boolean | null | undefined;
  customNextText?: string;
  useAnimation?: boolean;
}

export interface ComponentProps {
  setHasModal: (b: boolean) => any; // To hide background when component has a modal opened.
  backAndNextHandler: BackAndNextProps;
}

export interface HelperProps {
  setHasModal: (b: boolean) => any;
}

export interface Component {
  headerText: string; // Title to display in FiveStepHeader.
  pageStep: number; // Step to display, must be from 1 - 5.
  Main: FC<ComponentProps>; // The main component to display for that page.
  Helper?: FC<HelperProps>; // Any helper modal when question mark in FiveStepHeader is clicked.
  type: string;
  category?: string;
}

interface ComponentMapping {
  [key: string]: Component;
}

export const defaultComponents: string[] = [
  "dice",
  "intro-rating",
  "rating-average",
  "setting-intentions",
  "choose-roles",
  "view-choose-roles",
  "choose-topic",
  "view-choose-topic",
  "choose-pathway",
  "sharing-panel",
  "end-rating",
  "intro-end-average",
  "feedback-after",
];

export const SUSSDefaultComponents: string[] = [
  "dice",
  "intro-rating",
  "rating-average",
  "setting-intentions",
  "choose-roles",
  "view-choose-roles",
  "choose-activity",
  "choose-topic",
  "view-choose-topic",
  "choose-pathway",
  "sharing-panel",
  "end-rating",
  "intro-end-average",
  "feedback-after",
];

export const NGDefaultComponents: string[] = [
  "dice",
  "intro-rating",
  "rating-average",
  "setting-intentions",
  "choose-roles",
  "view-choose-roles",
  "choose-activity",
  "activity-NG",
  "NG-activity-reflection",
  "end-rating",
  "intro-end-average",
  "feedback-after",
];

export const ComponentProperName: Object[] = [
  { dice: "IceBreaker (Choose Dice)" },
  { "intro-org-rating": "Customized Check-In" },
  { "intro-rating": "Check-in (ORS)" },
  { "rating-average": "Check-in Average (ORS)" },
  { "rating-org-average": "Customized Check-In Average" },
  { "setting-intentions": "Setting Intentions" },
  { "setting-intentions-randomize": "Randomize Setting Intentions" },
  { "choose-roles": "Choose Roles" },
  { "view-choose-roles": "View Choose Roles" },
  { "choose-activity": "Choose Activity" },
  { "activity-NG": "Activity National Gallery" },
  { "NG-activity-reflection": "Activity Reflection National Gallery" },
  { "choose-topic": "Choose Topics" },
  { "view-choose-topic": "View Choose Topics" },
  { "choose-pathway": "Choose Pathway" },
  { "sharing-panel": "Sharing Panel" },
  { "end-rating": "Check-out (ORS)" },
  { "end-org-rating": "Customized Check-out" },
  { "intro-end-average": "Check-In & Check-Out Total Average (ORS)" },
  { "intro-end-org-average": "Customized Check-In & Check-Out Total Average" },
  { "feedback-after": "Feedback After Session" },
  { "feedback-org-after": "Org Feedback After Session" },
];

export const getProperNameComponent = (component: string) => {
  const componentValue = ComponentProperName.find((obj) =>
    obj.hasOwnProperty(component)
  );

  return componentValue ? componentValue[component] : component;
};

export const getProperNameComponentHeader = (comp: string) => {
  const headerText = componentMapping[comp]?.headerText || "";

  if (
    headerText === "Reflection on Session" ||
    headerText === "Reflection on GrowthCircles"
  ) {
    return "Reflection";
  }

  return headerText;
};
export const ExpectRows = [
  {
    title: "Let's Connect",
    contents: [
      {
        imageName: "Dice",
        text: "What connects to us",
      },
    ],
  },
  {
    title: "Check-In",
    contents: [
      {
        imageName: "Heart",
        text: "How are you feeling?",
      },
    ],
  },

  {
    title: "Setting Intentions",
    contents: [
      {
        imageName: "Heart",
        text: "Setting Intentions",
      },
    ],
  },
  {
    title: "Build A Skill",
    contents: [
      {
        imageName: "Muscle",
        text: "Choosing Roles",
      },
    ],
  },
  {
    title: "Choose & Share",
    contents: [
      {
        imageName: "Folder",
        text: "Choosing Topic",
      },
      {
        imageName: "Journey",
        text: "Choosing Journey",
      },
      {
        imageName: "ChatBubble",
        text: "Sharing",
      },
    ],
  },
  {
    title: "Reflections",
    contents: [
      {
        imageName: "LightBulb",
        text: "Check-Out - How are you feeling?",
      },
      {
        imageName: "ChatBubble",
        text: "Feedback",
      },
    ],
  },
];

export const getContentByHeaderTitle = (headerTitle: string) => {
  const row = ExpectRows.find((row) => row.title === headerTitle);
  return row ? row.contents : [];
};

export const componentMapping: ComponentMapping = {
  welcome: {
    headerText: `Welcome`,
    pageStep: 1,
    Main: WelcomeComponent,
    type: "default",
  },
  "enter-code": {
    headerText: `Enter Code`,
    pageStep: 1,
    Main: EnterCodeComponent,
    type: "default",
  },
  "waiting-area": {
    headerText: `Waiting Area`,
    pageStep: 1,
    Main: WaitingAreaComponent,
    type: "default",
  },
  "info-panel": {
    headerText: `info-panel`,
    pageStep: 1,
    Main: InfoPanelComponent,
    type: "default",
  },
  "ground-rules": {
    headerText: `Group Rules`,
    pageStep: 1,
    Main: GroundRulesComponent,
    type: "special",
  },
  dice: {
    headerText: `Let's Connect`,
    pageStep: 1,
    Main: DiceComponent,
    Helper: TriggerHelper,
    type: "custom",
  },
  "intro-org-rating": {
    headerText: `Check-In`,
    pageStep: 2,
    Main: IntroOrgRatingComponent,
    type: "custom",
  },
  "intro-rating": {
    headerText: `Check-In`,
    pageStep: 2,
    Main: IntroRatingComponent,
    type: "custom",
  },
  "rating-average": {
    headerText: `Check-In`,
    pageStep: 2,
    Main: RatingAverageComponent,
    type: "custom",
  },
  "rating-org-average": {
    headerText: `Check-In`,
    pageStep: 2,
    Main: RatingOrgAverageComponent,
    type: "custom",
  },
  "setting-intentions": {
    headerText: `Setting Intentions`,
    pageStep: 2,
    Main: SettingIntentionsComponent,
    type: "custom",
  },
  "setting-intentions-randomize": {
    headerText: `Check-In`,
    pageStep: 2,
    Main: SettingRandomIntentionsComponent,
    type: "custom",
  },
  "choose-roles": {
    headerText: `Build A Skill`,
    pageStep: 3,
    Main: ChooseRolesComponent,
    Helper: ChooseRolesHelper,
    type: "custom",
  },
  "view-choose-roles": {
    headerText: `Build A Skill`,
    pageStep: 3,
    Main: ViewChooseRolesComponent,
    type: "custom",
  },
  "choose-activity": {
    headerText: "Choose & Share",
    pageStep: 4,
    Main: ChooseActivityComponent,
    type: "custom",
  },
  "activity-NG": {
    headerText: "Choose & Share",
    pageStep: 4,
    Main: ActivityPageComponent,
    type: "custom",
  },
  "NG-activity-reflection": {
    headerText: "Choose & Share",
    pageStep: 4,
    Main: ActivityShareReflectionComponent,
    type: "custom",
  },
  "choose-topic": {
    headerText: "Choose & Share",
    pageStep: 4,
    Main: ChooseTopicComponent,
    type: "custom",
  },
  "view-choose-topic": {
    headerText: "Choose & Share",
    pageStep: 4,
    Main: ViewChooseTopicComponent,
    type: "custom",
  },
  "choose-pathway": {
    headerText: "Choose & Share",
    pageStep: 4,
    Main: ChoosePathwayComponent,
    type: "custom",
  },
  "sharing-panel": {
    headerText: "Choose & Share",
    pageStep: 4,
    Main: SharingPanelComponent,
    Helper: SharingPanelHelper,
    type: "custom",
  },
  "end-rating": {
    headerText: "Reflections",
    pageStep: 5,
    Main: CheckOutAfterComponent,
    type: "custom",
  },
  "end-org-rating": {
    headerText: "Reflections",
    pageStep: 5,
    Main: CheckOrgOutAfterComponent,
    type: "custom",
  },
  "intro-end-average": {
    headerText: "Reflections",
    pageStep: 5,
    Main: CheckInCheckOutAverageComponent,
    type: "custom",
  },
  "intro-end-org-average": {
    headerText: "Reflections",
    pageStep: 5,
    Main: CheckInCheckOutOrgAverageComponent,
    type: "custom",
  },
  "feedback-after": {
    headerText: "Reflections",
    pageStep: 5,
    Main: FeedbackAfterComponent,
    type: "custom",
  },

  "feedback-org-after": {
    headerText: "Reflections",
    pageStep: 5,
    Main: FeedbackOrgAfterComponent,
    type: "custom",
  },
};

/**
 * Returns true when component provided is in the componentMapping object.
 * @param component
 * @returns boolean
 */
export function isValidComponent(component: string): boolean {
  return componentMapping.hasOwnProperty(component) ? true : false;
}

/*Base: dice, intro rating, rating average, setting intensions, choose roles, view choose roles, 
choose-topic, view choose topic, choose-pathway, sharing panel, check in after, checkincheckoutaverage,
check out feedback for self, checkout feedback for facil, checkout feedback for group */

/*SUSS: dice, intro rating, rating average, setting intensions, choose roles, view choose roles, 
choose-activity-suss, choose-topic, viewchoose topic, choose-pathway, sharing panel, check in after, checkincheckoutaverage,
check out feedback for self, checkout feedback for facil, checkout feedback for group */

/*NG: dice, intro rating, rating average, setting intensions, choose roles, view choose roles, 
choose-activity, activity, activity share reflection, check in after,checkincheckoutaverage,
check out feedback for self, checkout feedback for facil, checkout feedback for group */
