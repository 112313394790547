import SliderCustom from "components/SliderCustom/SliderCustom";
import { Dispatch, SetStateAction } from "react";

type Props = {
  label: string;
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
  isDisabled?: boolean;
  maxRating?: number;
  isEditing?: boolean;
};

export default function SliderQuestion({
  label,
  value,
  setValue,
  isDisabled,
  maxRating,
  isEditing,
}: Props) {
  return (
    <div className="pb-12 flex flex-col items-stretch">
      <p className="text-slate-600 font-[700] px-4 select-none text-center pb-2">
        {label}
      </p>
      <SliderCustom
        maxValue={maxRating}
        value={value}
        setValue={isDisabled ? () => {} : setValue}
        isDisabled={isDisabled}
        editable={isEditing}
      />
    </div>
  );
}
