import React, { useContext, useEffect } from "react";
import classes from "./ReflectionQuestions.module.css";
import InnerContainer from "../Container/InnerContainer";
import usePath from "../../../hooks/usePath";
import {
  REFLECTION_QUESTION_TITLE,
  REFLECTION_COMPULSORY_QUESTIONS,
} from "strings/SharingPanel";
import EmptySpace from "components/utility/EmptySpace";
import ViewJournalContext from "../ViewJournalContext";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import { useOrganisationQuestion } from "hooks/useOrganisationQuestion";
import SimpleLoader from "components/Loaders/SimpleLoader";
import Organisation from "interface/OrganisationInterface";
import DisplayQuestions from "./DisplayQuestions";

interface Props {
  path: string;
  generalReflectionQuestion: string[];
  personalReflectionQuestion: string[];
  compulsoryReflectionQuestion: string[];
  updateCompulsoryReflectionQuestion?: (question: string[]) => Promise<void>;
  updatePersonalReflectionQuestion?: (question: string[]) => Promise<void>;
  updateGeneralReflectionQuestion?: (question: string[]) => Promise<void>;
  topic: string;
  generalTopic?: string;
  organisation?: Organisation | undefined;
}

const ReflectionViewQuestions: React.FC<Props> = ({
  path,
  generalReflectionQuestion,
  personalReflectionQuestion,
  compulsoryReflectionQuestion,
  updateCompulsoryReflectionQuestion,
  updatePersonalReflectionQuestion,
  updateGeneralReflectionQuestion,
  topic,
  generalTopic,
  organisation,
}) => {
  const { userJournal } = useContext(ViewJournalContext);
  const { personalQuestion, generalQuestion } = usePath(path, generalTopic!);
  const tabNumber = "first";
  const place =
    userJournal && !userJournal.reflection && !userJournal.notes
      ? "top-and-bottom"
      : "top";
  const { growthCircleSession } = useGrowthCircleContext();
  const { formattedCompulsoryQuestions } = useOrganisationQuestion();

  useEffect(() => {
    if (!generalReflectionQuestion) return; // if question not loaded
    if (!personalReflectionQuestion) return; // if question not loaded
    if (!compulsoryReflectionQuestion) return;
    if (!personalQuestion) return; // if question not loaded
    if (!generalQuestion) return; // if question not loaded
    if (!formattedCompulsoryQuestions) return;
    if (personalReflectionQuestion.length === 0)
      if (updatePersonalReflectionQuestion !== undefined)
        updatePersonalReflectionQuestion(personalQuestion);
    if (generalReflectionQuestion.length === 0)
      if (updateGeneralReflectionQuestion !== undefined)
        updateGeneralReflectionQuestion(generalQuestion);
    // if (compulsoryReflectionQuestion.length === 0)
    //   if (updateCompulsoryReflectionQuestion !== undefined)
    //     updateCompulsoryReflectionQuestion(formattedCompulsoryQuestions);

    // console.log(userJournal);
    // console.log("gen topic", generalTopic);
    // console.log("topic", personalQuestion, generalQuestion);
    // console.log(growthCircleSession);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    personalQuestion,
    generalQuestion,

    topic,
    generalTopic,
    personalReflectionQuestion,
    generalReflectionQuestion,
    compulsoryReflectionQuestion,
    growthCircleSession,
  ]);

  return (
    <InnerContainer tab={tabNumber} place={place}>
      {userJournal ? (
        <>
          {organisation?.questionsType === "specific" && (
            <strong className={`my-4  text-center break-words w-full text-xl`}>
              On topic of {topic}
            </strong>
          )}
          {organisation?.questionsType === "specific" && (
            <>
              <DisplayQuestions
                Journal={userJournal}
                questionType="general"
                topic={topic}
              />
            </>
          )}
          {organisation?.questionsType === "random" && (
            <>
              <strong className={classes["title"]}>
                General Questions for {topic}
              </strong>
              <DisplayQuestions
                questionType="general"
                Journal={userJournal}
                topic={topic}
              />
              <EmptySpace />
              <>
                {personalReflectionQuestion.length > 0 && (
                  <strong className={classes["title"]}>
                    Personal Questions for {topic}
                  </strong>
                )}
                <DisplayQuestions
                  Journal={userJournal}
                  questionType="personal"
                  topic={topic}
                />
              </>{" "}
            </>
          )}
          <EmptySpace />
          {userJournal.activity !== "Growth Circle 2 (GC2)" &&
            userJournal.activity !== "Growth Circle 3 (GC3)" && (
              <strong className={classes["title"]}>
                Personal {REFLECTION_QUESTION_TITLE}
              </strong>
            )}
          <ol className={`${classes["list-marker"]} px-4 list-decimal`}>
            {personalReflectionQuestion &&
              personalReflectionQuestion.length > 0 &&
              userJournal.activity !== "Growth Circle 2 (GC2)" &&
              userJournal.activity !== "Growth Circle 3 (GC3)" &&
              Array.isArray(personalReflectionQuestion) &&
              personalReflectionQuestion.map((line) => {
                if (line) {
                  const replacedLine = line.replace(
                    /<topic>/g,
                    `<strong>${topic}</strong>`
                  );
                  return (
                    <li
                      key={line}
                      className={`${classes["question"]}`}
                      dangerouslySetInnerHTML={{ __html: replacedLine }}
                    ></li>
                  );
                } else {
                  return null;
                }
              })}
          </ol>
        </>
      ) : (
        <>
          {growthCircleSession &&
            growthCircleSession?.Activity &&
            growthCircleSession?.Activity[0] !== "Growth Circle 2 (GC2)" &&
            growthCircleSession &&
            growthCircleSession?.Activity &&
            growthCircleSession?.Activity[0] !== "Growth Circle 3 (GC3)" && (
              <strong className={classes["title"]}>
                Personal {REFLECTION_QUESTION_TITLE}
              </strong>
            )}
          <ol className={`${classes["list-marker"]} px-4 list-decimal`}>
            {personalReflectionQuestion.length > 0 &&
              growthCircleSession &&
              growthCircleSession?.Activity &&
              growthCircleSession?.Activity[0] !== "Growth Circle 2 (GC2)" &&
              growthCircleSession &&
              growthCircleSession?.Activity &&
              growthCircleSession?.Activity[0] !== "Growth Circle 3 (GC3)" &&
              personalReflectionQuestion.map((line) => {
                if (line) {
                  const replacedLine = line.replace(
                    /<topic>/g,
                    `<strong>${topic}</strong>`
                  );
                  return (
                    <li
                      key={line}
                      className={`${classes["question"]}`}
                      dangerouslySetInnerHTML={{ __html: replacedLine }}
                    ></li>
                  );
                } else {
                  return null;
                }
              })}
            {personalReflectionQuestion.length === 0 && (
              <div className="w-full flex justify-center items-center h-24">
                <SimpleLoader />
              </div>
            )}
          </ol>
        </>
      )}
      <EmptySpace />
      <>
        {compulsoryReflectionQuestion &&
          compulsoryReflectionQuestion.length > 0 && (
            <strong className={classes["title"]}>
              Compulsory Questions for {topic}
            </strong>
          )}
        <ol className={`${classes["list-marker"]} px-4 list-decimal`}>
          {compulsoryReflectionQuestion &&
            compulsoryReflectionQuestion.length > 0 &&
            compulsoryReflectionQuestion.map((line) => {
              if (line) {
                const replacedLine = line.replace(
                  /<topic>/g,
                  `<strong>${topic}</strong>`
                );
                return (
                  <li
                    key={line}
                    className={`${classes["question"]}`}
                    dangerouslySetInnerHTML={{ __html: replacedLine }}
                  ></li>
                );
              } else {
                return null;
              }
            })}
        </ol>
      </>{" "}
      <EmptySpace />
      <strong className={classes["title"]}>Follow-up Questions</strong>
      {REFLECTION_COMPULSORY_QUESTIONS.map((line) => {
        return (
          <p key={line} className={classes["question"]}>
            {line}
          </p>
        );
      })}
    </InnerContainer>
  );
};

export default ReflectionViewQuestions;
