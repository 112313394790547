import { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Lightbox, { ImagesListType } from "react-spring-lightbox";
import { FaTimes } from "react-icons/fa";

interface ImageCarouselProps {
  images: string[];
  hasLightBox?: boolean;
  setLightBoxHandler?: () => void;
  className?: string;
  currentIndex?: number;
  setCurrentIndex?: (index: number) => void;
}

const ImageCarousel = ({
  images,
  className = "",
  setLightBoxHandler,
  hasLightBox,
  currentIndex,
  setCurrentIndex,
}: ImageCarouselProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    setIsOpen(hasLightBox || false);
  }, [hasLightBox]);

  useEffect(() => {
    if (currentImageIndex >= images.length) {
      const newIndex = Math.max(0, images.length - 1);
      setCurrentImageIndex(newIndex);
      setCurrentIndex?.(newIndex);
    }
  }, [images, currentImageIndex, setCurrentIndex]);

  const handleImageClick = () => {
    setLightBoxHandler?.();
    setIsOpen(true);
  };

  const handleClose = () => {
    setLightBoxHandler?.();
    setIsOpen(false);
  };

  const gotoPrevious = () => setCurrentImageIndex(currentImageIndex - 1);
  const gotoNext = () => setCurrentImageIndex(currentImageIndex + 1);

  if (!images.length) return null;

  const imagesSet: ImagesListType = images.map((url) => ({
    src: url,
    loading: "lazy",
    alt: "Uploaded image",
  }));

  return (
    <>
      <div className={className}>
        <Carousel
          showArrows={true}
          showStatus={false}
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={false}
          className="rounded-lg overflow-hidden cursor-pointer"
          onChange={(index) => {
            setCurrentImageIndex(index);
            setCurrentIndex?.(index);
          }}
          onClickItem={handleImageClick}
          selectedItem={currentImageIndex}
        >
          {images.map((image, index) => (
            <div key={index} className="aspect-w-16 aspect-h-9">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </Carousel>
      </div>

      <Lightbox
        isOpen={isOpen}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        onClose={handleClose}
        images={imagesSet}
        currentIndex={currentImageIndex}
        renderHeader={() => (
          <button
            className="absolute top-4 right-4 p-2 text-white hover:text-gray-300 z-10"
            onClick={handleClose}
          >
            <FaTimes size={24} />
          </button>
        )}
        className="bg-black/90"
      />
    </>
  );
};

export default ImageCarousel;
