import {
  getDoc,
  getDocs,
  writeBatch,
  doc,
  collection,
  WriteBatch,
  QuerySnapshot,
  DocumentData,
  Firestore,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { app } from "../../firebase/config"; // Adjust based on your project structure
import { CopyOptions } from "components/Organisation/GeneralSettings/CopyOrgComponent";
import { setDefaultRoles } from "models/organisationRole";

const FIRESTORE_PATH_ORGANISATIONS = "organisations";
const MAX_BATCH_SIZE = 500;

async function getSubcollectionNames(
  firestore: Firestore,
  orgId: string,
  copyOptions: CopyOptions
): Promise<string[]> {
  // Map CopyOptions to subcollection names
  const subcollectionMap = {
    questions: "questions",
    topics: "topics",
    customScale: "ORS",
    activities: "activities",
    feedbackQuestions: "feedbackQuestions",
    roles: "roles",
    intentions: "intentions",
  };

  // Filter subcollections based on copyOptions
  const selectedSubcollections = Object.entries(copyOptions)
    .filter(([_, isSelected]) => isSelected)
    .map(
      ([option]) => subcollectionMap[option as keyof typeof subcollectionMap]
    )
    .filter(Boolean);

  const existingSubcollections: string[] = [];

  // Only check for subcollections that were selected in copyOptions
  for (const subcoll of selectedSubcollections) {
    const subcollRef = collection(
      firestore,
      FIRESTORE_PATH_ORGANISATIONS,
      orgId,
      subcoll
    );
    const snapshot = await getDocs(subcollRef);
    if (!snapshot.empty) {
      existingSubcollections.push(subcoll);
    }
  }
  return existingSubcollections;
}

/**
 * Duplicates an organization document along with its subcollections.
 * @param newName - The name for the new organization.
 * @param orgId - The ID of the existing organization that is being copied.
 * @param addedBy - The email of the user who created the copy.
 * @returns The new organization ID.
 */
export const copyOrganisation = async (
  newName: string,
  originalOrgId: string,
  userEmail: string,
  options: CopyOptions,
  onProgress?: (step: string) => void
) => {
  const firestore: Firestore = getFirestore(app);

  onProgress?.("init");

  async function copyDocumentRecursively(
    sourceDocPath: string,
    targetDocPath: string,
    batch: WriteBatch,
    newName?: string,
    newOrgId?: string
  ) {
    if (!newOrgId) {
      throw new Error("newOrgId is required and cannot be undefined.");
    }

    const sourceDocRef = doc(firestore, sourceDocPath);
    const sourceSnap = await getDoc(sourceDocRef);
    if (!sourceSnap.exists()) return;

    // Copy document data, update the name, and replace `createdAt`
    const data = sourceSnap.data();
    if (newName) {
      data.name = newName;
    }
    delete data.createdAt;
    data.createdAt = serverTimestamp();
    delete data.addedBy;
    data.addedBy = userEmail;
    data.id = newOrgId;
    delete data.users;
    data.users = {};

    batch.set(doc(firestore, targetDocPath), data);

    // Get selected subcollections based on copyOptions
    const subcollections = await getSubcollectionNames(
      firestore,
      originalOrgId,
      options
    );

    // Check if "roles" is missing and call setDefaultRoles if needed
    if (!subcollections.includes("roles")) {
      await setDefaultRoles(newOrgId);
    }

    const batchPromises: Promise<void>[] = [];

    for (const subcoll of subcollections) {
      // Update progress based on subcollection type
      switch (subcoll) {
        case "questions":
          onProgress?.("questions");
          break;
        case "topics":
          onProgress?.("topics");
          break;
        case "ORS":
          onProgress?.("scale");
          break;
        case "activities":
          onProgress?.("activities");
          break;
        case "feedbackQuestions":
          onProgress?.("feedback");
          break;
        case "roles":
          onProgress?.("roles");
          break;
        case "intentions":
          onProgress?.("intentions");
          break;
      }

      const sourceSubcollRef = collection(firestore, sourceDocPath, subcoll);
      const docsSnap = await getDocs(sourceSubcollRef);

      if (docsSnap.size > MAX_BATCH_SIZE) {
        batchPromises.push(
          processBatchedDocs(docsSnap, subcoll, targetDocPath, newOrgId)
        );
      } else {
        docsSnap.forEach((docSnap) => {
          const docData = docSnap.data();
          if (docData.organisationId) {
            docData.organisationId = newOrgId;
          }
          if (docData.documentId) {
            docData.documentId = docSnap.id;
          }
          const targetSubDocRef = doc(
            firestore,
            targetDocPath,
            subcoll,
            docSnap.id
          );
          batch.set(targetSubDocRef, docData);
        });
      }
    }

    await Promise.all(batchPromises);
  }

  // Helper function to process large collections in batches
  async function processBatchedDocs(
    docsSnap: QuerySnapshot<DocumentData>,
    subcollName: string,
    targetDocPath: string,
    newOrgId: string
  ) {
    if (!newOrgId) {
      throw new Error("newOrgId is required and cannot be undefined.");
    }

    const chunks: Promise<void>[] = [];
    const docsArray = docsSnap.docs;

    for (let i = 0; i < docsArray.length; i += MAX_BATCH_SIZE) {
      const chunk = docsArray.slice(i, i + MAX_BATCH_SIZE);
      const batch = writeBatch(firestore);

      chunk.forEach((docSnap) => {
        const docData = { ...docSnap.data() };

        // Replace organisationId field with the new organization ID
        docData.organisationId = newOrgId;

        // Generate a new document reference (this ensures a unique ID)
        const targetDocRef = doc(
          collection(firestore, targetDocPath, subcollName)
        );

        // Store the new document ID inside the document itself
        docData.id = targetDocRef.id;

        batch.set(targetDocRef, docData);
      });

      chunks.push(batch.commit());
    }

    await Promise.all(chunks);
  }

  try {
    // Create new organization document
    const sourceOrgPath = `${FIRESTORE_PATH_ORGANISATIONS}/${originalOrgId}`;
    const sourceOrgRef = doc(firestore, sourceOrgPath);
    const sourceOrgSnap = await getDoc(sourceOrgRef);

    if (!sourceOrgSnap.exists()) {
      throw new Error("Source organization not found");
    }

    // Generate a new document for the copied organization
    const newOrgRef = doc(collection(firestore, FIRESTORE_PATH_ORGANISATIONS));
    const newOrgId = newOrgRef.id; // This is the new organization ID

    if (!newOrgId) {
      throw new Error("New organization ID could not be generated.");
    }

    const batch = writeBatch(firestore);

    // Copy main organization document and update name
    await copyDocumentRecursively(
      sourceOrgPath,
      newOrgRef.path,
      batch,
      newName,
      newOrgId
    );

    // Commit the batch
    await batch.commit();

    // Signal completion
    onProgress?.("finish");

    return newOrgId;
  } catch (error) {
    console.error("Error duplicating organization:", error);
    throw error;
  }
};

export default copyOrganisation;
