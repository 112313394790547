import classes from "./EntryCards.module.css";

interface Props {
  randomize?: boolean;
  userName: string;
  intention: string;
  intentionQuestion?: string;
}

const EntryCardRandom: React.FC<Props> = ({
  randomize,
  userName,
  intention,
  intentionQuestion,
}) => {
  if (!intention) return null;

  // Match and split text by numbers followed by a period (e.g., "1.", "2.", etc.)
  const steps = (intentionQuestion &&
    intentionQuestion
      .match(/\d+\.\s*([^0-9]+)/g)
      ?.map((step) => step.replace(/^\d+\.\s*/, "").trim())) || [
    intentionQuestion,
  ];

  return (
    <>
      <div className={classes.content}>
        <div className={`${classes.header} text-xl font-semibold`}>
          {userName}
        </div>
        <div className={classes.body}>
          {randomize && intentionQuestion && (
            <>
              <div className="mb-4">
                <span className="font-semibold ">Q : </span>
                {steps.length > 1 ? (
                  <ol className="list-decimal pl-6">
                    {steps.map((step, index) => (
                      <li key={index} className="mb-2  text-left">
                        {step}
                      </li>
                    ))}
                  </ol>
                ) : (
                  <span className="font-semibold">{intentionQuestion}</span>
                )}
              </div>
              <p>
                <span className="font-semibold ">A : </span> {intention}
              </p>
            </>
          )}
          {!randomize && <p>{intention}</p>}
        </div>
      </div>
    </>
  );
};

export default EntryCardRandom;
