import SimpleLoader from "components/Loaders/SimpleLoader";
import AutoResizeTextArea from "components/utility/Inputs/AutoResizeTextArea";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Button, Modal, Tooltip } from "flowbite-react";
import { useOrganisationTopics } from "hooks/useOrganisationTopics";
import usePromise from "hooks/utility/usePromise";
import OrganisationTopics from "interface/OrganisationTopicsInterface";
import classes from "pages/AllCircles/Admin/Admin.module.css";
import { useCallback, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toast } from "react-hot-toast";
import { AiFillCloseCircle } from "react-icons/ai";
import {
  FaEdit,
  FaPlusCircle,
  FaSave,
  FaSpinner,
  FaTrash,
} from "react-icons/fa";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { MdSettings } from "react-icons/md";
import TopicItem from "./TopicItem";

interface TopicCategoryProps {
  topics: OrganisationTopics;
}

const TopicCategoryCard = ({ topics }: TopicCategoryProps) => {
  const {
    DeleteCategory,
    AddNewTopic,
    DeleteTopic,
    updateTopicName,
    updateTopicSettings,
  } = useOrganisationTopics();
  const [isVisible, setIsVisible] = useState(false);
  const [addShow, setAddShow] = useState(false);
  const [settingsShow, setSettingsShow] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editableContent, setEditableContent] = useState(topics.category);
  const [checkboxState, setCheckboxState] = useState({
    hasInfo: topics.hasInfo ?? false,
  });

  const [topicTitles, setTopicTitles] = useState("");
  const { isLoading, resolve } = usePromise();
  const { isLoading: isDeleting, resolve: deleteResolve } = usePromise();
  const { isLoading: isUpdating, resolve: updatingResolve } = usePromise();
  const [saveEnabled, setSaveEnabled] = useState(true);
  const [content, setContent] = useState(topics.InfoContent ?? "");

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const saveContent = useCallback((content) => {
    window.localStorage.setItem(
      "InFoContent",
      JSON.stringify(convertToRaw(content))
    );
    setContent(JSON.stringify(convertToRaw(content)));
  }, []);

  useEffect(() => {
    if (content)
      setEditorState(
        EditorState.createWithContent(convertFromRaw(JSON.parse(content)))
      );
    // eslint-disable-next-line
  }, [topics]);

  const updateTextDescription = useCallback(
    async (state) => {
      const contentState = state.getCurrentContent();
      saveContent(contentState);
      setEditorState(state);
      // const data = convertToRaw(editorState.getCurrentContent());
    },
    [saveContent]
  );

  const modalHandler = useCallback(async () => {
    setIsVisible(!isVisible);
  }, [isVisible]);

  const deleteHandler = useCallback(
    async (id: string) => {
      deleteResolve(() => DeleteCategory(id)).then(() => {
        modalHandler();
        toast.success("Topic category was deleted successfully");
      });
    },
    [DeleteCategory, deleteResolve, modalHandler]
  );

  const AddTopicHandler = useCallback(async () => {
    resolve(() => AddNewTopic(topicTitles, topics.id)).then(() => {
      toast.success("New topic(s) was added successfully");
      setAddShow(!addShow);
    });
  }, [AddNewTopic, addShow, resolve, topicTitles, topics]);

  const activateEditable = useCallback(async () => {
    setIsEditable(!isEditable);
  }, [isEditable]);

  const saveTopicName = useCallback(async () => {
    if (editableContent !== "") {
      updatingResolve(() =>
        updateTopicName(topics.id, editableContent).then(() => {
          setIsEditable(!isEditable);
        })
      ).then(() => {
        toast.success("Category was updated successfully.");
      });
    } else {
      toast.error("Category name cannot be empty");
    }
    // eslint-disable-next-line
  }, [editableContent, isEditable, topics, updateTopicName]);

  const handleContentChange = useCallback(
    (event: React.FormEvent<HTMLSpanElement>) => {
      const newContent = event.currentTarget.innerText || "";
      if (newContent !== "") {
        setSaveEnabled(true);
      } else {
        setSaveEnabled(false);
      }
      setEditableContent(newContent);
    },
    []
  );

  const settingsModalHandler = useCallback(() => {
    setSettingsShow(!settingsShow);
  }, [settingsShow]);

  const handleCheckboxChange = useCallback(
    async (checkboxName: string) => {
      const updatedCheckboxState = {
        ...checkboxState,
        [checkboxName]: !checkboxState[checkboxName],
      };

      setCheckboxState(updatedCheckboxState);
    },
    [checkboxState]
  );

  const saveTopicSettingsHandler = useCallback(
    async (topicId: string) => {
      const content = window.localStorage.getItem("InFoContent");
      await updateTopicSettings(checkboxState, content ?? "", topicId).then(
        () => {
          setSettingsShow(!settingsShow);
          toast.success("Topic settings was updated successfully.");
        }
      );
    },
    [checkboxState, settingsShow, updateTopicSettings]
  );

  const uploadCallback = useCallback(async () => {}, []);

  const config = {
    image: { uploadCallback: uploadCallback },
  };

  return (
    <>
      <Modal
        show={settingsShow}
        size="3xl"
        popup
        onClose={() => setSettingsShow(!settingsShow)}
      >
        <Modal.Header />
        <Modal.Body className="min-h-[60vh] relative">
          <div className="text-center flex flex-col gap-4">
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              <strong>{topics.category}</strong> Settings
            </h3>
            <label className="cursor-pointer text-slate-600 text-lg">
              <input
                type="checkbox"
                checked={checkboxState["hasInfo"]}
                onChange={() => handleCheckboxChange("hasInfo")}
                className="mx-4"
                style={{
                  height: "20px",
                  width: "20px",
                  borderRadius: "5px",
                  backgroundColor: checkboxState["hasInfo"]
                    ? "var(--icon-colour-0)"
                    : "",
                }}
              />
              Show "i" icon and display information modal
            </label>
            {checkboxState["hasInfo"] && (
              <Editor
                toolbar={config}
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="border border-gray-300"
                editorClassName="p-2"
                onEditorStateChange={updateTextDescription}
              />
            )}
          </div>
          <div
            className={`${classes["container"]} float-right flex justify-center gap-4 mt-8`}
          >
            <button
              color="success"
              onClick={() => saveTopicSettingsHandler(topics.id)}
              className={`flex items-center gap-2 ${classes["button"]}`}
            >
              <FaSave size={22} />
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={isVisible}
        size="xl"
        popup
        onClose={() => setIsVisible(!isVisible)}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you confirming your intention to delete the "
              <strong>{topics.category}</strong>" category? This action will
              result in the removal of all associated topics beneath it, and it
              will also eliminate the category's presence from the list of
              assigned topics in various activities.
            </h3>
            {!isDeleting ? (
              <div className="flex justify-center gap-4">
                <Button
                  color="failure"
                  onClick={() => deleteHandler(topics.id)}
                >
                  Yes, I'm sure
                </Button>
                <Button color="gray" onClick={modalHandler}>
                  No, cancel
                </Button>
              </div>
            ) : (
              <div className="flex justify-center gap-4">
                <SimpleLoader />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={addShow} size="3xl" onClose={() => setAddShow(!addShow)}>
        <Modal.Body
          className="border-2 rounded-lg bg-default relative"
          style={{ borderColor: "var(--icon-colour-0)" }}
        >
          <AiFillCloseCircle
            size={30}
            className="absolute top-2 right-2 cursor-pointer"
            onClick={() => setAddShow(!addShow)}
            style={{ color: "var(--icon-colour-0)" }}
          />
          <div className="text-center">
            <p className="py-4 text-slate-600 text-lg">
              To introduce a new topic within the category labeled{" "}
              <strong>{topics.category}</strong>, kindly provide the topic name
              in the input field and then press the "Add" button. If necessary,
              you can also insert several topic titles by separating them with
              commas.
            </p>
            <div className="flex flex-col gap-2 justify-center items-center">
              <div className="p-2 shadow-lg bg-white rounded-lg my-4">
                {" "}
                <AutoResizeTextArea
                  className="w-full border-none"
                  onChange={(value) => setTopicTitles(value)}
                  placeholder={"topic 1, topic 2, topic 3"}
                />
              </div>

              <button
                disabled={isLoading}
                onClick={AddTopicHandler}
                className="bg-red-400 text-white py-4 text-lg uppercase rounded-full shadow-md w-1/3 "
              >
                {isLoading ? "Submitting..." : "ADD"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="border border-gray-200 bg-white min-h-[200px] shadow-md">
        <div className="w-full border border-b-slate-400/70 flex items-center justify-between">
          <h2
            className={`text-slate-600 text-xl px-2 py-3 ${
              isEditable ? "border-2 border-green-600" : ""
            }`}
          >
            <span
              className={`${isEditable ? "px-4" : ""}`}
              suppressContentEditableWarning={true}
              contentEditable={isEditable}
              onInput={handleContentChange}
              onBlur={handleContentChange}
            >
              {topics.category}
            </span>
          </h2>
          <div className=" flex gap-4 flex-row-reverse px-2">
            <Tooltip className="capitalize" content={"Delete Category"}>
              <FaTrash
                className=" text-red-400 cursor-pointer"
                size={24}
                onClick={modalHandler}
              />
            </Tooltip>
            <Tooltip className="capitalize" content={"View category settings"}>
              <MdSettings
                className=" text-blue-400 cursor-pointer"
                size={24}
                onClick={settingsModalHandler}
              />
            </Tooltip>
            {!isEditable && (
              <Tooltip className="capitalize" content={"Edit category name"}>
                <FaEdit
                  onClick={activateEditable}
                  size={24}
                  className="text-blue-400 cursor-pointer"
                />
              </Tooltip>
            )}

            {isEditable && (
              <Tooltip className="capitalize" content={"Save"}>
                {!isUpdating ? (
                  <FaSave
                    onClick={
                      saveEnabled
                        ? saveTopicName
                        : () => {
                            toast.error("Category name cannot be empty");
                          }
                    }
                    size={24}
                    className={` ${
                      saveEnabled
                        ? "cursor-pointer text-blue-400"
                        : "cursor-none text-gray-500"
                    }`}
                  />
                ) : (
                  <FaSpinner size={24} className="animate-spin" />
                )}
              </Tooltip>
            )}
          </div>
        </div>
        <div className="relative px-4 py-4 flex flex-wrap gap-2 w-full item-stretch">
          <div className="absolute top-2 right-2">
            {" "}
            <Tooltip
              className="capitalize w-[100px] text-center"
              content={"Add new topic(s)"}
            >
              <FaPlusCircle
                onClick={() => setAddShow(!addShow)}
                className=" text-green-400 cursor-pointer"
                size={24}
              />
            </Tooltip>
          </div>

          {topics.general.length === 0 && (
            <p className="py-4 text-slate-500">No topics available.</p>
          )}
          {topics["general"]
            .sort((a, b) => a.localeCompare(b))
            .map((topic, index: number) => (
              <TopicItem
                key={index}
                topic={topic}
                categoryId={topics.id}
                deleteFunc={DeleteTopic}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default TopicCategoryCard;
