import EmptySpace from "components/utility/EmptySpace";

import Tab from "components/utility/Tab/Tab";
import TabItem from "components/utility/Tab/TabItem";
import GroupsSettings from "components/Organisation/GroupsSettings/GroupsSettings";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import usePromise from "hooks/utility/usePromise";
import { FormEvent, Suspense, lazy, useCallback, useEffect } from "react";
import classes from "../../pages/AllCircles/Admin/Admin.module.css";
import { OrganisationGroupContextProvider } from "context/OrganisationGroupContext";
import { setDefaultComponents } from "models/organisation";
import ComponentSettings from "./ComponentSettings/ComponentSettings";
import { FiRefreshCw } from "react-icons/fi";
import { toast } from "react-hot-toast";
import TopicSettings from "./TopicSettings/TopicSettings";

import ResourcesSettings from "./ResourcesSettings/ResourcesSettings";
import SimpleLoader from "components/Loaders/SimpleLoader";
import { useOrganisationActivityPage } from "hooks/useOrganisationActivityPage";
import { useOrganisationTopics } from "hooks/useOrganisationTopics";
import { useResourcesOrganisation } from "hooks/useResourcesOrganisation";
import QuestionSettings from "./QuestionSettings/QuestionSettings";
import { useOrganisationQuestion } from "hooks/useOrganisationQuestion";
import FeedbackSettings from "./FeedbackSettings/FeedbackSettings";
import { useOrgFeedbackQuestions } from "hooks/organisation/useOrgFeedbackQuestions";
import ORSSettings from "./ORSSettings/ORSSettings";
import { useOrgORS } from "hooks/organisation/useOrgORS";
import IntentionSettings from "./IntentionSettings/IntentionSettings";
import { useOrganisationIntentions } from "hooks/useOrganisationIntentions";
import { useGrowthCirclesType } from "hooks/useGrowthCirlesType";
import { Link } from "react-router-dom";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import GrowthCircleTypeGeneralSettings from "./GeneralSettings/GrowthCircleTypeGeneralSettings";
import DisabledSection from "components/Disabled/DisabledSection";

const ActivityPageSettings = lazy(
  () => import("./ActivitySettings/ActivitySettings")
);

/**
 * Component to update organisation's settings.
 *
 * @returns GrowthCircleTypeDetails component.
 */

interface GrowthCircleTypeDetailsProps {
  id: string;
}

export default function GrowthCircleTypeDetails({
  id,
}: GrowthCircleTypeDetailsProps) {
  const { organisations, selectedOrganisation, selectOrganisationByName } =
    useOrganisationContext();
  const { getGrowthCircleTypeById } = useGrowthCirclesType();
  const { resolve, isLoading } = usePromise();
  const { activities } = useOrganisationActivityPage();
  const { topics } = useOrganisationTopics();
  const { resources } = useResourcesOrganisation();
  const { questions, categoryQuestions } = useOrganisationQuestion();
  const { feedbackLayout } = useOrgFeedbackQuestions();
  const { ors } = useOrgORS();
  const { intentions } = useOrganisationIntentions();

  /**
   * Pad all organisation roles with default components if they are missing.
   */
  const handleRefreshComponents = useCallback(
    (e: FormEvent<HTMLButtonElement>) => {
      e.preventDefault();

      resolve(() => {
        return Promise.all(
          organisations.map((organisation) =>
            setDefaultComponents(organisation.id)
          )
        ).then(() => {
          toast.success("Components refreshed");
        });
      });
    },
    // eslint-disable-next-line
    [organisations, resolve]
  );

  useEffect(() => {
    if (!id) return;
    const org = getGrowthCircleTypeById(id);
    org.then((org) => {
      if (org) {
        selectOrganisationByName(org.name);
      }
    });
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      <EmptySpace height="32px" />
      <div className="flex gap-2 items-start">
        <Link
          to={`/all-circles/admin/growthcircles-type`}
          className={`${classes["button"]} `}
        >
          <FaArrowAltCircleLeft size={25} />
        </Link>
        <button
          className={`${classes["button"]} flex items-center gap-2`}
          onClick={handleRefreshComponents}
          disabled={isLoading}
        >
          <FiRefreshCw size={20} /> Refresh default components
        </button>
      </div>
      <ul className="list-disc px-4 my-4 text-slate-600">
        <li>
          <strong>Refresh Default Components :</strong> This action will add
          default components (pages).
        </li>
      </ul>
      <EmptySpace />

      {selectedOrganisation && (
        <Tab>
          <TabItem title="General">
            <OrganisationGroupContextProvider
              organisationId={selectedOrganisation?.id ?? ""}
            >
              <GrowthCircleTypeGeneralSettings />
            </OrganisationGroupContextProvider>
          </TabItem>

          <TabItem title="Pages">
            <ComponentSettings />
          </TabItem>

          <TabItem title="Groups">
            <GroupsSettings />
          </TabItem>

          <TabItem title="CheckIn/CheckOut">
            {!selectedOrganisation.components.includes("intro-org-rating") ? (
              <DisabledSection requiredPage="intro-org-rating" />
            ) : (
              <ORSSettings ors={ors} />
            )}
          </TabItem>

          <TabItem title="Intention's Questions">
            {!selectedOrganisation.components.includes(
              "setting-intentions-randomize"
            ) ? (
              <DisabledSection requiredPage="setting-intentions-randomize" />
            ) : (
              <IntentionSettings intentions={intentions} />
            )}
          </TabItem>

          <TabItem title="Activity">
            {!selectedOrganisation.components.includes("choose-activity") ? (
              <DisabledSection requiredPage="choose-activity" />
            ) : (
              <Suspense fallback={<SimpleLoader />}>
                <ActivityPageSettings activities={activities} />
              </Suspense>
            )}
          </TabItem>

          <TabItem title="Topics">
            {!selectedOrganisation.components.includes("choose-topic") ? (
              <DisabledSection requiredPage="choose-topic" />
            ) : (
              <TopicSettings topics={topics} />
            )}
          </TabItem>

          <TabItem title="Reflection Questions">
            <QuestionSettings
              topics={topics}
              questions={questions}
              categoryQuestions={categoryQuestions}
            />
          </TabItem>
          <TabItem title="Feedback Questions">
            {!selectedOrganisation.components.includes("feedback-org-after") ? (
              <DisabledSection requiredPage="feedback-org-after" />
            ) : (
              <FeedbackSettings feedbackLayout={feedbackLayout} />
            )}
          </TabItem>

          <TabItem title="Resources">
            <ResourcesSettings resources={resources} />
          </TabItem>
        </Tab>
      )}
      {!selectedOrganisation && (
        <div className="w-full flex justify-center items-center h-96">
          <SimpleLoader />
        </div>
      )}
    </>
  );
}
