import Organisation from "interface/OrganisationInterface";
import { getProperNameComponent } from "pages/AllCircles/GenericSessionPage/ComponentMapping";
import { FaExchangeAlt } from "react-icons/fa";

const RefreshOverview = ({ data }: { data: Organisation }) => {
  // Function to check if a component exists in the default components
  const isDefaultComponent = (component: string, index: number) => {
    return (
      data.defaultComponents && data.defaultComponents[index] === component
    );
  };

  return (
    <div className="p-6">
      {/* Header Section */}
      <div className="mb-8 text-center">
        <div className="flex justify-center mb-4">
          <div className="bg-red-50 p-3 rounded-full">
            <FaExchangeAlt className="text-[#EB8181] text-xl" />
          </div>
        </div>
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          Component Layout Comparison
        </h2>
        <p className="text-gray-600 max-w-3xl mx-auto">
          Review the differences between your current components and stored
          defaults. Components highlighted in pink indicate changes in position
          or arrangement.
        </p>
      </div>

      {/* Comparison Section */}
      <div className="flex gap-8 p-4">
        {/* Current Components */}
        <div className="flex-1">
          <div className="mb-4">
            <h3 className="text-lg font-semibold text-gray-700 flex items-center gap-2">
              <div className="w-3 h-3 rounded-full bg-[#EB8181]"></div>
              Current Layout
            </h3>
          </div>
          <div className="space-y-3">
            {data.components.map((component, index) => (
              <div
                key={index}
                className={`relative flex items-center p-4 rounded-lg border-2 transition-all ${
                  !isDefaultComponent(component, index)
                    ? "border-[#EB8181] bg-red-50"
                    : "border-gray-200 bg-white"
                }`}
              >
                {/* Left dot */}
                <div className="absolute -left-3 top-1/2 transform -translate-y-1/2 w-2 h-2 rounded-full bg-[#EB8181]" />

                <div className="flex items-center gap-3">
                  <span className="bg-white w-8 h-8 rounded-full flex items-center justify-center text-gray-600 font-medium shadow-sm">
                    {index + 1}
                  </span>
                  <span className="font-medium text-gray-700">
                    {getProperNameComponent(component)}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Default Components */}
        <div className="flex-1">
          <div className="mb-4">
            <h3 className="text-lg font-semibold text-gray-700 flex items-center gap-2">
              <div className="w-3 h-3 rounded-full bg-gray-400"></div>
              Default Layout
            </h3>
          </div>
          <div className="space-y-3">
            {data.defaultComponents ? (
              data.defaultComponents.map((component, index) => (
                <div
                  key={index}
                  className={`relative flex items-center p-4 rounded-lg border-2 transition-all ${
                    !data.components.includes(component)
                      ? "border-[#EB8181] bg-red-50"
                      : "border-gray-200 bg-white"
                  }`}
                >
                  {/* Left dot */}
                  <div className="absolute -left-3 top-1/2 transform -translate-y-1/2 w-2 h-2 rounded-full bg-gray-400" />

                  <div className="flex items-center gap-3">
                    <span className="bg-white w-8 h-8 rounded-full flex items-center justify-center text-gray-600 font-medium shadow-sm">
                      {index + 1}
                    </span>
                    <span className="font-medium text-gray-700">
                      {getProperNameComponent(component)}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex flex-col items-center justify-center p-8 text-center border-2 border-dashed border-gray-300 rounded-lg">
                <div className="bg-gray-100 p-3 rounded-full mb-3">
                  <svg
                    className="w-6 h-6 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <p className="text-gray-600 font-medium mb-2">
                  No Default Components Found
                </p>
                <p className="text-sm text-gray-500">
                  Exit and save the current layout as default by checking the
                  "Save as default component" option.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefreshOverview;
