import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/performance";

import {
  FacebookAuthProvider,
  getAuth,
  signInAnonymously,
  signInWithPopup,
  updateProfile,
  GoogleAuthProvider,
  signInWithCustomToken,
  sendPasswordResetEmail,
  onAuthStateChanged,
} from "firebase/auth";

var env: string = "";
var firebaseConfig = {};
//testing gc-testing-ebf45
env = process.env.REACT_APP_PROJECT_ID!;
firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
const ENV = env;

//init firebase
firebase.initializeApp(firebaseConfig);
const app = firebase.initializeApp(firebaseConfig);
const perf = firebase.performance();

//init services
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const projectStorage = firebase.storage();
const projectAuthFacebook = new FacebookAuthProvider();
const projectAuthGoogle = new GoogleAuthProvider();

//timestamp
const timestamp = firebase.firestore.Timestamp;
const serverTimestamp = new Date();

export {
  projectFirestore,
  projectAuth,
  projectStorage,
  serverTimestamp,
  timestamp,
  firebase,
  projectAuthFacebook,
  projectAuthGoogle,
  perf,
  signInAnonymously,
  getAuth,
  signInWithPopup,
  updateProfile,
  signInWithCustomToken,
  sendPasswordResetEmail,
  onAuthStateChanged,
  ENV,
  app,
};
