import { useCallback } from "react";
import {
  addOrganisation as _addOrganisation,
  FIRESTORE_PATH_ORGANISATIONS,
  getOrganisations,
  getOrgById,
  getOrgByName,
  inviteUser,
  removeUserFromOrganisation,
  updateOrganisation,
} from "models/organisation";
import { useListSelect } from "../utility/useListSelect";
import getModelOperations, { WithId } from "utility/model";
import { QueryConstraint, UpdateData } from "firebase/firestore";
import { useAuthContext } from "hooks/useAuthContext";
import Organisation from "interface/OrganisationInterface";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { projectStorage, timestamp } from "../../firebase/config";
import { OrgUser, defaultOrgUser } from "interface/OrgUserInterface";
import { getOrgUsersForMigration } from "utility/orgUsersHelpers";
import duplicateOrganisation from "utility/firebase/organisationHelper";

export const FIRESTORE_SUBPATH_ORGUSERS = "orgusers";

export interface CopyOptions {
  questions: boolean;
  topics: boolean;
  customScale: boolean;
  activities: boolean;
  feedbackQuestions: boolean;
  roles: boolean;
  intentions: boolean;
}

export interface OrganisationHook {
  selectedOrganisation: Organisation | null;
  organisations: Organisation[];
  fetchOrganisations: () => Promise<Organisation[]>;
  selectFirstOrganisation: () => void;
  selectOrganisationByName: (OrganisationName: string) => void;
  selectOrganisationById: (id: string) => void;
  addOrganisation: (OrganisationName: string, addedBy: string) => Promise<void>;
  updateSelectedOrganisation: (
    OrganisationUpdates: UpdateData<Organisation>
  ) => Promise<void>;
  // local update: without calling Firebase (avoid expensive network request)
  updateSelectedOrganisationLocally: (newOrg: Organisation) => void;
  inviteUserToSelectedOrg: (email: string, role: string) => Promise<void>;
  deleteUserFromOrg: (
    userId: string,
    organisation: Organisation,
    profileId: string
  ) => Promise<void>;
  fetchOrgImages: (directory: string) => Promise<string[]>;
  selectOrganisationByNameFirst: (name: string, orgs: Organisation[]) => void;
  copyOrganisation: (
    organisationName: string,
    orgId: string,
    addedBy: string,
    copyOptions: CopyOptions,
    onProgress?: (step: string) => void
  ) => Promise<void>;
}

const ops = getModelOperations(defaultOrgUser);

function _setOrgUser(organisationId: string, updatedOrgUser: OrgUser) {
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_ORGUSERS}/${updatedOrgUser.user_id}`;
  return ops.setModel(path, updatedOrgUser);
}

function _removeOrgUser(organisationId: string, removeOrgUserId: string) {
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_ORGUSERS}/${removeOrgUserId}`;
  return ops.deleteModel(path);
}

function _getOrgUser(organisationId: string, userId: string) {
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_ORGUSERS}/${userId}`;
  return ops.getModel(path);
}

async function _getOrgUsers(
  organisationId: string,
  ...queryConstraints: QueryConstraint[]
) {
  const path = `${FIRESTORE_PATH_ORGANISATIONS}/${organisationId}/${FIRESTORE_SUBPATH_ORGUSERS}`;
  const result = await ops.getModels(path, ...queryConstraints);
  return result;
}

export const getOrgUsers = _getOrgUsers;

export const setOrgUser = _setOrgUser;

export const getOrgUserById = _getOrgUser;

export async function addOrgUser(organisationId: string, user: OrgUser) {
  const existingOrgUser = getOrgUserById(organisationId, user.user_id);
  if (await existingOrgUser) {
    return false;
  }

  return setOrgUser(organisationId, user);
}

export async function removeOrgUser(organisationId: string, userId: string) {
  if (userId === null) {
    return false;
  }
  await removeUserFromOrganisation(organisationId, userId);
  return _removeOrgUser(organisationId, userId);
}

export async function migrateToOrgUsersCollection(organisation: Organisation) {
  const users = (await getOrgUsersForMigration(organisation)) as OrgUser[];
  const promises = users.map(async (user) => {
    await addOrgUser(organisation.id, {
      user_id: user.user_id,
      org_id: user.org_id,
      role: user.role,
      joinedOn: user.joinedOn ? user.joinedOn : timestamp.fromDate(new Date()),
    });
  });

  await Promise.all(promises);
}

export const ERR_NO_ORG_SELECTED =
  "Cannot update organisation as no organisation is currently selected";

/**
 * Keeps the state of a list of organisations and the currently selected organisation.
 *
 * @param fetchOrganisations Function to fetch organisations.
 * @returns OrganisationHook.
 */
export default function useOrganisation(
  fetchOrganisations?: () => Promise<WithId<Organisation>[]>
): OrganisationHook {
  const { profile } = useAuthContext();

  const fetchUserOrganisations = useCallback(async (): Promise<
    Organisation[]
  > => {
    if (!profile) {
      return [];
    }
    return getOrganisations(profile);
  }, [profile]);

  if (!fetchOrganisations) {
    fetchOrganisations = fetchUserOrganisations;
  }

  const {
    items: organisations,
    selectedItem: selectedOrganisation,
    selectItem,
    addItem,
    updateItem,
    fetchItems: fetchAndUpdateOrganisations,
  } = useListSelect(
    fetchOrganisations,
    (org1: WithId<Organisation>, org2: WithId<Organisation>) =>
      org1.id === org2.id
  );

  const selectFirstOrganisation = useCallback(
    () => selectItem(() => true),
    [selectItem]
  );

  const selectOrganisationByName = useCallback(
    (name: string) => selectItem((org) => org.name === name),
    [selectItem]
  );

  const selectOrganisationByNameFirst = useCallback(
    (name: string, orgs: Organisation[]) => {
      const org = orgs.find((_org) => _org.name === name); // Use `find` to get the first matching organization
      if (org) {
        // Ensure the org has the correct type (WithId<Organisation>)

        selectItem((item) => item.id === org.id); // Use selectItem in the correct way if it's expecting a boolean function
      }
    },
    [selectItem]
  );

  const selectOrganisationById = useCallback(
    async (id: string) => selectItem((org) => org.id === id),
    [selectItem]
  );

  const addOrganisation = useCallback(
    async (organisationName: string, addedBy: string) => {
      await _addOrganisation(organisationName, addedBy);
      return addItem(await getOrgByName(organisationName));
    },
    [addItem]
  );

  const copyOrganisation = useCallback(
    async (
      organisationName: string,
      orgId: string,
      addedBy: string,
      copyOptions: CopyOptions,
      onProgress?: (step: string) => void
    ) => {
      await duplicateOrganisation(
        organisationName,
        orgId,
        addedBy,
        copyOptions,
        onProgress
      );
      return addItem(await getOrgByName(organisationName));
    },

    [addItem]
  );

  const updateSelectedOrganisationLocally = useCallback(
    (newOrg: Organisation) => {
      if (!selectedOrganisation?.id) {
        return;
      }
      updateItem(newOrg);
    },
    [selectedOrganisation?.id, updateItem]
  );

  const updateSelectedOrganisation = useCallback(
    async (organisationUpdates: UpdateData<Organisation>) => {
      if (!selectedOrganisation?.id) {
        return;
      }
      await updateOrganisation(selectedOrganisation.id, organisationUpdates);
      return updateItem(await getOrgById(selectedOrganisation.id));
    },
    [selectedOrganisation?.id, updateItem]
  );

  const inviteUserToSelectedOrg = useCallback(
    async (email: string, role: string) => {
      if (!selectedOrganisation?.id) {
        return;
      }
      // inviteUser updates orgusers
      await inviteUser(selectedOrganisation, email, role);
      return updateItem(await getOrgById(selectedOrganisation.id));
    },
    [selectedOrganisation, updateItem]
  );

  const deleteUserFromOrg = useCallback(
    async (userId: string, organisation: Organisation, profileId: string) => {
      if (organisation) {
        removeOrgUser(organisation.id, userId);
      }
    },
    // eslint-disable-next-line
    [organisations]
  );

  const fetchOrgImages = async (directory: string): Promise<string[]> => {
    try {
      const storageRef = ref(
        projectStorage,
        `organisations/${directory}/${selectedOrganisation?.id}`
      );
      const result = await listAll(storageRef);

      const urlPromises = result.items.map((imageRef) =>
        getDownloadURL(imageRef)
      );

      const urls = await Promise.all(urlPromises);

      return urls; // Return the array of URLs
    } catch (error) {
      console.error(error);
      return []; // Return an empty array in case of an error
    }
  };

  return {
    selectedOrganisation,
    organisations,
    deleteUserFromOrg,
    fetchOrganisations: fetchAndUpdateOrganisations,
    selectFirstOrganisation,
    selectOrganisationByName,
    addOrganisation,
    updateSelectedOrganisation,
    updateSelectedOrganisationLocally,
    inviteUserToSelectedOrg,
    fetchOrgImages,
    selectOrganisationById,
    selectOrganisationByNameFirst,
    copyOrganisation,
  };
}
